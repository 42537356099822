import { Functions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import {
    EmailRequest,
    FareharborRequestEmail,
    FeedbackRequest,
    PaymentEmailRequest,
    ResetPassowrdRequest
} from 'firebase_api';

export class EmailFunctions {
    constructor(readonly functions: Functions) {}

    public sendFeedbackEmail = async (data: FeedbackRequest): Promise<HttpsCallableResult> => {
        return await httpsCallable(this.functions, 'sendFeedbackEmail')(data);
    };

    public sendPaymentLinkEmail = async (
        request: PaymentEmailRequest
    ): Promise<HttpsCallableResult> => {
        return await httpsCallable(this.functions, 'sendPaymentLinkEmail')(request);
    };

    public sendLoginLinkEmail = async (request: EmailRequest): Promise<HttpsCallableResult> => {
        return await httpsCallable(this.functions, 'sendLoginLinkEmail')(request);
    };

    public sendEmailVerificationEmail = async (
        request: EmailRequest
    ): Promise<HttpsCallableResult> => {
        return await httpsCallable<EmailRequest, HttpsCallableResult>(
            this.functions,
            'sendEmailVerificationEmail'
        )(request);
    };

    public sendResetPasswordEmail = async (
        request: ResetPassowrdRequest
    ): Promise<HttpsCallableResult> => {
        return await httpsCallable(this.functions, 'sendResetPasswordEmail')(request);
    };

    public sendBrandingEmail = async (request: EmailRequest): Promise<HttpsCallableResult> => {
        return await httpsCallable(this.functions, 'sendBrandingEmail')(request);
    };

    public sendFareharborRequestEmail = async (
        request: FareharborRequestEmail
    ): Promise<HttpsCallableResult> => {
        return await httpsCallable(this.functions, 'sendFareharborRequestEmail')(request);
    };
}
