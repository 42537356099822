import { CircularProgress, Grid, Typography } from '@mui/material';

interface LoadingScreenProps {
    readonly title?: string;
    readonly subtitle?: string;
}
export default function LoadingScreen({ title, subtitle }: LoadingScreenProps) {
    return (
        <Grid
            container
            justifyContent="center"
            style={{ height: '100%', padding: '0px 20px 20px 20px' }}
            alignContent="center">
            {title && (
                <Grid container item xs={12} justifyContent="center">
                    <Typography variant="h5">{title}</Typography>
                </Grid>
            )}
            {subtitle && (
                <Grid container item xs={12} justifyContent="center" style={{ paddingTop: 15 }}>
                    <Typography variant="body2">{subtitle}</Typography>
                </Grid>
            )}
            <Grid container item xs={12} justifyContent="center" style={{ paddingTop: 20 }}>
                <CircularProgress />
            </Grid>
        </Grid>
    );
}
