"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getResizedImageName = exports.monitorUploadTask = exports.LOGO_IMAGE_SIZES = exports.NORMAL_IMAGE_SIZES = exports.BLURED_SUFFIX = exports.RESIZED_IMAGE_SUFFIX = void 0;
exports.RESIZED_IMAGE_SUFFIX = '_resized';
exports.BLURED_SUFFIX = '_blurred';
exports.NORMAL_IMAGE_SIZES = [
    { size: 240 },
    { size: 480 },
    { size: 1024 },
    { size: 1024, isBlurred: true }
];
exports.LOGO_IMAGE_SIZES = [{ size: 64 }, { size: 100 }];
const monitorUploadTask = (task, onProgress, onError, onSuccess) => {
    task.on('state_changed', (snapshot) => {
        onProgress &&
            onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100, snapshot.ref.name);
    }, (error) => {
        onError && onError(error);
    }, () => {
        onSuccess && onSuccess();
    });
};
exports.monitorUploadTask = monitorUploadTask;
const getResizedImageName = (name, size, isBlurred) => name + '_' + size + (isBlurred ? exports.BLURED_SUFFIX : '') + exports.RESIZED_IMAGE_SUFFIX + '.webp';
exports.getResizedImageName = getResizedImageName;
