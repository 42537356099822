import { Functions, httpsCallable } from 'firebase/functions';
import {
    UpsertCompanyMemberRequest,
    UpsertCompanyMemberResponse
} from 'firebase_api/src/model/functions/NewCompanyMember';
import {
    CreateCustomerRequest,
    CreateCustomerResponse
} from 'firebase_api/src/model/functions/NewCustomerRequest';

export class UserFunctions {
    constructor(readonly functions: Functions) {}

    public upsertCompanyMember = async (
        member: UpsertCompanyMemberRequest
    ): Promise<UpsertCompanyMemberResponse> =>
        (
            await httpsCallable<UpsertCompanyMemberRequest, UpsertCompanyMemberResponse>(
                this.functions,
                'upsertCompanyMember'
            )(member)
        ).data;

    public createUserAndStripeId = async (
        member: CreateCustomerRequest
    ): Promise<CreateCustomerResponse> =>
        (
            await httpsCallable<CreateCustomerRequest, CreateCustomerResponse>(
                this.functions,
                'createUserAndStripeId'
            )(member)
        ).data;
}
