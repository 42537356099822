import { Components } from '@mui/material';
import { createTheme, DeprecatedThemeOptions, PaletteOptions, Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

interface LoginPages {
    textSecondary: string;
    textSlider: string;
    sliderBg: string;
    textSubtitle: string;
    textPolicy: string;
}

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        dialogTitle: string;
        deleteBtn: string;
        tabelRow: string;
        avatarBg: string;
        titleBg: string;
        buttonBg: string;
        aboutCompanyBg: string;
        cancellationBg: string;
        disabledBtn: string;
        tasksDescription: string;
        footerBg: string;
        paperDark: string;
        customerBg: string;
        boxShadow: string;
    }
    interface TypeText {
        loginPages: LoginPages;
        confirmed: string;
        companyText: string;
        customerDescription: string;
        disabledBtText: string;
        shadowText: string;
    }
    interface PaletteOptions {
        inputBorder?: string;
        containerBorder?: string;
        headerGradient?: string;
    }
    interface Palette {
        inputBorder?: string;
        headerGradient?: string;
        containerBorder?: string;
    }
}

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        customSm: true;
    }
}

export const colors = {
    lightTheme: {
        primary: '#FF5C5C',
        secondary: '#1FC981',
        white: '#FFFFFF',
        black: '#000000',
        backgroundDefault: '#F1F6F6',
        paperDark: '#00194C',
        dialogTitle: '#F1F6F6',
        deleteBtn: '#EFF5F9',
        textPrimary: '#00194C',
        textSecondary: '#3F536E',
        contrastText: '#FFFFFF',
        companyText: '#B9C9DE',
        error: '#E61405',
        warning: '#FF9900',
        inputBorder: '#C5DBEA',
        headerGradient: '#FF9999',
        disabled: '#DADADA',
        borderMain: '#C5DBEA',
        borderActive: '#0052FF',
        buttonTextBg: '#F1F6F6',
        tablePrimary: '#00194C',
        backDropBg: '#000000D6',
        tabelBorder: '#DFEBF3',
        avatarBg: '#0296E5',
        titleBg: '#F2F3F6',
        buttonBg: '#1FC981' + '1A',
        confirmedText: '#125F51',
        aboutCompanyBg: '#F7F9FC',
        cancellationBg: '#FFEFEF',
        disabledBtn: '#EDFBF5',
        disabledBtText: '#C4F1DE',
        pendingStatus: '#D6840A',
        tasksDescription: '#FAFBFB',
        footerBg: '#6CC44D1A',
        customerBg: '#DCF0FF',
        selected: '#00000014',
        hover: '#0000000a',
        loginPages: {
            textSecondary: '#000000',
            textSlider: '#47546B',
            textSubtitle: '#C4C4C4',
            sliderBg: '#F1F5F6',
            textPolicy: '#73819D'
        }
    },
    darkTheme: {}
};

const lightThemeColors: PaletteOptions = {
    mode: 'light',
    common: {
        white: colors.lightTheme.white,
        black: colors.lightTheme.black
    },
    primary: {
        main: colors.lightTheme.primary,
        contrastText: colors.lightTheme.contrastText
    },
    secondary: {
        main: colors.lightTheme.secondary,
        contrastText: colors.lightTheme.contrastText,
        dark: '#158c5a'
    },
    success: {
        main: colors.lightTheme.secondary,
        contrastText: colors.lightTheme.contrastText
    },
    background: {
        paper: colors.lightTheme.white,
        paperDark: colors.lightTheme.paperDark,
        default: colors.lightTheme.backgroundDefault,
        dialogTitle: colors.lightTheme.dialogTitle,
        deleteBtn: colors.lightTheme.deleteBtn,
        tabelRow: colors.lightTheme.tabelBorder,
        avatarBg: colors.lightTheme.avatarBg,
        titleBg: colors.lightTheme.titleBg,
        buttonBg: colors.lightTheme.buttonBg,
        aboutCompanyBg: colors.lightTheme.aboutCompanyBg,
        cancellationBg: colors.lightTheme.cancellationBg,
        disabledBtn: colors.lightTheme.disabledBtn,
        tasksDescription: colors.lightTheme.tasksDescription,
        footerBg: colors.lightTheme.footerBg,
        customerBg: colors.lightTheme.customerBg,
        boxShadow: colors.lightTheme.secondary + '33'
    },
    action: {
        selected: colors.lightTheme.selected,
        hover: colors.lightTheme.hover
    },
    error: {
        main: colors.lightTheme.error
    },
    warning: {
        main: colors.lightTheme.warning
    },
    text: {
        primary: colors.lightTheme.textPrimary,
        secondary: colors.lightTheme.textSecondary,
        confirmed: colors.lightTheme.confirmedText,
        companyText: colors.lightTheme.companyText,
        customerDescription: colors.lightTheme.tablePrimary,
        disabledBtText: colors.lightTheme.disabledBtText,
        shadowText: colors.lightTheme.textPrimary + '66', // 40%
        loginPages: {
            ...colors.lightTheme.loginPages
        }
    },
    inputBorder: colors.lightTheme.inputBorder,
    containerBorder: colors.lightTheme.borderMain,
    headerGradient:
        'linear-gradient(48.03deg, ' +
        colors.lightTheme.primary +
        ' 26.32%, ' +
        colors.lightTheme.headerGradient +
        ' 96.36%)'
};

const overridesLight: Components = {
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                '&.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
                    {
                        padding: 0
                    }
            },
            inputRoot: {
                '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
                    padding: 0,
                    '& $input': {
                        padding: '12px 16px'
                    }
                },
                '&[class*="MuiOutlinedInput-root"]': {
                    padding: '12px 16px',
                    '& $input:first-of-type': {
                        paddingLeft: 16
                    }
                }
            }
        }
    },
    MuiTable: {
        styleOverrides: {
            root: {
                fontSize: 14,
                fontWeight: 400
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            input: { height: 22 }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                padding: 0
            },
            root: {
                '& fieldset': {
                    borderColor: colors.lightTheme.borderMain
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.lightTheme.borderActive
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.lightTheme.borderActive
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.lightTheme.error
                },
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.lightTheme.disabled
                },
                padding: '12px 16px'
            }
        }
    },
    MuiFormHelperText: {
        styleOverrides: { contained: { marginLeft: 0 } }
    },
    MuiStepLabel: {
        styleOverrides: {
            label: {
                fontSize: 13,
                lineHeight: 1.125
            }
        }
    },
    MuiButton: {
        defaultProps: { color: 'secondary', size: 'medium', disableRipple: true },
        styleOverrides: {
            root: { padding: '10.5px 20px 9.5px 20px' },
            outlined: { padding: '10.5px 20px 9.5px 20px' },
            contained: {
                padding: '11.5px 20px 10.5px 20px'
            },
            text: {
                padding: '11.5px 20px 10.5px 20px',
                background: colors.lightTheme.buttonTextBg,
                color: colors.lightTheme.textPrimary
            },
            sizeSmall: { padding: '8.5px 20px 7.5px 20px', fontSize: 16 },
            outlinedSizeSmall: { padding: '7.5px 20px 6.5px 20px !important' },
            sizeLarge: {
                padding: '16px 20px 15px 20px',
                fontSize: 18,
                lineHeight: 1.3333333333333333
            },
            outlinedSizeLarge: { padding: '15px 20px 14px 20px !important' }
        }
    },
    MuiPaper: { styleOverrides: { rounded: { borderRadius: 10 } } },
    MuiTableCell: {
        styleOverrides: {
            head: {
                backgroundColor: colors.lightTheme.primary,
                borderTop: '1px solid ' + colors.lightTheme.tabelBorder
            }
        }
    },
    MuiBackdrop: { styleOverrides: { root: { backgroundColor: colors.lightTheme.backDropBg } } },
    MuiMenuItem: {
        styleOverrides: { root: { borderBottom: '1px solid ' + colors.lightTheme.tabelBorder } }
    },
    MuiInput: {
        styleOverrides: {
            inputTypeSearch: { border: '1px solid ' + colors.lightTheme.borderMain },
            underline: {
                '&:after': { borderBottom: '1px solid' + colors.lightTheme.textPrimary }
            }
        }
    },
    MuiLink: {
        styleOverrides: {
            root: {
                cursor: 'pointer',
                color: colors.lightTheme.textPrimary,
                textDecorationColor: 'unset'
            }
        }
    },
    MuiFormLabel: {
        styleOverrides: {
            root: { '&.Mui-focused': { color: colors.lightTheme.textSecondary } }
        }
    },
    MuiAppBar: {
        styleOverrides: {
            colorDefault: { backgroundColor: colors.lightTheme.white }
        }
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
                color: colors.lightTheme.textPrimary + '40',
                '&.Mui-active': { color: colors.lightTheme.secondary }
            }
        }
    },
    MuiChip: {
        styleOverrides: {
            root: {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.34375,
                height: 28
            },
            colorSecondary: {
                backgroundColor: colors.lightTheme.buttonBg,
                color: colors.lightTheme.secondary
            },
            deleteIconColorSecondary: {
                color: colors.lightTheme.secondary
            }
        }
    },
    MuiSelect: {
        styleOverrides: {
            select: {
                minHeight: '1.4em'
            }
        }
    },
    MuiTextField: { defaultProps: { variant: 'outlined', size: 'small' } },
    MuiRadio: { defaultProps: { color: 'secondary' } },
    MuiButtonBase: {
        styleOverrides: {
            root: {
                '&.MuiTab-root': {
                    width: 160,
                    fontWeight: 500,
                    opacity: 0.7,
                    '&.Mui-selected': { opacity: 1 }
                },
                '&.MuiMenuItem-root.Mui-selected': {
                    backgroundColor: colors.lightTheme.selected,
                    '&:hover': { backgroundColor: colors.lightTheme.selected }
                },
                '&.MuiMenuItem-root.MuiMenuItem-root.Mui-selected': {
                    backgroundColor: colors.lightTheme.selected,
                    '&:hover': { backgroundColor: colors.lightTheme.selected }
                }
            }
        }
    },
    MuiCheckbox: { defaultProps: { color: 'success' } },
    MuiButtonGroup: {
        styleOverrides: {
            grouped: {
                '&:hover': {
                    backgroundColor: colors.lightTheme.selected,
                    boxShadow: 1,
                    borderColor: colors.lightTheme.textPrimary + '40'
                }
            }
        }
    },
    MuiSwitch: {
        styleOverrides: {
            switchBase: { '&.Mui-checked.Mui-disabled': { color: colors.lightTheme.selected } }
        }
    },
    MuiList: {
        styleOverrides: {
            root: {
                '&.MuiMenu-list': {
                    padding: 0
                }
            }
        }
    }
};

const typography: TypographyOptions = {
    fontSize: 16,
    fontFamily: 'Inter',
    h1: { fontSize: 46, lineHeight: 3.5375, fontWeight: 700 },
    h2: { fontSize: 32, lineHeight: 2.4, fontWeight: 600 },
    h3: { fontSize: 28, lineHeight: 1.875, fontWeight: 600 },
    h4: { fontSize: 22, lineHeight: 1.75, fontWeight: 600 },
    h5: { fontSize: 18, lineHeight: 1.625, fontWeight: 400 },
    h6: { fontSize: 16, lineHeight: 1.25, fontWeight: 600 },
    body1: { fontSize: 16, lineHeight: 1.5 },
    body2: { fontSize: 14, lineHeight: 1.09375 },
    button: { textTransform: 'none', fontWeight: 600, fontSize: 16, lineHeight: 1.5 }
};

const lightTheme = createTheme({
    palette: lightThemeColors,
    typography: typography,
    components: overridesLight,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            customSm: 767,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    }
});

const darkTheme: Theme = {
    ...lightTheme
};

export enum ThemeType {
    LIGHT = 'light',
    DARK = 'dark'
}

export const themes = new Map<ThemeType, DeprecatedThemeOptions>([
    [ThemeType.LIGHT, lightTheme],
    [ThemeType.DARK, darkTheme]
]);
