import { Box, Typography } from '@mui/material';
import React from 'react';

interface LabelProps {
    readonly children: string;
    readonly subtitle?: string;
    readonly error?: boolean;
}

export default function Label(props: LabelProps) {
    return (
        <Box style={{ marginBottom: 8 }}>
            <Typography
                variant="body2"
                color={props.error ? 'error' : undefined}
                style={{ lineHeight: 1.375 }}>
                {props.children}
            </Typography>
            <Typography
                variant="caption"
                color={props.error ? 'error' : undefined}
                style={{ opacity: 0.5 }}>
                {props.subtitle}
            </Typography>
        </Box>
    );
}
