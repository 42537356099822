import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toNumber } from 'lodash';
import { useContext } from 'react';
import { useQuery } from '../../../../../libs/utils/hooks';
import { extensionsStyle } from '../ExtensionsPage';
import { ButtonSize, ExtensionsPageController } from '../ExtensionsPageController';

interface BookNowBtnProps {
    readonly sizeOption?: ButtonSize;
    readonly textColor?: string;
    readonly textSize?: number;
    readonly fontWeight?: number;
    readonly btnColor?: string;
    readonly borderRadius?: number;
}

export default function ExtensionsBookNowBtn(props: BookNowBtnProps) {
    const query = useQuery();
    const theme = useTheme();
    const classes = extensionsStyle();
    const controller = useContext(ExtensionsPageController);
    const sizeOption =
        props.sizeOption ||
        (query.get('sizeOption') !== null
            ? (JSON.parse(query.get('sizeOption')!) as ButtonSize)
            : { width: 180, height: 45 });
    const textColor = '#' + (props.textColor || query.get('textColor'));
    const fontWeight = props.fontWeight || query.get('fontWeight') || 500;
    const textSize = props.textSize || toNumber(query.get('textSize'));
    const borderRadius = props.borderRadius || query.get('borderRadius');
    const btnColor = '#' + (props.btnColor || query.get('btnColor'));

    return (
        <Grid
            item
            container
            justifyContent={controller.buttonPositionInParentDiv}
            className={classes.buttonContainer}
            style={{
                backgroundColor: controller.isParentDivChecked
                    ? theme.palette.background.buttonBg
                    : theme.palette.common.white,
                border: controller.isParentDivChecked
                    ? '1px solid' + theme.palette.secondary.main
                    : 'none',
                padding: controller.parentDivPadding,
                margin: '20px 0',
                minWidth: 'fit-content'
            }}>
            <Button
                variant="text"
                disabled={query.get('btnColor') === null}
                style={{
                    color: textColor,
                    background: btnColor,
                    borderRadius: Number(borderRadius),
                    height: sizeOption?.height,
                    width: sizeOption?.width
                }}>
                <Typography
                    style={{
                        fontSize: textSize,
                        fontWeight: fontWeight
                    }}>
                    Book Now
                </Typography>
            </Button>
        </Grid>
    );
}
