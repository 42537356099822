import { Functions, httpsCallable } from 'firebase/functions';
import {
    PaymentIntentRequest,
    PaymentIntentResponse
} from 'firebase_api/src/model/functions/PaymentIntentRequest';
import { CreateAccountToken } from 'firebase_api/src/model/functions/Stripe';

export class StripeFunctions {
    constructor(readonly functions: Functions) {}

    public createCustomAccountToken = async (request: CreateAccountToken): Promise<any> => {
        return await httpsCallable(this.functions, 'customAccountTokenCreator')(request);
    };

    public getCustomerLoginLink = async (companyId: string): Promise<any> => {
        return await httpsCallable(this.functions, 'customAccountUserLoginLink')(companyId);
    };

    public createPaymentIntent = async (
        request: PaymentIntentRequest
    ): Promise<PaymentIntentResponse> => {
        return (
            await httpsCallable<PaymentIntentRequest, PaymentIntentResponse>(
                this.functions,
                'paymentIntentCreator'
            )(request)
        ).data;
    };
}
