"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCATIONS = void 0;
exports.LOCATIONS = {
    Queensland: [
        'Tropical North',
        'Townsville',
        'Outback',
        'Whitsundays',
        'Mackay',
        'Capricorn',
        'Gladstone',
        'Bundaberg',
        'Fraser Coast',
        'Sunshine Coast',
        'South Queensland Country',
        'Brisbane',
        'Gold Coast'
    ],
    'New South Wales': [
        'Blue Mountains',
        'Central Coast',
        'Country NSW',
        'Hunter',
        'Lord Howe Island',
        'North Coast',
        'Outback NSW',
        'Snowy Mountains',
        'South Coast',
        'Sydney'
    ],
    Victoria: [
        'Melbourne',
        'Yarra Valley & Dandenong Ranges',
        'Gippsland',
        'Daylesford & The Macedon Ranges',
        'Mornington Peninsula',
        'High Country',
        'Phillip Island',
        'Great Ocean Road',
        'The Murray',
        'Geelong & The Bellarine',
        'Goldfields',
        'Grampians'
    ],
    'South Australia': [
        'Adelaide',
        'Adelaide Hills',
        'Barossa',
        'Clare Valley',
        'Eyre Peninsula',
        'Fleurieu Peninsula',
        'Flinders Ranges & Outback',
        'Kangaroo Island',
        'Riverland',
        'Yorke Peninsula'
    ],
    'Western Australia': ['Perth', 'South West', 'Outback', 'North-West', 'Coral Coast'],
    'Northern Territory': [
        'Darwin & Surrounds',
        'Alice Springs & Surrounds',
        'Uluru & Surrounds',
        'Kakadu & Surrounds',
        'Katherine & Surrounds',
        'Arnhem Land',
        'Tennant Creek & Barkly Region'
    ],
    'Australian Capital Territory': ['Canberra Region'],
    Tasmania: [
        'Hobart & South',
        'West Coast',
        'East Coast',
        'Launceston & North',
        'Flinders Island',
        'North West',
        'King Island'
    ]
};
