import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: { fontSize: 12, fontFamily: 'Inter' },
    pageLayout: { paddingHorizontal: 38 },
    header: {
        backgroundColor: '#FF5C5C',
        height: 67,
        display: 'flex',
        flexDirection: 'row'
    },
    logo: {
        width: 150,
        height: 34,
        margin: '16px 0 16px 38px'
    },
    headerTitle: {
        color: 'white',
        fontWeight: 700,
        textAlign: 'right',
        margin: '5px 0',
        fontFamily: 'InterBold'
    },
    refNr: {
        color: 'white',
        textAlign: 'right'
    },
    companyView: {
        backgroundColor: '#d9dde4',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 38px'
    },
    bookedOnView: {
        backgroundColor: '#f2f3f6',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 38px'
    },
    companyName: {
        fontWeight: 400,
        paddingTop: 8
    },
    companyText: {
        fontFamily: 'InterBold'
    },
    productView: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 17
    },
    productName: {
        fontSize: 18,
        lineHeight: 1.4,
        fontWeight: 400,
        fontFamily: 'InterBold'
    },
    defaultLabel: {
        fontFamily: 'InterBold',
        borderRadius: 14,
        padding: '5.5px 11px 5px',
        fontWeight: 600,
        fontSize: 14,
        height: 28,
        color: 'white',
        backgroundColor: '#D6840A',
        marginTop: -2
    },
    successLabel: {
        backgroundColor: '#1FC981'
    },
    failedLabel: {
        backgroundColor: '#FF5C5C'
    },
    pendingLabel: {},
    productDetailsView: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 17
    },
    separatorView: {
        backgroundColor: '#ccc',
        height: 1,
        width: '100%',
        marginVertical: 15
    },
    fareTitle: { fontFamily: 'InterBold', paddingBottom: 4 },
    fareText: { paddingVertical: 3 },
    detailsView: { display: 'flex', flexDirection: 'row' },
    detailsTitle: { fontFamily: 'InterBold', paddingLeft: 10 },
    commentsTitle: { padding: 13, fontFamily: 'InterBold' },
    commentsText: { fontSize: 10.5, padding: '0 13px 13px' },
    termsText: { paddingVertical: 3 },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    iconDetails: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 2,
        paddingRight: 10
    },
    faresExtrasView: {
        paddingRight: 120,
        width: '82%'
    }
});
