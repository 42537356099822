import { DeprecatedThemeOptions, Theme } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles/';
import { useRef, useState } from 'react';
import { themes, ThemeType } from '../../assets/css/themes';
import { LOCAL_STORAGE_KEYS } from '../../components/constants';
import { getLocalStorage } from '../utils/functions';
import { WrappedUseState, WrappedUseStateReturn } from '../utils/hooks';
import { createStore, createStoreContext, DefaultProviderProps } from './common';

export interface ThemeDataStoreValue {
    readonly theme: Theme;
    readonly toggleTheme: (themeType?: ThemeType, prefersDarkMode?: boolean) => void;
    readonly isDarkMode: WrappedUseStateReturn<boolean>;
}

export const ThemeStore = createStoreContext<ThemeDataStoreValue>();

export const ThemeDataStoreProvider = ({ children }: DefaultProviderProps) => {
    const isMounted = useRef(true);
    const storedThemeType =
        (getLocalStorage()?.getItem(LOCAL_STORAGE_KEYS.theme) as ThemeType) || ThemeType.LIGHT;
    const isDarkMode = WrappedUseState<boolean>(storedThemeType === ThemeType.DARK);

    const myCreateTheme = (options: DeprecatedThemeOptions): Theme => {
        return responsiveFontSizes(createTheme(options));
    };

    const [theme, setTheme] = useState<Theme>(
        myCreateTheme(themes.get(storedThemeType) || themes.get(ThemeType.LIGHT)!)
    );

    const toggleTheme = (themeType?: ThemeType, prefersDarkMode?: boolean) => {
        const themeId = themeType || (isDarkMode.data ? ThemeType.LIGHT : ThemeType.DARK);
        isDarkMode.setData(
            (prev) => (prefersDarkMode !== undefined ? prefersDarkMode : !prev),
            isMounted
        );
        setTheme(myCreateTheme(themes.get(themeId) || themes.get(ThemeType.LIGHT)!));
        !themeType && getLocalStorage()?.setItem(LOCAL_STORAGE_KEYS.theme, themeId);
    };

    return createStore(ThemeStore, children, {
        theme,
        toggleTheme,
        isDarkMode
    });
};
