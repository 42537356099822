import { MenuItem } from '@mui/material';
import {
    Base,
    BookingStatus,
    Company,
    Customer,
    Duration,
    FareOption,
    getResizedImageName,
    ImageSizeProps,
    ImageType,
    ImageUrls,
    LOGO_IMAGE_SIZES,
    Priceable,
    Product,
    Role,
    Sale,
    User
} from 'firebase_api';
import { floor, isEmpty, last, startCase, toLower, trim } from 'lodash';
import moment from 'moment';
import React, { isValidElement, ReactElement, ReactNode } from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import { BACKEND_DATE_FORMAT, STORAGE_API, TWO_YEAR_DURATION } from '../../components/constants';
import { CrudStorage } from '../../firebase/storage/CrudStorage';
import { StoreKeys } from '../../v2/components/wrappers/GlobalController';
import { MenuRoutes } from '../../v2/utils/mainMenu/OperatorMenu';
import environment from '../environment/env';
import { getImgExtensionFromDataUrl } from './file';

export const convertDuration = (duration: Duration): string => {
    const days =
        (duration.days || 0) > 0
            ? duration.days + ((duration.days || 0) > 1 ? ' days' : ' day')
            : '';
    const hrs =
        (duration.hours || 0) > 0
            ? duration.hours + ((duration.hours || 0) > 1 ? ' hours' : ' hour')
            : '';
    const min =
        (duration.minutes || 0) > 0
            ? duration.minutes + ((duration.minutes || 0) > 1 ? ' minutes' : ' minute')
            : '';
    return (
        days +
        (((days && hrs) || (days && min)) && !(hrs && min)
            ? ', and '
            : days && hrs && min
            ? ', '
            : '') +
        hrs +
        (hrs && min ? ', and ' : '') +
        min
    );
};

export const loadScript = (src: string, position: HTMLElement | null, id: string) => {
    if (!position) return;

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
};

const minutesInOneDay = 1440;
export const minutesToDuration = (minutes: number | undefined): Duration => {
    if (minutes === undefined) {
        return {};
    }
    const days = Math.floor(minutes / minutesInOneDay);
    const left = minutes % minutesInOneDay;
    const hours = Math.floor(left / 60);
    minutes = left % 60;
    return { days, hours, minutes };
};

export const durationToMinutes = (duration: Duration): number | undefined => {
    if (
        isNaNOrUndefined(duration.days) &&
        isNaNOrUndefined(duration.hours) &&
        isNaNOrUndefined(duration.minutes)
    ) {
        return undefined;
    }
    return (
        (duration.days || 0) * minutesInOneDay +
        (duration.hours || 0) * 60 +
        (duration.minutes || 0)
    );
};

export function getById<T extends Base>(array: T[], id: string): T | undefined {
    const [item] = array.filter((item) => item.id === id);
    return item;
}

export function unique<T>(array: T[]): T[] {
    return array.filter((item, index, self) => self.indexOf(item) === index);
}

export function removeMultiple<T>(array: Array<T>, toRemove: Array<T>): Array<T> {
    const tmp = [...array];
    toRemove.forEach((item) => {
        const index = tmp.indexOf(item);
        if (index > -1) {
            tmp.splice(index, 1);
        }
    });
    return tmp;
}

export const isNaNOrUndefined = (value: number | undefined) => {
    if (value === undefined) {
        return true;
    }
    return isNaN(value);
};

export const userHasAnyRole = (
    userId: string | undefined,
    company: Company | undefined,
    roles: Role[]
) => {
    if (!userId || !company) {
        return false;
    }
    const currentRoles = (company.users || {})[userId] || [];
    return containsAnyRole(currentRoles, roles);
};

export const containsAnyRole = (userRoles: Role[], roles: Role[]): boolean => {
    for (const role of roles) {
        if (userRoles.indexOf(role) > -1) {
            return true;
        }
    }
    return false;
};

export const round = (value: number): number => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const getRoundedPercentage = (
    amount: number | undefined,
    percentage: number | undefined
): number => round(((amount || 0) * (percentage || 0)) / 100);

export const returnPrivateRoutes = (menuList: MenuRoutes[], history: any): React.ReactNode => {
    return menuList.map((item, i) => {
        if (item.children) return returnPrivateRoutes(item.children, history);
        return (
            <PrivateRoute
                key={i}
                path={item.path || history.location.pathname}
                exact
                component={item.component!}
                needsCompleteCompany={
                    item.needsCompanySetup === undefined ? false : item.needsCompanySetup
                }
                roles={item.roles}
            />
        );
    });
};

export const getCompanyName = (company: Company, fallback: string = ''): string => {
    return company.tradingName || company.accountName || fallback;
};

export const getCustomerName = (customer?: Customer): string =>
    `${customer?.firstName || ''} ${customer?.lastName || ''}`;

export const humanReadableSalesAmount = (amount: number) => {
    const steps = [
        { symbol: 'K', amount: Math.pow(10, 3) },
        { symbol: 'M', amount: Math.pow(10, 6) }
    ];
    for (const step of steps) {
        const value = amount / step.amount;
        if (value > 1) {
            return `${floor(value, 1)}${step.symbol}`;
        }
    }
    return amount;
};

export const setOrDelete = (object: any, key: any, value?: any) => {
    const tmp = { ...object };
    if (value) {
        tmp[key] = value;
    } else {
        delete tmp[key];
    }
    return tmp;
};

export const removeRemoteImages = async (
    oldImageIds: string[],
    currentImageIds: string[],
    sotrage: CrudStorage,
    mainObjId: string,
    imgSizes: ImageSizeProps[]
) => {
    const removedIds = oldImageIds.filter((id) => !currentImageIds?.includes(id));
    const imgsToRemove = removedIds.flatMap((id) =>
        imgSizes.map((imageProp) => getResizedImageName(id, imageProp.size, imageProp.isBlurred))
    );
    const promises = imgsToRemove.map((img) => sotrage.delete(img, mainObjId));
    await Promise.all(promises);
};

export const getImageUrls = (
    rootObjName: string,
    objectId: string,
    imageId: string,
    imgSizes: ImageSizeProps[]
): ImageUrls => {
    const originalName = `${STORAGE_API}/${environment.firebase.storageBucket}/o/${rootObjName}%2f${objectId}%2F${imageId}`;
    const mediaType = '?alt=media';
    if (imgSizes === LOGO_IMAGE_SIZES) {
        return {
            original: originalName + mediaType,
            small: `${getResizedImageName(originalName, imgSizes[0].size)}${mediaType}`,
            medium: `${getResizedImageName(originalName, imgSizes[1].size)}${mediaType}`
        };
    }
    return {
        original: originalName + mediaType,
        small: `${getResizedImageName(originalName, imgSizes[0].size)}${mediaType}`,
        medium: `${getResizedImageName(originalName, imgSizes[1].size)}${mediaType}`,
        large: `${getResizedImageName(originalName, imgSizes[2].size)}${mediaType}`,
        largeBlurred: `${getResizedImageName(originalName, imgSizes[2].size, true)}${mediaType}`
    };
};

export const toMenuItem = (
    native: boolean,
    value: string | number,
    key: string | number,
    children: ReactNode,
    disabled?: boolean,
    icon?: ReactElement
) =>
    native ? (
        <option key={key} value={value} disabled={disabled}>
            {icon
                ? isValidElement(icon) && (
                      <>
                          {icon}
                          {children}
                      </>
                  )
                : children}
        </option>
    ) : (
        <MenuItem key={key} value={value} disabled={disabled}>
            {icon
                ? isValidElement(icon) && (
                      <>
                          {icon}
                          {children}
                      </>
                  )
                : children}
        </MenuItem>
    );

export const isUserDataIncomplete = (user: User): boolean =>
    isEmpty(user) || !user?.firstName || !user?.lastName || !user?.phone;

export const replaceBase64WithDownloadUrl = (
    images: ImageType[],
    downloadUrls: { [id: string]: string }
) =>
    images.map((img) => {
        if (img.itemUrl?.includes('firebase')) {
            return img;
        }
        const extension = getImgExtensionFromDataUrl(img.itemUrl || '');
        const itemUrl = downloadUrls[img.id!];
        return {
            ...img,
            itemUrl: itemUrl,
            thumbnailUrl: itemUrl.replace(`.${extension}`, '_tb.webp'),
            mediumSizeUrl: itemUrl.replace(`.${extension}`, '_md.webp'),
            largeSizeUrl: itemUrl.replace(`.${extension}`, '_lg.webp')
        };
    });

// source: https://github.com/DemystData/is-valid-abn
export const isValidABN = (rawAbn: string) => {
    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

    if (!rawAbn) {
        return false;
    }

    // strip non-alphanumeric characters
    const abn = rawAbn.toString().replace(/[^a-z\d]/gi, '');

    // check if length is 11 digits
    if (abn.length !== 11) {
        return false;
    }

    // apply ato check method
    let sum = 0;
    for (let position = 0; position < weights.length; position += 1) {
        const weight = weights[position];
        const digit = parseInt(abn[position], 10) - (position ? 0 : 1);
        sum += weight * digit;
    }

    const checksum = sum % 89;

    return checksum === 0;
};

export const getUsedSeats = (fares: FareOption[]) =>
    fares.reduce((sum, fare) => sum + (fare.quantity || 0) * (fare?.seatsUsed || 0), 0);

export const getLocalStorage = (): Storage | null => {
    try {
        return window?.localStorage;
    } catch (error) {
        return null;
    }
};

export const notExtensionsPath = (route: string, noAdditionalId?: boolean) =>
    !location.pathname.startsWith(route) ||
    (noAdditionalId ? false : isEmpty(last(location.pathname.split('/'))));

export const calculateTotalPriceable = (priceables: Priceable[], noDiscount?: boolean) =>
    priceables.reduce(
        (sum, act) =>
            sum +
            (act.quantity || 0) * (act.price || 0) -
            (noDiscount ? 0 : (act.quantity || 0) * (act.discount || 0)),
        0
    );

export const getDateRange = (fromDate: moment.Moment, toDate: moment.Moment) => {
    const diff = toDate.diff(fromDate, 'days');
    const range = [];
    for (let i = 0; i < diff; i++) {
        range.push(moment(fromDate).add(i, 'days').format(BACKEND_DATE_FORMAT));
    }
    return range;
};
export const removeWhitespace = (field?: string) => (field ? field.replace(/\s/g, '') : '');

export const ignoreCase = (field?: string) => (field ? trim(field.toLowerCase()) : '');

export const checkExtension = () => !isEmpty(getLocalStorage()?.getItem(StoreKeys.EXTENSIONS));

export const uiString = (field?: string) => (field ? startCase(toLower(field)) : '');

export const isSaleActive = (sale: Sale) => moment().isBetween(sale.startDate, sale.endDate);

export const getFare = (product: Product, fare: FareOption | undefined, date?: string) => {
    const defaultFare =
        product.fares?.find((f) =>
            fare?.id === f.id && product.provider
                ? true
                : moment(date).isBetween(
                      moment.tz(f?.startDate, product.timeZone || 'utc'),
                      moment.tz(f?.endDate, product.timeZone || 'utc')
                  )
        ) || fare;
    const sales =
        product?.sales && product.sales.find((s) => moment(date).isBetween(s.startDate, s.endDate));
    return fare?.isItemDiscount
        ? fare
        : (sales && sales.fares.find((f) => f.id === fare?.id)) || defaultFare;
};

export const getCancellation = (company: Company) => ({
    isAlways: (company?.cancellationReturnPolicies?.full_fare || 0) === TWO_YEAR_DURATION,
    timePeriod:
        company?.cancellationReturnPolicies?.full_fare ||
        company?.cancellationReturnPolicies?.nothing ||
        0
});

export const isChangedBookingStatus = (status?: BookingStatus) =>
    [BookingStatus.DRAFT, BookingStatus.NEW, BookingStatus.PENDING_UPDATE].includes(
        status || BookingStatus.NEW
    );
