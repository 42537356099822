import { Functions, httpsCallable } from 'firebase/functions';
import {
    deleteObject,
    getDownloadURL,
    ref,
    StorageReference,
    uploadBytesResumable,
    UploadMetadata
} from 'firebase/storage';
import { Collection, ImageType } from 'firebase_api';
import { v4 as uuid } from 'uuid';
import { storage } from '../firebase/firebaseIndex';
import { getImgExtensionFromDataUrl } from '../libs/utils/file';

export interface ImagesApi {
    readonly getBlobImg: (url: string) => Promise<string>;
    readonly deleteImg: (path: string) => Promise<void>;
    readonly uploadImg: (
        collection: Collection,
        parentId: string,
        image: ImageType,
        onProgress: (progress: number) => void
    ) => Promise<string>;

    readonly uploadImages: (
        collection: Collection,
        parentId: string,
        image: ImageType[],
        onProgress: (progress: number) => void
    ) => Promise<{ [id: string]: string }>;
}

export class ImagesApiImpl implements ImagesApi {
    constructor(
        private readonly functions: Functions,
        private readonly storageRef: StorageReference
    ) {}

    public getBlobImg = async (url: string): Promise<string> =>
        url.includes('firebasestorage')
            ? this.downloadFirebaseImg(url)
            : this.downloadNonFirebaseImg(url);

    public deleteImg = async (path: string) => deleteObject(ref(storage, path));

    public uploadImg = (
        collection: Collection,
        parentId: string,
        image: ImageType,
        onProgress: (progress: number) => void
    ) =>
        new Promise<string>((resolve, reject) => {
            const metadata: UploadMetadata = {
                cacheControl: 'public,max-age=2592000',
                customMetadata: {
                    key: uuid()
                }
            };
            const extension = getImgExtensionFromDataUrl(image.itemUrl || '');
            fetch(image.itemUrl || '')
                .then((resp) => resp.blob())
                .then((blob) => {
                    const task = uploadBytesResumable(
                        ref(storage, `${collection}/${parentId}/${image.id}.${extension}`),
                        blob,
                        metadata
                    );
                    task.on(
                        'state_changed',
                        (snapshot) => {
                            onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        (error) => {
                            reject(error);
                        },
                        () => {
                            getDownloadURL(task.snapshot.ref).then(resolve);
                        }
                    );
                });
        });

    public uploadImages = async (
        collection: Collection,
        parentId: string,
        images: ImageType[],
        onProgress: (progress: number) => void
    ) => {
        const progresses: { [id: string]: number } = {};
        const uploadPromises = images.map(async (img) => {
            const downloadUrl = await this.uploadImg(collection, parentId, img, (progress) => {
                progresses[img.id!] = progress;
                onProgress(this.getProgressesSum(progresses, images.length));
            });
            return [img.id!, downloadUrl];
        });
        return (await Promise.all(uploadPromises)).reduce(
            (acc, [id, url]) => ({ ...acc, [id]: url }),
            {} as { [id: string]: string }
        );
    };

    private getProgressesSum = (progresses: { [id: string]: number }, uploadCount: number) => {
        const sum = Object.values(progresses).reduce((sum, act) => sum + act, 0);
        return Math.floor(sum / uploadCount);
    };

    private downloadFirebaseImg = async (url: string): Promise<string> => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                resolve(reader.result as string);
            };
        });
    };

    private downloadNonFirebaseImg = async (url: string): Promise<string> =>
        (await httpsCallable(this.functions, 'imgDownloader')(url)).data as string;
}
