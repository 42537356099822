import {
    Button,
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogContentProps,
    DialogContentText,
    DialogProps,
    Grid,
    Slide,
    SlideProps,
    Typography
} from '@mui/material';
import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import accountCreatedImg from '../../../../assets/images/popups/account_created.png';
import ImgWithFallback from '../../../pages/common/ImgWithFallback';
import { PLoadingButtonProps } from '../LoadingButton';
import PopupManager from './PopupManager';

export interface PopupProps {
    readonly imgSrc?: string;

    readonly title?: ReactNode;
    readonly description?: ReactNode;

    readonly buttonText?: string;
    readonly buttonProps?: PLoadingButtonProps;

    readonly timeout?: number;

    readonly dialogProps?: DialogProps;
    readonly contentProps?: DialogContentProps;
    readonly actionProps?: DialogActionsProps;

    readonly onClose?: () => void;
}

const SlideTransition = forwardRef<HTMLDivElement, SlideProps>(function Transition(props, ref) {
    return <Slide direction="up" {...props} ref={ref} />;
});

interface PopupViewProps extends PopupProps {
    readonly open: boolean;
}

function PopupView(props: PopupViewProps) {
    return (
        <Dialog open={props.open} TransitionComponent={SlideTransition} {...props.dialogProps}>
            <DialogContent
                style={{
                    textAlign: 'center',
                    maxWidth: 375,
                    padding: props.buttonText ? '27px 27px 4px' : 27
                }}
                {...props.contentProps}>
                {props.imgSrc && (
                    <ImgWithFallback
                        src={props.imgSrc}
                        fallbackSrc={accountCreatedImg}
                        skeletonStyles={{ width: '100%', height: '300px' }}
                    />
                )}
                {props.title && (
                    <Typography variant="h4" align="center" style={{ paddingBottom: 10 }}>
                        {props.title}
                    </Typography>
                )}
                {props.description && (
                    <DialogContentText align="center">{props.description}</DialogContentText>
                )}
            </DialogContent>
            {props.buttonText && (
                <DialogActions style={{ paddingBottom: 20 }} {...props.actionProps}>
                    <Grid container item justifyContent="center">
                        <Button variant="contained" onClick={props.onClose} {...props.buttonProps}>
                            {props.buttonText}
                        </Button>
                    </Grid>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default function PopupContainer() {
    const [props, setProps] = useState<PopupProps | undefined>();

    useEffect(() => {
        PopupManager.addChangeListener(setProps);
    }, []);

    return (
        <PopupView
            {...props}
            open={props !== undefined}
            onClose={props?.onClose || (() => PopupManager.remove())}
        />
    );
}
