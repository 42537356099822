import { Functions, httpsCallable } from 'firebase/functions';
import {
    ApiBookingValidation,
    GetRezdyImageRequest,
    RezdyAvailabilityRequest,
    RezdyRequest
} from 'firebase_api';

export class RezdyFunctions {
    constructor(readonly functions: Functions) {}

    public importPickUpList = async (request: RezdyRequest): Promise<void> => {
        await httpsCallable(this.functions, 'importPickUpList')(request);
    };

    public getImage = async (
        request: GetRezdyImageRequest
    ): Promise<{ [imgUrl: string]: string }> => {
        return (
            await httpsCallable<GetRezdyImageRequest, { [imgUrl: string]: string }>(
                this.functions,
                'getRezdyImage'
            )(request)
        ).data;
    };

    public getRezdyProduct = async (request: RezdyRequest) => {
        return (await httpsCallable<RezdyRequest, any>(this.functions, 'getRezdyProduct')(request))
            .data;
    };

    public getAvailability = async (request: RezdyAvailabilityRequest) => {
        return (
            await httpsCallable<RezdyAvailabilityRequest, any>(
                this.functions,
                'getRezdyAvailability'
            )(request)
        ).data;
    };

    public getExtra = async (request: RezdyRequest) => {
        return (await httpsCallable<RezdyRequest, any>(this.functions, 'getRezdyExtra')(request))
            .data;
    };

    public getRezdyPickUp = async (request: RezdyRequest) => {
        return (await httpsCallable<RezdyRequest, any>(this.functions, 'getRezdyPickUp')(request))
            .data;
    };

    public validateRezdyBooking = async (request: ApiBookingValidation) => {
        return (
            await httpsCallable<ApiBookingValidation, string | undefined>(
                this.functions,
                'validateRezdyBooking'
            )(request)
        ).data;
    };
}
