import defaultEnv from './dev.env.json';
import packageJson from '../../../package.json';
import { Environment } from './model';

const env = process.env.REACT_APP_ENV_VARS
    ? JSON.parse(process.env.REACT_APP_ENV_VARS)
    : (defaultEnv as any)[process.env.REACT_APP_DEV || 'dev'];

const environment: Environment = { ...env, version: packageJson.version };
export default environment;
