import { Functions, httpsCallable } from 'firebase/functions';
import {
    ApiBookingValidation,
    ResPaxApiRequest,
    ResPaxApiWithTimes,
    ResPaxAvailability,
    ResPaxDropOff,
    ResPaxPickUpOption,
    ResPaxProduct
} from 'firebase_api';

export class ResPaxFunctions {
    constructor(readonly functions: Functions) {}

    public getResPaxProduct = async (request: ResPaxApiRequest) =>
        (
            await httpsCallable<ResPaxApiRequest, ResPaxProduct>(
                this.functions,
                'getResPaxProduct'
            )(request)
        ).data;

    public getResPaxDefaultPricing = async (request: ResPaxApiWithTimes) =>
        (
            await httpsCallable<ResPaxApiWithTimes, any>(
                this.functions,
                'getResPaxDefaultPricing'
            )(request)
        ).data;

    public getResPaxAvailability = async (request: ResPaxAvailability) =>
        (
            await httpsCallable<ResPaxAvailability, any>(
                this.functions,
                'getResPaxAvailability'
            )(request)
        ).data;

    public getLiveFaresPrices = async (request: ResPaxAvailability) =>
        (
            await httpsCallable<ResPaxAvailability, any>(
                this.functions,
                'getLiveFaresPrices'
            )(request)
        ).data;

    public checkReservation = async (request: ApiBookingValidation) =>
        (
            await httpsCallable<ApiBookingValidation, any>(
                this.functions,
                'checkReservation'
            )(request)
        ).data;

    public getPaymentOptions = async (request: ResPaxApiRequest) =>
        (await httpsCallable<ResPaxApiRequest, any>(this.functions, 'getPaymentOptions')(request))
            .data;

    public getResPaxPickUps = async (request: ResPaxAvailability) =>
        (
            await httpsCallable<ResPaxAvailability, ResPaxPickUpOption[]>(
                this.functions,
                'getResPaxPickUps'
            )(request)
        ).data;

    public getResPaxTransfers = async (request: ResPaxAvailability) =>
        (
            await httpsCallable<ResPaxAvailability, ResPaxDropOff[]>(
                this.functions,
                'getResPaxTransfers'
            )(request)
        ).data;
}
