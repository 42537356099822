import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WrappedSetter } from '../stores/BaseStores/BaseStore';

export interface WrappedUseStateReturn<T> {
    readonly data: T;
    readonly setData: WrappedSetter<T>;
}

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useSingle<T>(value: T): T {
    const ref = useRef(value);
    return ref.current;
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function WrappedUseState<T>(defaultValue: T): WrappedUseStateReturn<T> {
    const [data, set] = useState<T>(defaultValue);
    const setData: WrappedSetter<T> = (
        next: T | ((prev: T) => T),
        isMounted: React.MutableRefObject<boolean>
    ) => {
        if (isMounted.current) {
            set(next instanceof Function ? (prev) => next(prev) : next);
        }
    };
    return { data, setData };
}
