import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { ReactNode, useEffect, useState } from 'react';
import SnackbarManager from './SnackbarManager';

export interface SnackbarViewProps {
    readonly timeout?: number;
    readonly severity?: 'success' | 'info' | 'warning' | 'error';
    readonly message: ReactNode;
}

export default function SnackbarContainer() {
    const [props, setProps] = useState<SnackbarViewProps | undefined>();

    useEffect(() => {
        SnackbarManager.addChangeListener(setProps);
    }, []);

    return props !== undefined ? (
        <Snackbar
            open={props !== undefined}
            autoHideDuration={props?.timeout || 2500}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert variant="filled" severity={props?.severity} style={{ alignItems: 'center' }}>
                {props?.message}
            </Alert>
        </Snackbar>
    ) : null;
}
