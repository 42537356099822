"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyPrivateCollectionId = exports.EmailType = exports.CompanyFeature = exports.ReturnPolicy = exports.ContactType = exports.CompanyStatus = exports.CompanyType = void 0;
var CompanyType;
(function (CompanyType) {
    CompanyType["OPERATOR"] = "operator";
    CompanyType["AGENT"] = "agent";
})(CompanyType = exports.CompanyType || (exports.CompanyType = {}));
var CompanyStatus;
(function (CompanyStatus) {
    CompanyStatus["INCOMPLETE"] = "incomplete";
    CompanyStatus["ACTION_REQUIRED"] = "action_required";
    CompanyStatus["PENDING"] = "pending";
    CompanyStatus["DISABLED"] = "disabled";
    CompanyStatus["COMPLETE"] = "complete";
})(CompanyStatus = exports.CompanyStatus || (exports.CompanyStatus = {}));
var ContactType;
(function (ContactType) {
    ContactType["ADMIN"] = "admin";
    ContactType["SALES"] = "sales";
    ContactType["ACCOUNTS"] = "accounts";
})(ContactType = exports.ContactType || (exports.ContactType = {}));
var ReturnPolicy;
(function (ReturnPolicy) {
    ReturnPolicy["FULL_FARE"] = "full_fare";
    ReturnPolicy["NOTHING"] = "nothing";
})(ReturnPolicy = exports.ReturnPolicy || (exports.ReturnPolicy = {}));
var CompanyFeature;
(function (CompanyFeature) {
    CompanyFeature["TASK"] = "task";
    CompanyFeature["NOTE"] = "note";
})(CompanyFeature = exports.CompanyFeature || (exports.CompanyFeature = {}));
var EmailType;
(function (EmailType) {
    EmailType["BOOKING_CREATED"] = "booking_created";
    EmailType["BOOKING_UPDATED"] = "booking_updated";
    EmailType["BOOKING_CANCELLATION"] = "booking_cancellation";
    EmailType["BOOKING_PENDING_UPDATE"] = "booking_pending_update";
    EmailType["BOOKING_UPDATE_REJECTED"] = "booking_update_rejected";
    EmailType["BOOKING_REFUND"] = "booking_refund";
    EmailType["PAYMENT_FAILED"] = "payment_failed";
    EmailType["PERMISSION_REQUEST"] = "permission_request";
    EmailType["PAYMENT_LINK"] = "payment_link";
})(EmailType = exports.EmailType || (exports.EmailType = {}));
var CompanyPrivateCollectionId;
(function (CompanyPrivateCollectionId) {
    CompanyPrivateCollectionId["LINKS"] = "links";
})(CompanyPrivateCollectionId = exports.CompanyPrivateCollectionId || (exports.CompanyPrivateCollectionId = {}));
