import {
    collection,
    collectionGroup,
    endAt,
    Firestore,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    startAt,
    where
} from 'firebase/firestore';
import { ApprovalResponse, BookingGroupItem, Collection } from 'firebase_api';
import { last } from 'lodash';
import { CrudRepository } from '../CrudRepository';
import { DocumentSnapshotAny, toEntities } from '../utils';

export class BookingGroupItemRepository extends CrudRepository<BookingGroupItem> {
    constructor(readonly batchLimit: number, db: Firestore) {
        super(db, Collection.BOOKINGS, Collection.BOOKING_GROUPS);
    }

    public getAllByCompanyId = async (groupId: string, companyId: string) => {
        const queryResult = query(
            collection(this.db, `${this.rootCollection}/${groupId}/${this.collectionName}`),
            where('companies', 'array-contains', companyId)
        );
        const snapshot = await getDocs(queryResult);
        return toEntities<BookingGroupItem>(snapshot.docs);
    };

    public getAllByGroupsAndCompanyId = async (groupdIds: string[], companyId: string) => {
        const result: { [groupId: string]: BookingGroupItem[] } = {};
        const promises = groupdIds.map(async (groupdId) => {
            result[groupdId] = await this.getAllByCompanyId(groupdId, companyId);
        });
        await Promise.all(promises);
        return result;
    };

    public getPendingLive = (
        companyId: string,
        isAgentCompany: boolean,
        callback: (bookings: BookingGroupItem[]) => void,
        onError: (error: Error) => void
    ): (() => void) => {
        const confirmedField = isAgentCompany
            ? 'pendingChange.approvals.agentResponse'
            : 'pendingChange.approvals.operatorResponse';
        return onSnapshot(
            query(
                collectionGroup(this.db, this.collectionName),
                where('companies', 'array-contains', companyId),
                where(confirmedField, '==', ApprovalResponse.PENDING)
            ),
            (snapshot) => callback(toEntities<BookingGroupItem>(snapshot.docs)),
            (error) => onError(error)
        );
    };

    public getByCompanyIdAndRefNumber = async (
        companyId: string,
        bookingRefNr: string,
        currnetLimit: number = 15
    ): Promise<BookingGroupItem[]> => {
        const searchableRefNr = bookingRefNr.toUpperCase();
        const queryResult = query(
            collectionGroup(this.db, this.collectionName),
            where('companies', 'array-contains', companyId),
            orderBy('reference'),
            startAt(searchableRefNr),
            endAt(searchableRefNr + '\uf8ff'),
            limit(currnetLimit)
        );
        const snapshot = await getDocs(queryResult);
        return toEntities<BookingGroupItem>(snapshot.docs);
    };

    public productHasAssociatedBooking = async (
        productId: string,
        companyId: string
    ): Promise<boolean> => {
        const queryResult = query(
            collectionGroup(this.db, this.collectionName),
            where('companies', 'array-contains', companyId),
            where('productId', '==', productId),
            limit(1)
        );
        return (await getDocs(queryResult)).size > 0;
    };

    public getItemsByCustomerId = async (groupId: string, customerId: string) => {
        const queryResult = query(
            collection(this.db, `${this.getRoot(groupId)}${this.collectionName}`),
            where('customerId', '==', customerId)
        );
        const snapshot = await getDocs(queryResult);
        return toEntities<BookingGroupItem>(snapshot.docs);
    };

    public getAllByCompany = async (
        companyId: string,
        isPaid?: boolean,
        hasPendingChanges?: boolean,
        lastDoc?: DocumentSnapshotAny
    ) => {
        let queryResult = query(
            collectionGroup(this.db, this.collectionName),
            where('companies', 'array-contains', companyId),
            limit(this.batchLimit),
            orderBy('updatedAt', 'desc')
        );
        if (isPaid) {
            queryResult = query(queryResult, where('isPaid', '==', isPaid));
        }
        if (hasPendingChanges) {
            queryResult = query(queryResult, where('hasPendingChanges', '==', hasPendingChanges));
        }
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return {
            entities: toEntities<BookingGroupItem>(snapshot.docs),
            lastDoc: last(snapshot.docs)
        };
    };

    public getNewUpdatedLive = (
        companyId: string,
        updatedAt: string,
        onChange: (bookings: BookingGroupItem[]) => void,
        onError: (error: any) => void
    ) =>
        onSnapshot(
            query(
                collectionGroup(this.db, this.collectionName),
                where('companies', 'array-contains', companyId),
                where('updatedAt', '>=', updatedAt)
            ),
            (snapshot) => onChange(toEntities<BookingGroupItem>(snapshot.docs)),
            onError
        );
}
