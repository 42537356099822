"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewTypes = exports.UserStatus = exports.Role = void 0;
var Role;
(function (Role) {
    Role["OWNER"] = "owner";
    Role["ADMIN"] = "admin";
    Role["ACCOUNTS"] = "accounts";
    Role["EXTENSION"] = "extension";
    Role["SALES"] = "sales";
    Role["PARSE_ADMIN"] = "parseportsAdmin";
})(Role = exports.Role || (exports.Role = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["ACTIVE"] = "active";
    UserStatus["PENDING"] = "pending";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var ViewTypes;
(function (ViewTypes) {
    ViewTypes["LOCATIONS"] = "locations";
    ViewTypes["BOOKINGS"] = "bookingTable";
    ViewTypes["TRANSACTIONS"] = "transactionTable";
})(ViewTypes = exports.ViewTypes || (exports.ViewTypes = {}));
