import { collection, Firestore, getDocs, orderBy, query, where } from 'firebase/firestore';
import { Collection, SupportArticle, SupportCategories } from 'firebase_api';
import { CrudRepository } from '../CrudRepository';
import { toEntities } from '../utils';

export class SupportRepository extends CrudRepository<SupportArticle> {
    constructor(protected readonly db: Firestore) {
        super(db, Collection.SUPPORT_ARTICLES);
    }

    public getByCategory = async (category: SupportCategories) => {
        const queryResult = query(
            collection(this.db, this.collectionName),
            where('category', '==', category),
            orderBy('updatedAt')
        );
        const snapshot = await getDocs(queryResult);
        return toEntities<SupportArticle>(snapshot.docs);
    };
}
