import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogContentProps,
    DialogProps,
    DialogTitle,
    DialogTitleProps,
    Grid,
    IconButton,
    Slide,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactNode } from 'react';
import { checkExtension } from '../../../libs/utils/functions';
import LoadingButton, { PLoadingButtonProps } from './LoadingButton';

export const NoActionBox = styled(Box)(() => ({
    padding: '10px 5px'
}));

interface PDialogProps {
    readonly dialogProps: DialogProps;
    readonly titleProps?: DialogTitleProps;
    readonly contentProps?: DialogContentProps;
    readonly actionProps?: DialogActionsProps;
    readonly useTransition?: boolean;

    readonly title: string | ReactNode;
    readonly actions?: ReactNode;

    readonly children: ReactNode;

    readonly cancelButtonText?: string;
    readonly submitButtonText?: string;
    readonly backButtonText?: string;

    readonly cancelButtonProps?: PLoadingButtonProps;
    readonly submitButtonProps?: PLoadingButtonProps;
    readonly backButtonProps?: PLoadingButtonProps;
    readonly buttonsJustify?: 'center' | 'flex-end' | 'flex-start';
    readonly submitButtonGridStyle?: React.CSSProperties;

    readonly onClose?: () => void;
    readonly noClickAwayListener?: boolean;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PDialog(props: PDialogProps) {
    const theme = useTheme();
    const isExtension = checkExtension();

    return (
        <Dialog
            {...props.dialogProps}
            onClose={props.noClickAwayListener ? undefined : props.onClose}
            TransitionComponent={props.useTransition ? Transition : undefined}
            PaperProps={{
                sx: isExtension ? {} : { m: '12px', width: 'calc(100% - 24px)' }
            }}>
            <DialogTitle
                {...props.titleProps}
                sx={{
                    backgroundColor: theme.palette.background.dialogTitle,
                    p: isExtension ? 0 : '4px 10px 4px 20px',
                    [theme.breakpoints.only('xs')]: {
                        p: isExtension ? 0 : '0 0 0 20px'
                    }
                }}
                style={{ ...props.titleProps?.style }}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ backgroundColor: props.titleProps?.style?.backgroundColor }}>
                    <Grid item xs={true}>
                        {typeof props.title === 'string' ? (
                            <Typography variant="h4" style={{ fontWeight: 500 }}>
                                {props.title}
                            </Typography>
                        ) : (
                            props.title
                        )}
                    </Grid>
                    {props.onClose && (
                        <Grid item xs={'auto'}>
                            <IconButton
                                onClick={props.onClose}
                                size="large"
                                style={isExtension ? { margin: '-100px 10px 0px 0px' } : {}}>
                                <CloseIcon style={{ color: props.titleProps?.style?.color }} />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </DialogTitle>
            <DialogContent
                sx={{
                    [theme.breakpoints.only('xs')]: {
                        p: !isExtension && '12px'
                    }
                }}
                dividers={!!(props.actions || props.cancelButtonText || props.submitButtonText)}
                {...props.contentProps}>
                {props.children}
            </DialogContent>
            {props.actions || (
                <DialogActions
                    sx={{
                        p: isExtension ? '20px' : '14px 24px',
                        [theme.breakpoints.only('xs')]: {
                            p: isExtension ? '20px' : '12px'
                        }
                    }}
                    {...props.actionProps}>
                    {(props.backButtonProps || props.backButtonText) && (
                        <Grid item xs={2}>
                            <LoadingButton
                                variant="text"
                                onClick={props.onClose}
                                {...props.backButtonProps}>
                                {props.backButtonText || 'Back'}
                            </LoadingButton>
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        spacing={2}
                        xs={props.backButtonProps ? 10 : 'auto'}
                        justifyContent={props.buttonsJustify || 'flex-end'}>
                        {(props.cancelButtonProps || props.cancelButtonText) && (
                            <Grid item>
                                <LoadingButton
                                    variant="text"
                                    onClick={props.onClose}
                                    {...props.cancelButtonProps}>
                                    {props.cancelButtonText || 'Cancel'}
                                </LoadingButton>
                            </Grid>
                        )}
                        {props.submitButtonText && (
                            <Grid item style={props.submitButtonGridStyle}>
                                <LoadingButton variant="contained" {...props.submitButtonProps}>
                                    {props.submitButtonText}
                                </LoadingButton>
                            </Grid>
                        )}
                    </Grid>
                </DialogActions>
            )}
        </Dialog>
    );
}
