import { useTheme } from '@mui/material/styles';
import { Product } from 'firebase_api';
import { isEmpty } from 'lodash';
import { Color, createColor } from 'material-ui-color';
import { useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    IFRAME_CERTAIN_CLOSE_MESSAGE,
    IFRAME_UNCERTAIN_CLOSE_MESSAGE
} from '../../../../components/constants';
import { productRepository } from '../../../../firebase/firebaseIndex';
import { DocumentSnapshotAny } from '../../../../firebase/firestore/utils';
import {
    createStore,
    createStoreContext,
    DefaultProviderProps
} from '../../../../libs/stores/common';
import { ignoreCase } from '../../../../libs/utils/functions';
import SnackbarManager from '../../../components/common/snackbar/SnackbarManager';
import { GlobalController } from '../../../components/wrappers/GlobalController';
import IsMounted from '../../../components/wrappers/IsMounted';
import { State, StateType } from '../../../components/wrappers/State';
import { routes, ROUTE_STATE } from '../../../utils/mainMenu/routes';
import { AccordionPanels } from './ExtensionsPage';

export interface ButtonSize {
    readonly width: number;
    readonly height: number;
}

export enum ButtonAlign {
    RIGHT = 'Align right',
    LEFT = 'Align left',
    CENTER = 'Align center'
}

export enum FontWeight {
    LIGHT = 400,
    MEDIUM = 500,
    HEAVY = 600
}

export enum PaddingType {
    TOP = 'Top',
    RIGHT = 'Right',
    BOTTOM = 'Bottom',
    LEFT = 'Left'
}

export const SEARCH_DEFAULT_LIMIT = 10;

export interface ContainerPaddingProps {
    readonly isParentDivChecked: boolean;
    readonly defaultPadding: string;
    readonly changeAllPadding: (value: string) => void;
    readonly parentDivPaddings: { [key: string]: string };
    readonly parentDivPadding?: string;
    readonly changeParentDivState: () => void;
    readonly changeButtonPadding: (paddingType: PaddingType, value: string) => void;
}

export interface ExtensionsPageControllerValue extends ContainerPaddingProps {
    readonly buttonSizeOption: ButtonSize;
    readonly textSize: number;
    readonly fontWeight: FontWeight;
    readonly roundEdgeSize: number;
    readonly buttonColor: Color;
    readonly buttonTextColor: Color;
    readonly buttonLastColor: string;
    readonly buttonTextLastColor: string;
    readonly fetchingProducts: boolean;
    readonly isFirstCodeStep: StateType<boolean>;
    readonly searchProductValue: string;
    readonly products: Product[];
    readonly buttonPositionInParentDiv: 'center' | 'flex-start' | 'flex-end';
    readonly selectedButton: ButtonAlign;
    readonly activeTab: StateType<AccordionPanels>;

    readonly changeNumberValue: (
        type: 'text' | 'button' | 'weight',
        value: number,
        name?: string
    ) => void;
    readonly changeRoundEdgeSize: (value: string) => void;
    readonly changeButtonColor: (color: Color) => void;
    readonly changeButtonTextColor: (color: Color) => void;
    readonly onSearchFieldChanged: (productName: string) => void;
    readonly fetchProducts: (productName: string, limit: number) => void;
    readonly onSelectProduct: (product: Product) => void;
    readonly generateCodeStep1: () => string;
    readonly generateCodeStep2: () => string;
    readonly changeButtonAlignment: (align: ButtonAlign) => void;
    readonly isSearchFormOpen: StateType<boolean>;
    readonly selectedProduct: StateType<Product>;
    readonly codeSnippetDialogText: StateType<string>;
    readonly isCodeSnippetDialogOpen: StateType<boolean>;
    readonly isAllProductFetched: StateType<boolean>;
    readonly copyCodeToClipboard: () => void;
}

export const ExtensionsPageController = createStoreContext<ExtensionsPageControllerValue>();

export const ExtensionsPageControllerProvider = ({ children }: DefaultProviderProps) => {
    const theme = useTheme();
    const isMounted = IsMounted();
    const buttonSizeOption = State<ButtonSize>({ height: 45, width: 180 }, isMounted);
    const buttonLastColor = State<string>(theme.palette.primary.main.replace('#', ''), isMounted);
    const buttonTextLastColor = State<string>(
        theme.palette.common.white.replace('#', ''),
        isMounted
    );
    const roundEdgeSize = State<number>(10, isMounted);
    const buttonColor = State<Color>(createColor(theme.palette.primary.main), isMounted);
    const buttonTextColor = State<Color>(createColor(theme.palette.common.white), isMounted);
    const textSize = State<number>(16, isMounted);
    const fontWeight = State<FontWeight>(FontWeight.MEDIUM, isMounted);
    const isSearchFormOpen = State(false, isMounted);
    const fetchingProducts = State(false, isMounted);
    const isFirstCodeStep = State(true, isMounted);
    const searchProductValue = State('', isMounted);
    const products = State<Product[]>([], isMounted);
    const allProducts = State<Product[]>([], isMounted);
    const lastDoc = useRef<DocumentSnapshotAny | undefined>(undefined);
    const visibleProductCount = State<number>(SEARCH_DEFAULT_LIMIT, isMounted);
    const isAllProductFetched = State<boolean>(false, isMounted);
    const selectedProduct = State<Product>({}, isMounted);
    const codeSnippetDialogText = State<string>('', isMounted);
    const isCodeSnippetDialogOpen = State(false, isMounted);
    const globalController = useContext(GlobalController);
    const isParentDivChecked = State<boolean>(false, isMounted);
    const buttonPositionInParentDiv = State<'center' | 'flex-start' | 'flex-end'>(
        'center',
        isMounted
    );
    const selectedButton = State<ButtonAlign>(ButtonAlign.CENTER, isMounted);
    const defaultPadding = State<string>('24', isMounted);
    const parentDivPadding = State<string>('24px 24px 24px 24px', isMounted);
    const parentDivPaddings = State<{ [key: string]: string }>(
        Object.values(PaddingType).reduce(
            (acc, key) => ({ ...acc, [key]: defaultPadding.data }),
            {}
        ),
        isMounted
    );
    const activeTab = State(AccordionPanels.BUTTON, isMounted);

    useEffect(() => {
        fetchProducts();
    }, []);

    const changeParentDivState = () => {
        isParentDivChecked.set(!isParentDivChecked.data);
        if (isParentDivChecked.data) {
            changeButtonAlignment(ButtonAlign.CENTER);
        }
    };

    const changeButtonAlignment = (align: ButtonAlign) => {
        switch (align) {
            case ButtonAlign.CENTER:
                selectedButton.set(ButtonAlign.CENTER);
                buttonPositionInParentDiv.set('center');
                break;
            case ButtonAlign.LEFT:
                selectedButton.set(ButtonAlign.LEFT);
                buttonPositionInParentDiv.set('flex-start');
                break;
            case ButtonAlign.RIGHT:
                selectedButton.set(ButtonAlign.RIGHT);
                buttonPositionInParentDiv.set('flex-end');
                break;
        }
    };

    const changeButtonPadding = (paddingType: PaddingType, value: string) => {
        const verifiedValue = String(value.replace(/[^0-9]+/g, ''));
        parentDivPaddings.data[paddingType] = verifiedValue || '0';
        parentDivPadding.set(
            parentDivPaddings.data[PaddingType.TOP] +
                'px ' +
                parentDivPaddings.data[PaddingType.RIGHT] +
                'px ' +
                parentDivPaddings.data[PaddingType.BOTTOM] +
                'px ' +
                parentDivPaddings.data[PaddingType.LEFT] +
                'px'
        );
    };

    const changeAllPadding = (value: string) => {
        const verifiedValue = String(value.replace(/[^0-9]+/g, ''));
        defaultPadding.set(verifiedValue);
        Object.values(PaddingType).map(
            (paddingType) => (parentDivPaddings.data[paddingType] = verifiedValue || '0')
        );
        parentDivPadding.set(
            parentDivPaddings.data[PaddingType.TOP] +
                'px ' +
                parentDivPaddings.data[PaddingType.RIGHT] +
                'px ' +
                parentDivPaddings.data[PaddingType.BOTTOM] +
                'px ' +
                parentDivPaddings.data[PaddingType.LEFT] +
                'px'
        );
    };

    const changeNumberValue = (
        type: 'text' | 'button' | 'weight',
        value: number,
        name?: string
    ) => {
        if (type === 'button') {
            buttonSizeOption.set((prev) => ({
                ...prev,
                [name!]: value
            }));
        } else if (type === 'text') {
            textSize.set(value);
        } else {
            fontWeight.set(value);
        }
    };

    const changeRoundEdgeSize = (value: string) => {
        const edgeSize = Number(value.replace(/[^0-9]+/g, ''));
        roundEdgeSize.set(edgeSize);
    };

    const changeButtonColor = (color: Color) => {
        buttonColor.set(color);
        if (isHexColor(color.hex)) {
            buttonLastColor.set(color.hex);
        }
    };

    const changeButtonTextColor = (color: Color) => {
        buttonTextColor.set(color);
        if (isHexColor(color.hex)) {
            buttonTextLastColor.set(color.hex);
        }
    };

    const fetchProducts = async (productName?: string, limit = SEARCH_DEFAULT_LIMIT) => {
        try {
            fetchingProducts.set(true);
            if (isAllProductFetched.data) {
                const tempVisibleProductCount = products.data.length + limit;
                const tempFilterResult = filterProducts((productName || '').trim());
                if (tempVisibleProductCount < tempFilterResult.length) {
                    products.set(tempFilterResult.slice(0, tempVisibleProductCount));
                    visibleProductCount.set(tempVisibleProductCount);
                } else {
                    products.set(tempFilterResult);
                    visibleProductCount.set(tempFilterResult.length);
                }
                return;
            }
            const data = await productRepository.getProductsWithLimit(lastDoc.current, limit);
            const result = ([] as Product[])
                .concat(data.entities)
                .reduce((acc, act) => ({ ...acc, [act.id!]: act }), {});
            if (data.lastDoc) {
                lastDoc.current = data.lastDoc;
            } else {
                const tempAllProducts = products.data.concat(Object.values(result));
                allProducts.set(tempAllProducts);
                isAllProductFetched.set(true);
            }
            visibleProductCount.set(Object.values(result).length);
            products.set((prev) => prev.concat(Object.values(result)));
        } catch (error) {
            SnackbarManager.error(error, 'ExtensionsPageController-fetchProducts');
        } finally {
            fetchingProducts.set(false);
        }
    };

    const fetchAllProduct = async (searchValue?: string) => {
        try {
            fetchingProducts.set(true);
            if (isAllProductFetched.data) {
                const tempVisibleProductCount = allProducts.data.length;
                products.set(
                    filterProducts((searchValue || '').trim(), allProducts.data).slice(
                        0,
                        tempVisibleProductCount || visibleProductCount.data
                    )
                );
                return;
            }
            const allProduct = await productRepository.getAllProduct(undefined, lastDoc.current);
            const result = ([] as Product[])
                .concat(allProduct.entities)
                .reduce((acc, act) => ({ ...acc, [act.id!]: act }), {});
            const tempAllProducts = products.data.concat(Object.values(result));
            allProducts.set(tempAllProducts);
            products.set(
                filterProducts((searchValue || '').trim(), tempAllProducts).slice(
                    0,
                    visibleProductCount.data
                )
            );
            isAllProductFetched.set(true);
        } catch (error) {
            SnackbarManager.error(error, 'ExtensionsPageController-fetchAllProduct');
        } finally {
            fetchingProducts.set(false);
        }
    };

    const filterProducts = (searchValue: string, tempAllProducts?: Product[]) =>
        searchValue
            ? (tempAllProducts || allProducts.data).filter(
                  (product) =>
                      ignoreCase(product.name).includes(ignoreCase(searchValue)) ||
                      ignoreCase(product.company?.name).includes(ignoreCase(searchValue))
              )
            : tempAllProducts || allProducts.data;

    const onSearchFieldChanged = (productName: string) => {
        searchProductValue.set(productName);
        visibleProductCount.set(SEARCH_DEFAULT_LIMIT);
        fetchAllProduct(productName);
    };

    const onSelectProduct = (product: Product) => {
        selectedProduct.set(product);
        isSearchFormOpen.set(false);
    };

    const generateCodeStep1 = () =>
        `<script>
    function closeIframe(element) {
        element.style.display="none";
        element.children[0].removeAttribute('src');
    }
    function getMaxZIndex() {
        return Math.max(
            ...Array.from(document.querySelectorAll('body *'), el =>
              parseFloat(window.getComputedStyle(el).zIndex),
            ).filter(zIndex => !Number.isNaN(zIndex)),
            0,
          ) + 1;
    }
    window.addEventListener('click', function(e) { 
        var modal= document.getElementsByClassName('parseBookingModal');
        for (var i = 0; i < modal.length; i++) {
            var currentEl = modal[i];
            if (currentEl.contains(e.target) && confirm('Are you sure you want to close?')) {
                closeIframe(currentEl);
            } 
        }
    });
    window.addEventListener('message', (event) => {
        if(event.origin === '${window.location.origin}') {
            var modal= document.getElementsByClassName('parseBookingModal')[0];
            if (event.data === '${IFRAME_CERTAIN_CLOSE_MESSAGE}') closeIframe(modal);
            if (event.data === '${IFRAME_UNCERTAIN_CLOSE_MESSAGE}' && confirm('Are you sure you ` +
        `want to abandon your booking? Your information will be lost.')) closeIframe(modal);
        }
    }, false);
    </script>
    <style>
        .conditionalDisplay {
            width:100%;
            height:95%;
            max-width:598px;
            position:absolute;
            bottom:0;
            border:none;
            border-radius:0px;
            background-color: ${theme.palette.background.default};
        }
        .parseBookingModal {
            position:fixed;
            margin-left:auto;
            z-index:1;
            margin-right:auto;
            top:0;left:0;right:0;
            width:100%;height:100%;
            background-color:rgba(0,0,0,0.5);
        }
        @media (min-width:600px) {
            .conditionalDisplay { 
                max-width:600px;
                max-height: 1000px;
                left: 50%;
                top: 50%;
                transform: translate(-49%, -50%);
                border-radius:${roundEdgeSize.data > 20 ? 20 : roundEdgeSize.data}px
        }}
    </style>`;

    const generateCodeStep2 = () => {
        const uuid = uuidv4();
        const parentDiv = isParentDivChecked.data
            ? `<div style="padding:${parentDivPadding.data};display:flex;justify-content:${buttonPositionInParentDiv.data}"> 
            `
            : '';
        return (
            parentDiv +
            `<button style="border:none;border-radius:${
                roundEdgeSize.data
            }px;cursor:pointer; width:${buttonSizeOption.data.width}px;height:${
                buttonSizeOption.data.height
            }px;${`font-size:${textSize.data}px;font-weight:${fontWeight.data}`};color:#${
                buttonTextLastColor.data + ';background:#' + buttonLastColor.data
            };" onclick="(function(){var modal=document.getElementById('parseBookingModal-${uuid}');` +
            `modal.style.display=''; modal.style.zIndex=getMaxZIndex();` +
            `var iFrame=document.getElementById('parseBookingIframe-${uuid}');` +
            `iFrame.setAttribute('src', '${
                window.location.origin
            }${routes.extensions.button.authentication(
                globalController.company.id || '',
                selectedProduct.data.id || ''
            )}?${ROUTE_STATE.HEADER_BG_COLOR}=${buttonLastColor.data}&${
                ROUTE_STATE.HEADER_FONT_COLOR
            }=${buttonTextLastColor.data}&${ROUTE_STATE.BUTTON_RADIUS}=${roundEdgeSize.data}&${
                ROUTE_STATE.PRODUCT_NAME
            }=${encodeURIComponent(selectedProduct.data.name || '')}&${
                ROUTE_STATE.COMPANY_NAME
            }=${encodeURIComponent(selectedProduct.data.company?.name || '')}&${
                ROUTE_STATE.IS_LIVE_AVAILABILITY
            }=${!isEmpty(selectedProduct.data.provider)}');})();">
    Book Now
</button>\r` +
            `<div id="parseBookingModal-${uuid}" class="parseBookingModal" style="display:none;">` +
            `
                <iframe id="parseBookingIframe-${uuid}" class="conditionalDisplay"
                title="Booking Flow">Your browser does not support iframes.
    </iframe>
</div>` +
            `${isParentDivChecked.data ? '</div>' : ''}`
        );
    };

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(codeSnippetDialogText.data);
    };

    return createStore(ExtensionsPageController, children, {
        buttonLastColor: buttonLastColor.data,
        textSize: textSize.data,
        fontWeight: fontWeight.data,
        buttonTextLastColor: buttonTextLastColor.data,
        buttonSizeOption: buttonSizeOption.data,
        roundEdgeSize: roundEdgeSize.data,
        buttonColor: buttonColor.data,
        buttonTextColor: buttonTextColor.data,
        fetchingProducts: fetchingProducts.data,
        searchProductValue: searchProductValue.data,
        products: products.data,
        isParentDivChecked: isParentDivChecked.data,
        buttonPositionInParentDiv: buttonPositionInParentDiv.data,
        selectedButton: selectedButton.data,
        defaultPadding: defaultPadding.data,
        parentDivPadding: parentDivPadding.data,
        parentDivPaddings: parentDivPaddings.data,
        changeNumberValue,
        changeRoundEdgeSize,
        changeButtonColor,
        changeButtonTextColor,
        onSearchFieldChanged,
        fetchProducts,
        onSelectProduct,
        copyCodeToClipboard,
        generateCodeStep1,
        generateCodeStep2,
        changeParentDivState,
        changeButtonAlignment,
        changeButtonPadding,
        changeAllPadding,
        codeSnippetDialogText,
        isFirstCodeStep,
        isSearchFormOpen,
        isCodeSnippetDialogOpen,
        selectedProduct,
        activeTab,
        isAllProductFetched
    });
};

export const isHexColor = (color: string) => {
    if (isEmpty(color)) return false;
    const s = new Option().style;
    s.color = color;
    const test1 = s.color === color;
    const test2 = /^[0-9a-fA-F](?:.{2}|.{5}|.{7})$/i.test(color);
    if (test1 || test2) {
        return true;
    } else {
        return false;
    }
};
