"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipantInfoFieldType = void 0;
var ParticipantInfoFieldType;
(function (ParticipantInfoFieldType) {
    ParticipantInfoFieldType["TEXT"] = "text";
    ParticipantInfoFieldType["PHONE"] = "phone";
    ParticipantInfoFieldType["DATE"] = "date";
    ParticipantInfoFieldType["BOOLEAN"] = "boolean";
    ParticipantInfoFieldType["NUMBER"] = "number";
    ParticipantInfoFieldType["LIST"] = "list";
    ParticipantInfoFieldType["EMAIL"] = "email";
})(ParticipantInfoFieldType = exports.ParticipantInfoFieldType || (exports.ParticipantInfoFieldType = {}));
