"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransferPrefix = exports.TransferStatus = exports.PARSEPORTS = void 0;
exports.PARSEPORTS = 'parseports';
var TransferStatus;
(function (TransferStatus) {
    TransferStatus["PENDING"] = "pending";
    TransferStatus["PROCESSED"] = "processed";
    TransferStatus["FAILED"] = "failed";
})(TransferStatus = exports.TransferStatus || (exports.TransferStatus = {}));
var TransferPrefix;
(function (TransferPrefix) {
    TransferPrefix["TRANSFER"] = "transfer_";
    TransferPrefix["REVERSAL"] = "reversal_";
    TransferPrefix["REFUND"] = "refund_";
})(TransferPrefix = exports.TransferPrefix || (exports.TransferPrefix = {}));
