import { useRef } from 'react';
import { functions, storageRef } from '../firebase/firebaseIndex';
import { createStore, createStoreContext, DefaultProviderProps } from '../libs/stores/common';
import { ImagesApi, ImagesApiImpl } from './ImagesApi';

export interface InjectorValue {
    readonly imagesApi: ImagesApi;
}

export const Injector = createStoreContext<InjectorValue>();

export const InjectorProvider = ({ children }: DefaultProviderProps) => {
    const imagesApi = useRef(new ImagesApiImpl(functions, storageRef));

    return createStore(Injector, children, { imagesApi: imagesApi.current });
};
