import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

const StyleIconButton = withStyles((theme) => ({
    root: { backgroundColor: theme.palette.background.deleteBtn, borderRadius: 4, padding: 10 }
}))(IconButton);

interface DeleteButtonProps {
    readonly onClick: () => void;
    readonly buttonProps?: IconButtonProps;
    readonly iconProps?: SvgIconProps;
}

const DeleteButton = (props: DeleteButtonProps) => (
    <StyleIconButton {...props.buttonProps} onClick={props.onClick}>
        <DeleteOutlineIcon {...props.iconProps} />
    </StyleIconButton>
);

export default React.memo(DeleteButton);
