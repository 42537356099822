import { doc, Firestore, getDoc, setDoc, writeBatch } from 'firebase/firestore';
import {
    Collection,
    Company,
    CompanyType,
    Role,
    setCreatedUpdatedAt,
    setUpdatedAt,
    User
} from 'firebase_api';
import { AgentEmailTypes, OperatorEmailTypes } from '../company/CompanyRepository';
import { CrudRepository } from '../CrudRepository';

export class UserRepository extends CrudRepository<User> {
    constructor(db: Firestore) {
        super(db, Collection.USERS);
    }

    public createUserAndCompany = async (user: User, companyType: CompanyType): Promise<string> => {
        const batch = writeBatch(this.db);
        const companyDoc = doc(this.db, Collection.COMPANIES);
        const emailPreferences = Object.values(
            companyType === CompanyType.AGENT ? AgentEmailTypes : OperatorEmailTypes
        ).reduce((acc, type) => {
            return { ...acc, [type]: [user.email] };
        }, {});
        batch.set(
            companyDoc,
            setCreatedUpdatedAt({
                type: companyType,
                users: {
                    [user.id!]: [Role.OWNER]
                },
                emailPreferences
            } as Company)
        );

        const userDoc = doc(this.db, Collection.USERS, user?.id || '');
        batch.set(userDoc, {
            ...setCreatedUpdatedAt(user),
            companyIds: [companyDoc.id]
        } as User);

        await batch.commit();
        return companyDoc.id;
    };

    public createIfFirstTime = async (
        userId: string,
        email: string,
        firstName: string,
        lastName: string
    ): Promise<User | undefined> => {
        const userDocRef = doc(this.db, Collection.USERS, userId);
        const user = await getDoc(userDocRef);
        if (!user.exists()) {
            const newUser = setCreatedUpdatedAt({ email, firstName, lastName });
            await setDoc(userDocRef, newUser);
            return newUser;
        }
        return undefined;
    };

    public upsertUser = async (user: User, userId: string, isNew?: boolean) => {
        const docRef = doc(this.db, Collection.USERS, userId);
        const tmpUser = isNew ? setCreatedUpdatedAt(user) : setUpdatedAt(user);
        await setDoc(docRef, tmpUser, { merge: true });
        return user;
    };
}
