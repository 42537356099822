import { EventEmitter } from 'events';
import { ReactNode } from 'react';
import { errorRepository } from '../../../../firebase/firebaseIndex';
import environment from '../../../../libs/environment/env';
import { getLocalStorage } from '../../../../libs/utils/functions';
import { StoreKeys } from '../../wrappers/GlobalController';
import { SnackbarViewProps } from './SnacbarContainer';

class SnackbarManager extends EventEmitter {
    private props: SnackbarViewProps | undefined;

    private show(props: SnackbarViewProps) {
        this.props = props;
        this.emitChange();
        setTimeout(() => this.emit('info', undefined), props.timeout || 3000);
    }

    public success = (message: ReactNode, timeout?: number) =>
        this.show({ message, severity: 'success', timeout });

    public warning = (message: ReactNode, timeout?: number) =>
        this.show({ message, severity: 'warning', timeout });

    public error = (
        error: any,
        place: string,
        message: ReactNode = 'Something went wrong. Try again later.',
        timeout?: number
    ) => {
        this.show({ message, severity: 'error', timeout });
        if (environment.env !== 'prod' && environment.env !== 'demo') {
            console.log(place, error);
        }
        error !== undefined && saveError(error, place);
    };

    remove() {
        this.props = undefined;
        this.emitChange();
    }

    emitChange() {
        this.emit('info', this.props);
    }

    addChangeListener(callback: (props: SnackbarViewProps | undefined) => void) {
        this.addListener('info', callback);
    }
}

const saveError = (error: any, place: string) => {
    const errorDocument = {
        error: errorToString(error),
        companyId: getLocalStorage()?.getItem(StoreKeys.COMPANY_ID) || '',
        userId: getLocalStorage()?.getItem(StoreKeys.USER_ID) || '',
        place: place,
        version: `${environment.version}`
    };
    errorRepository.create(errorDocument);
};

const errorToString = (data: any): string => {
    if (typeof data === 'string') {
        return data;
    }
    return JSON.stringify(data);
};

export default new SnackbarManager();
