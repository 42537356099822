import { useTheme } from '@mui/material/styles';
import { Company, ExtensionCatalog, Product } from 'firebase_api';
import { isEmpty } from 'lodash';
import { Color, createColor } from 'material-ui-color';
import { useContext, useEffect } from 'react';
import { DropResult } from 'react-smooth-dnd';
import {
    IFRAME_CERTAIN_CLOSE_MESSAGE,
    IFRAME_UNCERTAIN_CLOSE_MESSAGE
} from '../../../../../components/constants';
import { companyRepository } from '../../../../../firebase/firebaseIndex';
import {
    createStore,
    createStoreContext,
    DefaultProviderProps
} from '../../../../../libs/stores/common';
import SnackbarManager from '../../../../components/common/snackbar/SnackbarManager';
import { GlobalController } from '../../../../components/wrappers/GlobalController';
import IsMounted from '../../../../components/wrappers/IsMounted';
import { State, StateType } from '../../../../components/wrappers/State';
import { routes, ROUTE_STATE } from '../../../../utils/mainMenu/routes';
import { ContainerPaddingProps, isHexColor, PaddingType } from '../ExtensionsPageController';

export enum ResponsiveOptions {
    PORTRAIT = 'Portrait Format',
    LANDSCAPE = 'Landescape Format',
    DESKTOP = 'Large Screen Format (1280px Max Width)'
}

export interface ProductCatalogControllerValue extends ContainerPaddingProps {
    readonly selectedResponsiveOption: ResponsiveOptions;
    readonly containerCount: number;
    readonly roundEdgeSize: number;
    readonly buttonColor: Color;
    readonly buttonTextColor: Color;
    readonly buttonLastColor: string;
    readonly buttonTextLastColor: string;
    readonly isCreateCatalogFormOpen: StateType<boolean>;
    readonly currentCompany: Company;
    readonly currentCatalog: StateType<ExtensionCatalog>;
    readonly isCatalogSearchOpen: StateType<boolean>;
    readonly selectedCatalogId: StateType<string>;
    readonly openRemoveDialog: StateType<boolean>;
    readonly titleError: StateType<string>;

    readonly changeResponsiveOption: (option: ResponsiveOptions) => void;
    readonly changeRoundEdgeSize: (value: string) => void;
    readonly changeButtonColor: (color: Color) => void;
    readonly changeButtonTextColor: (color: Color) => void;
    readonly addProductToList: (product: Product) => void;
    readonly removeProductFromList: (id: string) => void;
    readonly saveCatalogList: () => void;
    readonly changeCatalogTitle: (value: string) => void;
    readonly generateProductCatalogStep1: () => string;
    readonly generateProductCatalogStep2: () => string;
    readonly onDropFn: ({ removedIndex, addedIndex }: DropResult) => void;
    readonly removeCatalogList: () => void;
}

export const ProductCatalogController = createStoreContext<ProductCatalogControllerValue>();

export const ProductCatalogControllerProvider = ({ children }: DefaultProviderProps) => {
    const theme = useTheme();
    const isMounted = IsMounted();
    const globalController = useContext(GlobalController);
    const currentCompany = State<Company>(globalController.company, isMounted);
    const selectedResponsiveOption = State<ResponsiveOptions>(ResponsiveOptions.DESKTOP, isMounted);
    const containerCount = State<number>(3, isMounted);
    const roundEdgeSize = State<number>(10, isMounted);
    const buttonColor = State<Color>(createColor(theme.palette.primary.main), isMounted);
    const buttonTextColor = State<Color>(createColor(theme.palette.common.white), isMounted);
    const buttonLastColor = State<string>(theme.palette.primary.main.replace('#', ''), isMounted);
    const buttonTextLastColor = State<string>(
        theme.palette.common.white.replace('#', ''),
        isMounted
    );
    const isCreateCatalogFormOpen = State(false, isMounted);
    const isCatalogSearchOpen = State(false, isMounted);
    const currentCatalog = State<ExtensionCatalog>({}, isMounted);
    const selectedCatalogId = State<string>('', isMounted);
    const titleError = State<string>('', isMounted);
    const isParentDivChecked = State<boolean>(false, isMounted);
    const defaultPadding = State<string>('24', isMounted);
    const parentDivPaddings = State<{ [key: string]: string }>(
        Object.values(PaddingType).reduce(
            (acc, key) => ({ ...acc, [key]: defaultPadding.data }),
            {}
        ),
        isMounted
    );
    const parentDivPadding = State<string>('24px 24px 24px 24px', isMounted);
    const openRemoveDialog = State(false, isMounted);

    useEffect(() => {
        currentCompany.set(globalController.company);
    }, [globalController.company]);

    const changeResponsiveOption = (option: ResponsiveOptions) => {
        switch (option) {
            case ResponsiveOptions.PORTRAIT:
                selectedResponsiveOption.set(ResponsiveOptions.PORTRAIT);
                containerCount.set(1);
                break;
            case ResponsiveOptions.LANDSCAPE:
                selectedResponsiveOption.set(ResponsiveOptions.LANDSCAPE);
                containerCount.set(2);
                break;
            case ResponsiveOptions.DESKTOP:
                selectedResponsiveOption.set(ResponsiveOptions.DESKTOP);
                containerCount.set(3);
                break;
        }
    };

    const changeRoundEdgeSize = (value: string) => {
        const edgeSize = Number(value.replace(/[^0-9]+/g, ''));
        roundEdgeSize.set(edgeSize);
    };

    const changeButtonColor = (color: Color) => {
        buttonColor.set(color);
        if (isHexColor(color.hex)) {
            buttonLastColor.set(color.hex);
        }
    };

    const changeButtonTextColor = (color: Color) => {
        buttonTextColor.set(color);
        if (isHexColor(color.hex)) {
            buttonTextLastColor.set(color.hex);
        }
    };

    const changeCatalogTitle = (title: string) => {
        currentCatalog.set((prev) => ({
            ...prev,
            title
        }));
        titleError.set('');
    };

    const changeParentDivState = () => isParentDivChecked.set(!isParentDivChecked.data);

    const changeButtonPadding = (paddingType: PaddingType, value: string) => {
        const verifiedValue = String(value.replace(/[^0-9]+/g, ''));
        parentDivPaddings.data[paddingType] = verifiedValue || '0';
        parentDivPadding.set(
            parentDivPaddings.data[PaddingType.TOP] +
                'px ' +
                parentDivPaddings.data[PaddingType.RIGHT] +
                'px ' +
                parentDivPaddings.data[PaddingType.BOTTOM] +
                'px ' +
                parentDivPaddings.data[PaddingType.LEFT] +
                'px'
        );
    };

    const changeAllPadding = (value: string) => {
        const verifiedValue = String(value.replace(/[^0-9]+/g, ''));
        defaultPadding.set(verifiedValue);
        Object.values(PaddingType).map(
            (paddingType) => (parentDivPaddings.data[paddingType] = verifiedValue || '0')
        );
        parentDivPadding.set(
            parentDivPaddings.data[PaddingType.TOP] +
                'px ' +
                parentDivPaddings.data[PaddingType.RIGHT] +
                'px ' +
                parentDivPaddings.data[PaddingType.BOTTOM] +
                'px ' +
                parentDivPaddings.data[PaddingType.LEFT] +
                'px'
        );
    };

    const addProductToList = (product: Product) => {
        currentCatalog.set((prev) => ({
            ...prev,
            productNamesIds: { ...prev.productNamesIds, [product?.id!]: product?.name || '' },
            productsOrder: (prev?.productsOrder || []).concat(product?.id)
        }));
        isCatalogSearchOpen.set(false);
    };

    const removeProductFromList = (id: string) => {
        const products = currentCatalog.data.productNamesIds;
        delete products?.[id];
        currentCatalog.set((prev) => ({
            ...prev,
            productNamesIds: products,
            productsOrder: currentCatalog.data.productsOrder?.filter((e) => e !== id)
        }));
    };

    const saveCatalogList = async () => {
        try {
            if (currentCatalog.data.title?.trim()) {
                const catalogId = selectedCatalogId.data || companyRepository.getRandomId();
                await companyRepository.update({
                    ...currentCompany.data,
                    extensionCatalog: {
                        ...currentCompany.data.extensionCatalog,
                        [catalogId]: currentCatalog.data
                    }
                });
                isCreateCatalogFormOpen.set(false);
                selectedCatalogId.set(catalogId);
            } else {
                titleError.set('Title cannot be empty string');
            }
        } catch (error) {
            SnackbarManager.error(error, 'ProductCatalogController-saveCatalogList');
        }
    };

    const removeCatalogList = async () => {
        try {
            delete currentCompany.data.extensionCatalog[selectedCatalogId.data];
            await companyRepository.update({
                ...currentCompany.data,
                extensionCatalog: currentCompany.data.extensionCatalog
            });
            selectedCatalogId.set('');
            currentCatalog.set({});
            openRemoveDialog.set(false);
        } catch (error) {
            SnackbarManager.error(error, 'ProductCatalogController-saveCatalogList');
        }
    };

    const onDropFn = ({ removedIndex, addedIndex }: DropResult) => {
        const temp = currentCatalog.data.productsOrder;
        const save = temp[removedIndex];
        temp[removedIndex] = temp[addedIndex];
        temp[addedIndex] = save;
        currentCatalog.set((prev) => ({ ...prev, productsOrder: temp }));
    };

    const generateProductCatalogStep1 = (): string =>
        `<script>
    function closeIframe(element) {
        element.style.display="none";
        element.children[0].removeAttribute('src');
    }
    function openIframe(element, url) {
        element.style.display="";
        element.children[0].setAttribute('src', url);
    }
    window.addEventListener('click', function(e) { 
        var modal= document.getElementsByClassName('parseBookingModal');
        for (var i = 0; i < modal.length; i++) {
            var currentEl = modal[i];
            if (currentEl.contains(e.target) && confirm('Are you sure you want to close?')) {
                closeIframe(currentEl);
            } 
        }
    });
    window.addEventListener('message', (event) => {
        if(event.origin === '${window.location.origin}') {
            var modal= document.getElementsByClassName('parseBookingModal')[0];
            if (event.data === '${IFRAME_CERTAIN_CLOSE_MESSAGE}') { closeIframe(modal); 
            } else if (event.data === '${IFRAME_UNCERTAIN_CLOSE_MESSAGE}' && confirm('Are you sure you ` +
        `want to abandon your booking? Your information will be lost.')){ closeIframe(modal); 
            } else { openIframe(modal,event.data); modal.style.background = "#dfdfdfc7" }
        }
    }, false);
    </script>
    <style>
        .conditionalDisplay {
            width:100%;
            height:100%;
            position:absolute;
            bottom:0;
            border:none;
            border-radius:0px;
            max-width:598px;
        }
        .parseBookingModal {
            position:fixed;
            margin-left:auto;
            z-index:1;
            margin-right:auto;
            top:0;left:0;right:0;
            width:100%;height:100%;
        }
        .productCatalog {
            width:100%;
            height:100%;
            position:fixed;
            border:none;
            border-radius:10px;
        }
        @media (min-width:600px) {
            .conditionalDisplay {
                max-width:600px;
                max-height: 1000px; 
                left: 50%;
                top: 50%;
                transform: translate(-49%, -50%);
                border-radius:${roundEdgeSize.data > 20 ? 20 : roundEdgeSize.data}px
            }
        }
    </style>`;

    const generateProductCatalogStep2 = () =>
        `<div id="parseBookingModal-${selectedCatalogId.data}"\n class="parseBookingModal" style="display:none;">
                <iframe id="parseBookingIframe-${selectedCatalogId.data}" class="conditionalDisplay"
                title="Booking Flow" >Your browser does not support iframes.
        </iframe>
    </div>\n` +
        `<iframe class="productCatalog"
    title="Products Catalog" src="${
        window.location.origin
    }${routes.extensions.button.authentication(
            globalController.company.id || '',
            selectedCatalogId.data
        )}?${ROUTE_STATE.HEADER_BG_COLOR}=${buttonLastColor.data}&${
            ROUTE_STATE.HEADER_FONT_COLOR
        }=${buttonTextLastColor.data}&${ROUTE_STATE.BUTTON_RADIUS}=${roundEdgeSize.data}&${
            ROUTE_STATE.IS_PRODUCT_CATALOG
        }=${!isEmpty(selectedCatalogId.data)}${
            isParentDivChecked.data
                ? `&${ROUTE_STATE.CATALOG_PADDING}=${parentDivPadding.data}`
                : ''
        }">Your browser does not support iframes.
    </iframe>`;

    return createStore(ProductCatalogController, children, {
        selectedResponsiveOption: selectedResponsiveOption.data,
        containerCount: containerCount.data,
        roundEdgeSize: roundEdgeSize.data,
        buttonColor: buttonColor.data,
        buttonTextColor: buttonTextColor.data,
        buttonLastColor: buttonLastColor.data,
        buttonTextLastColor: buttonTextLastColor.data,
        currentCompany: currentCompany.data,
        isParentDivChecked: isParentDivChecked.data,
        defaultPadding: defaultPadding.data,
        parentDivPaddings: parentDivPaddings.data,
        parentDivPadding: parentDivPadding.data,
        isCreateCatalogFormOpen,
        isCatalogSearchOpen,
        currentCatalog,
        selectedCatalogId,
        titleError,
        openRemoveDialog,
        changeParentDivState,
        changeResponsiveOption,
        changeRoundEdgeSize,
        changeButtonColor,
        changeButtonTextColor,
        addProductToList,
        removeProductFromList,
        saveCatalogList,
        changeCatalogTitle,
        generateProductCatalogStep1,
        generateProductCatalogStep2,
        changeAllPadding,
        changeButtonPadding,
        onDropFn,
        removeCatalogList
    });
};
