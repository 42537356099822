"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRICE_OPTIONS_USED_SEATS = exports.PriceOptionLabel = void 0;
var PriceOptionLabel;
(function (PriceOptionLabel) {
    PriceOptionLabel["ADULT"] = "Adult";
    PriceOptionLabel["CHILD"] = "Child";
    PriceOptionLabel["INFANT"] = "Infant";
    PriceOptionLabel["SENIOR"] = "Senior";
    PriceOptionLabel["TEEN"] = "Teen";
    PriceOptionLabel["STUDENT"] = "Student";
    PriceOptionLabel["CONCESSION"] = "Concession";
    PriceOptionLabel["FAMILY_OF_THREE"] = "Family of three";
    PriceOptionLabel["FAMILY_OF_FOUR"] = "Family of four";
    PriceOptionLabel["FAMILY_OF_FIVE"] = "Family of five";
    PriceOptionLabel["ONE_ADULT_TWO_CHILD"] = "One Adult two Child";
    PriceOptionLabel["ONE_ADULT_THREE_CHILD"] = "One Adult three Child";
    PriceOptionLabel["TWO_ADULTS_ONE_CHILD"] = "Two Adults one Child";
    PriceOptionLabel["TWO_ADULTS_TWO_CHILD"] = "Two Adults two Child";
    PriceOptionLabel["TWO_ADULTS_THREE_CHILD"] = "Two Adults three Child";
    PriceOptionLabel["SINGLE"] = "Single";
    PriceOptionLabel["DOUBLE"] = "Double";
    PriceOptionLabel["TWIN"] = "Twin";
    PriceOptionLabel["TWINSHARE"] = "Twinshare";
    PriceOptionLabel["TRIPLE"] = "Triple";
    PriceOptionLabel["QUAD"] = "Quad";
    PriceOptionLabel["PER_ITEM"] = "Per Item";
})(PriceOptionLabel = exports.PriceOptionLabel || (exports.PriceOptionLabel = {}));
exports.PRICE_OPTIONS_USED_SEATS = {
    [PriceOptionLabel.ADULT]: 1,
    [PriceOptionLabel.CHILD]: 1,
    [PriceOptionLabel.INFANT]: 1,
    [PriceOptionLabel.SENIOR]: 1,
    [PriceOptionLabel.TEEN]: 1,
    [PriceOptionLabel.STUDENT]: 1,
    [PriceOptionLabel.CONCESSION]: 1,
    [PriceOptionLabel.FAMILY_OF_THREE]: 3,
    [PriceOptionLabel.FAMILY_OF_FOUR]: 4,
    [PriceOptionLabel.FAMILY_OF_FIVE]: 5,
    [PriceOptionLabel.ONE_ADULT_TWO_CHILD]: 3,
    [PriceOptionLabel.ONE_ADULT_THREE_CHILD]: 4,
    [PriceOptionLabel.TWO_ADULTS_ONE_CHILD]: 3,
    [PriceOptionLabel.TWO_ADULTS_TWO_CHILD]: 4,
    [PriceOptionLabel.TWO_ADULTS_THREE_CHILD]: 5,
    [PriceOptionLabel.SINGLE]: 1,
    [PriceOptionLabel.DOUBLE]: 2,
    [PriceOptionLabel.TWIN]: 2,
    [PriceOptionLabel.TWINSHARE]: 2,
    [PriceOptionLabel.TRIPLE]: 3,
    [PriceOptionLabel.QUAD]: 4,
    [PriceOptionLabel.PER_ITEM]: 1
};
