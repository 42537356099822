import { Box, Skeleton, SxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface ImgWithFallbackProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    readonly fallbackSrc: string;
    readonly skeletonStyles?: React.CSSProperties;
    readonly sx?: SxProps;
    readonly skeletonVariant?: 'circular' | 'rectangular' | 'text' | undefined;
}

export default function ImgWithFallback({
    src,
    sx,
    fallbackSrc,
    onClick,
    skeletonVariant,
    skeletonStyles,
    ...props
}: ImgWithFallbackProps) {
    const [error, setError] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            !src && setError(true);
        }, 1500);
        return () => clearTimeout(timer);
    }, [src]);

    return (
        <Box sx={{ ...sx, display: 'flex' }}>
            <img
                onClick={onClick}
                hidden={!ready}
                {...props}
                src={error ? fallbackSrc : src}
                onLoad={() => setReady(true)}
                onError={() => setError(true)}
                height={props?.height}
            />
            {!ready && (
                <Skeleton
                    variant={skeletonVariant || 'rectangular'}
                    width={skeletonStyles?.width || props.width || props.style?.width}
                    height={skeletonStyles?.height || props.height || props.style?.height}
                    style={{ borderRadius: props.style?.borderRadius, ...skeletonStyles }}
                />
            )}
        </Box>
    );
}
