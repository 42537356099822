import {
    collection,
    endAt,
    Firestore,
    getDocs,
    limit,
    orderBy,
    query,
    startAt,
    where
} from 'firebase/firestore';
import { Collection, Customer } from 'firebase_api';
import { CrudRepository } from '../CrudRepository';
import { toEntities, toEntity } from '../utils';

export class CustomerRepository extends CrudRepository<Customer> {
    constructor(db: Firestore) {
        super(db, Collection.CUSTOMERS, Collection.COMPANIES);
    }

    public getByEmail = async (email: string, companyId: string): Promise<Customer | undefined> => {
        const queryResult = query(
            collection(this.db, `${Collection.COMPANIES}/${companyId}/${Collection.CUSTOMERS}`),
            where('email', '==', email)
        );
        const snapshot = await getDocs(queryResult);
        return snapshot.docs.length > 0 ? toEntity<Customer>(snapshot.docs[0]) : undefined;
    };

    public getByEmailLike = async (
        email: string,
        companyId: string,
        currentLimit = 15
    ): Promise<Customer[]> => {
        const queryResult = query(
            collection(this.db, `${Collection.COMPANIES}/${companyId}/${Collection.CUSTOMERS}`),
            orderBy('email'),
            startAt(email),
            endAt(email + '\uf8ff'),
            limit(currentLimit)
        );
        const snapshot = await getDocs(queryResult);
        return toEntities<Customer>(snapshot.docs);
    };
}
