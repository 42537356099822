import PageviewIcon from '@mui/icons-material/Pageview';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useContext } from 'react';
import { ExtensionsPageController } from '../ExtensionsPageController';

export default function BookNowSelectProducts() {
    const theme = useTheme();
    const controller = useContext(ExtensionsPageController);

    return (
        <Grid container>
            <Grid item xs={12} style={{ padding: '16px 24px' }}>
                <Grid item xs={12}>
                    <Typography
                        variant="body2"
                        style={{
                            color: theme.palette.text.shadowText,
                            lineHeight: 1.4
                        }}>
                        {`Let's get those online sales! Search and select a product below, add a bit
                        of style, and then follow the instructions to place the button on your site.`}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: '16px 0 9px' }}>
                    <Typography>Select Product</Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 16 }}>
                    <TextField
                        fullWidth
                        value={controller.selectedProduct.data.name || ''}
                        variant="outlined"
                        placeholder="Select Product"
                        onClick={() => controller.isSearchFormOpen.set(true)}
                        name="productName"
                        InputProps={{
                            inputProps: {
                                style: { cursor: 'pointer' }
                            },
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                    <PageviewIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
