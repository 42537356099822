import { Firestore } from 'firebase/firestore';
import { Collection, Extras } from 'firebase_api';
import { CrudRepository } from '../CrudRepository';

export class ExtrasRepository extends CrudRepository<Extras> {
    constructor(
        db: Firestore,
        readonly rootCollection: Collection.COMPANIES | Collection.BOOKINGS
    ) {
        super(db, Collection.EXTRAS, rootCollection);
    }
}
