import { BookingStatus, StatusTypes } from 'firebase_api';
import { set } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import AmexCardImg from '../../assets/images/americanExpress.png';
import MastercardImg from '../../assets/images/mastercard.svg';
import NewCardImg from '../../assets/images/newcard.svg';
import VisaCardImg from '../../assets/images/visa.svg';

export const prevStep = (event: any, setter: Dispatch<SetStateAction<number>>) => {
    event.preventDefault();
    setter((prev) => prev - 1);
};

export const nextStep = (event: any, setter: Dispatch<SetStateAction<number>>) => {
    event.preventDefault();
    setter((prev) => prev + 1);
};

export const saveTextArray = <T extends object>(
    arr: string[],
    key: string,
    setter: Dispatch<SetStateAction<T>>
) => {
    setter((prev) => ({
        ...prev,
        [key]: arr
    }));
};

export const setNestedField = <T extends object>(
    event: any,
    setter: Dispatch<SetStateAction<T>>
) => {
    const value = event.target.type === 'number' ? Number(event.target.value) : event.target.value;
    const name = event.target.name;
    setter((prev) => {
        const tmp = { ...prev };
        return set(tmp, name, value);
    });
};

export const getLabelColorByStatus = (status?: BookingStatus) => {
    switch (status) {
        case BookingStatus.CONFIRMED:
        case BookingStatus.PROCESSING_PAYMENT:
        case BookingStatus.PROCESSING_REFUND:
            return StatusTypes.SUCCESS;
        case BookingStatus.CANCELLED:
        case BookingStatus.PAYMENT_FAILED:
            return StatusTypes.FAILED;
        case BookingStatus.NEW:
        case BookingStatus.DRAFT:
        case BookingStatus.PENDING_PAYMENT:
        case BookingStatus.PENDING_REFUND:
        case BookingStatus.PENDING_UPDATE:
            return StatusTypes.PENDING;
        default:
            return StatusTypes.PENDING;
    }
};

export const getCardIcon = (network?: string) => {
    switch (network) {
        case 'visa':
            return VisaCardImg;
        case 'mastercard':
            return MastercardImg;
        case 'amex':
            return AmexCardImg;
        default:
            return NewCardImg;
    }
};

const replaceABN = (x: string, index: number) =>
    x.substring(0, index) + ' ' + x.substring(index, x.length);

export const formatABN = (abn: string) =>
    replaceABN(replaceABN(replaceABN(abn.replace(/ /g, ''), 2), 6), 10);
