"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.amountUiStr = exports.amountUiFormat = exports.strToAmount = exports.currency = void 0;
const lodash_1 = require("lodash");
const AUD = {
    symbol: '$',
    code: 'aud',
    country: 'AU',
    multiplier: 100,
    fractions: 2,
    minPaymentAmount: 100,
    maxAmount: 1000000
};
exports.currency = AUD;
const strToAmount = (str) => Math.round(Number(str) * exports.currency.multiplier);
exports.strToAmount = strToAmount;
const amountUiFormat = (amount) => (0, lodash_1.ceil)((0, lodash_1.floor)((amount || 0) / exports.currency.multiplier, exports.currency.fractions), exports.currency.fractions);
exports.amountUiFormat = amountUiFormat;
const amountUiStr = (amount) => (0, lodash_1.ceil)((0, lodash_1.floor)((amount || 0) / exports.currency.multiplier, exports.currency.fractions), exports.currency.fractions).toFixed(exports.currency.fractions);
exports.amountUiStr = amountUiStr;
