import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { InjectorProvider } from './api/Injector';
import App from './App';
import { ThemeDataStoreProvider } from './libs/stores/ThemeStore';
import { GlobalControllerProvider } from './v2/components/wrappers/GlobalController';

ReactDOM.render(
    <InjectorProvider>
        <ThemeDataStoreProvider>
            <GlobalControllerProvider>
                <Router>
                    <App />
                </Router>
            </GlobalControllerProvider>
        </ThemeDataStoreProvider>
    </InjectorProvider>,
    document.getElementById('root')
);
