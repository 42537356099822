import React, { useState } from 'react';

export type StateSetterType<T> = (next: T | ((prev: T) => T)) => void;

export interface StateType<T> {
    readonly data: T;
    readonly set: StateSetterType<T>;
}

export function State<T>(
    defaultValue: T,
    isMounted: React.MutableRefObject<boolean>
): StateType<T> {
    const [data, setValue] = useState<T>(defaultValue);

    const set: StateSetterType<T> = (next: T | ((prev: T) => T)) => {
        if (isMounted.current) {
            setValue(next instanceof Function ? (prev) => next(prev) : next);
        }
    };

    return { data, set };
}
