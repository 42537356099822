import {
    arrayUnion,
    collection,
    deleteField,
    doc,
    endAt,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc,
    where,
    writeBatch
} from 'firebase/firestore';
import {
    Collection,
    Company,
    CompanyType,
    Customer,
    EmailType,
    setCreatedUpdatedAt,
    setUpdatedAt,
    User,
    UserSummary
} from 'firebase_api';
import { CrudRepository } from '../CrudRepository';
import { toEntities } from '../utils';

export const OperatorEmailTypes = {
    BOOKING_CREATED: EmailType.BOOKING_CREATED,
    BOOKING_UPDATED: EmailType.BOOKING_UPDATED,
    BOOKING_PENDING_UPDATE: EmailType.BOOKING_PENDING_UPDATE,
    BOOKING_CANCELLATION: EmailType.BOOKING_CANCELLATION,
    BOOKING_REFUND: EmailType.BOOKING_REFUND,
    PERMISSION_REQUEST: EmailType.PERMISSION_REQUEST
};

export const AgentEmailTypes = {
    BOOKING_CREATED: EmailType.BOOKING_CREATED,
    BOOKING_UPDATE_REJECTED: EmailType.BOOKING_UPDATE_REJECTED,
    PAYMENT_FAILED: EmailType.PAYMENT_FAILED,
    BOOKING_REFUND: EmailType.BOOKING_REFUND,
    PERMISSION_REQUEST: EmailType.PERMISSION_REQUEST
};

export class CompanyRepository extends CrudRepository<Company> {
    public createAndUpdateUser = async (user: User, company: Company): Promise<string> => {
        const batch = writeBatch(this.db);
        const companyDocRef = doc(collection(this.db, Collection.COMPANIES, company?.id || ''));
        const emailPreferences = Object.values(
            company.type === CompanyType.AGENT ? AgentEmailTypes : OperatorEmailTypes
        ).reduce((acc, type) => ({ ...acc, [type]: [user.email] }), {});
        batch.set(companyDocRef, setCreatedUpdatedAt({ ...company, emailPreferences }));

        const userDocRef = doc(this.db, Collection.USERS, user.id || '');
        batch.update(userDocRef, {
            companyIds: arrayUnion(companyDocRef.id)
        });

        await batch.commit();
        return companyDocRef.id;
    };

    public checkIfAlreadyExists = async (key: string, value: string): Promise<boolean> => {
        const queryResult = query(
            collection(this.db, Collection.COMPANIES),
            where(key, '==', value)
        );
        const snapshot = await getDocs(queryResult);
        return !snapshot.empty;
    };

    public getByTradingName = async (
        tradingName: string,
        companyType: CompanyType,
        currentLimit: number = 15
    ): Promise<Company[]> => {
        const searchableTradingName = tradingName.toLowerCase();
        const queryResult = query(
            collection(this.db, Collection.COMPANIES),
            where('type', '==', companyType),
            orderBy('searchableTradingName'),
            startAfter(searchableTradingName),
            endAt(searchableTradingName + '\uf8ff'),
            limit(currentLimit)
        );
        const snapshot = await getDocs(queryResult);
        return toEntities(snapshot.docs);
    };

    public updateCompanyUserRoles = async (companyId: string, userId: string, roles: string[]) => {
        const docRef = doc(this.db, `${Collection.COMPANIES}/${companyId}`);
        const key = `users.${userId}`;
        const value = roles.length === 0 ? deleteField() : roles;
        const updateData = setUpdatedAt({
            [key]: value
        });
        if (roles.length === 0) {
            updateData[`contacts.${userId}`] = deleteField();
        }
        await updateDoc(docRef, updateData);
    };

    public upsertCompanyCustomers = (userId: string, customer: Customer, companyId?: string) => {
        const companyDocRef = doc(
            this.db,
            `${Collection.COMPANIES}/${companyId}/${Collection.CUSTOMERS}/${userId}`
        );
        setDoc(companyDocRef, customer, { merge: true });
    };

    public getCompanyUsers = async (companyId: string) => {
        const queryResult = query(
            collection(this.db, Collection.USERS),
            where('companyIds', 'array-contains', companyId)
        );
        const snapshot = await getDocs(queryResult);
        const userList = toEntities<User>(snapshot.docs);
        const returnArray: UserSummary[] = userList.map((user) => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName
        }));
        return returnArray;
    };
}
