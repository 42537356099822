import { Document, Font, Image, Link, Page, Text, usePDF, View } from '@react-pdf/renderer';
import {
    amountUiStr,
    BookingGroup,
    BookingGroupItem,
    Company,
    StatusTypes,
    timeDateUIString
} from 'firebase_api';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import InterRegular from '../../../../../assets/fonts/Inter-Regular.ttf';
import InterBold from '../../../../../assets/fonts/Inter-SemiBold.ttf';
import flagIcon from '../../../../../assets/images/pdf/flag_icon.png';
import logo from '../../../../../assets/images/pdf/logo.png';
import timeIcon from '../../../../../assets/images/pdf/time_icon.png';
import LoadingScreen from '../../../../../components/common/LoadingScreen';
import { UI_DATE_FORMAT_WITH_HOURS } from '../../../../../components/constants';
import {
    bookingGroupItemRepository,
    bookingGroupRepository,
    productRepository
} from '../../../../../firebase/firebaseIndex';
import { getLabelColorByStatus } from '../../../../../libs/utils/formFunctions';
import { getCancellation, getCompanyName, uiString } from '../../../../../libs/utils/functions';
import SnackbarManager from '../../../../components/common/snackbar/SnackbarManager';
import IsMounted from '../../../../components/wrappers/IsMounted';
import { State } from '../../../../components/wrappers/State';
import { PATH_IDS } from '../../../../utils/mainMenu/routes';
import { styles } from './styles';

interface BookingPdfViewProps {
    readonly bookingGroup: BookingGroup;
    readonly bookingItem: BookingGroupItem;
    readonly company: Company;
    readonly productTerms: string[];
}

const BookingPdfView = ({
    bookingGroup,
    bookingItem,
    company,
    productTerms
}: BookingPdfViewProps) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View style={{ display: 'flex', flexDirection: 'row', width: '60%' }}>
                    <Image style={styles.logo} src={logo} />
                </View>
                <View style={{ width: '35%', marginTop: 10 }}>
                    <Text style={styles.headerTitle}>Booking Details</Text>
                    <Text style={styles.refNr}>Booking Ref No: {bookingItem.reference}</Text>
                </View>
            </View>
            <View style={styles.companyView}>
                <View style={{ flex: 5 }}>
                    <Text style={styles.companyText}>Agent Details</Text>
                    <Text style={styles.companyName}>
                        {company.accountName || company.tradingName}
                    </Text>
                </View>
                <View style={{ flex: 7 }}>
                    <Text>
                        <Text style={styles.companyText}> Phone:</Text>{' '}
                        {company.supportPhone || 'Not provided'}
                    </Text>
                    <Text style={{ paddingTop: 8 }}>
                        <Text style={styles.companyText}> Email:</Text> {company.supportEmail || ''}
                    </Text>
                </View>
            </View>
            <View style={styles.bookedOnView}>
                <Text style={styles.companyText}>Booking Confirmed On: </Text>
                <Text>{moment(bookingItem.createdAt).format(UI_DATE_FORMAT_WITH_HOURS)}</Text>
            </View>
            <View style={styles.pageLayout}>
                <View style={styles.productView}>
                    <View style={{ flex: 7 }}>
                        <Text style={styles.productName}>{bookingItem.product?.name}</Text>
                        <Text>{getCompanyName(bookingItem.operator || {})}</Text>
                    </View>
                    <Text
                        style={[
                            styles.defaultLabel,
                            getLabelColorByStatus(bookingItem.status) === StatusTypes.SUCCESS
                                ? styles.successLabel
                                : {},
                            getLabelColorByStatus(bookingItem.status) === StatusTypes.FAILED
                                ? styles.failedLabel
                                : {}
                        ]}>
                        {uiString(bookingItem.status)}
                    </Text>
                </View>
                <View>
                    <View style={[styles.productDetailsView, { alignItems: 'flex-start' }]}>
                        <View style={styles.iconDetails}>
                            <View style={{ width: 17 }}>
                                <Image src={timeIcon} />
                            </View>
                            <View style={{ flex: 3 }}>
                                <Text style={styles.detailsTitle}>
                                    {bookingItem.product?.availableAllDay
                                        ? 'Ticket Date'
                                        : 'Departure Time'}
                                </Text>
                            </View>
                        </View>
                        <View style={{ flex: 6, alignSelf: 'center' }}>
                            <Text>
                                {timeDateUIString(
                                    bookingItem.departure,
                                    false,
                                    bookingItem.product?.availableAllDay
                                )}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.productDetailsView, { alignItems: 'flex-start' }]}>
                        <View style={styles.iconDetails}>
                            <View style={{ width: 17 }}>
                                <Image src={flagIcon} style={{ width: 14 }} />
                            </View>
                            <View style={{ flex: 3 }}>
                                <Text style={styles.detailsTitle}>
                                    {bookingItem.product?.availableAllDay
                                        ? 'Check In'
                                        : 'Meeting Point'}
                                </Text>
                            </View>
                        </View>
                        {isEmpty(bookingItem.pickup) && isEmpty(bookingItem.product?.checkIn) && (
                            <View style={{ flex: 6, alignSelf: 'center' }}>
                                <Text>TBA</Text>
                            </View>
                        )}
                        {!isEmpty(bookingItem.pickup) && (
                            <View style={{ flex: 6, alignSelf: 'center' }}>
                                <Text>
                                    {moment(bookingItem.departure?.utc)
                                        .subtract(bookingItem.pickup?.minutesPrior, 'minutes')
                                        .format(UI_DATE_FORMAT_WITH_HOURS)}
                                </Text>
                                <Text style={{ paddingTop: 8 }}>
                                    {bookingItem.pickup?.address?.description}
                                </Text>
                                <Text style={{ paddingTop: 8 }}>
                                    {bookingItem.pickup?.comments}
                                </Text>
                            </View>
                        )}
                        {!isEmpty(bookingItem.product?.checkIn) && (
                            <View style={{ flex: 6, alignSelf: 'center' }}>
                                {!bookingItem.product?.availableAllDay && (
                                    <Text>
                                        {moment(bookingItem.departure?.utc)
                                            .subtract(
                                                bookingItem.product?.checkIn?.minutesPrior,
                                                'minutes'
                                            )
                                            .format(UI_DATE_FORMAT_WITH_HOURS)}
                                    </Text>
                                )}
                                <Text style={{ paddingTop: 8 }}>
                                    {bookingItem.product?.checkIn?.address?.description}
                                </Text>
                                <Text style={{ paddingTop: 8 }}>
                                    {bookingItem.product?.checkIn?.comments}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
                <View style={styles.separatorView}></View>
                <View style={styles.detailsView}>
                    <View style={styles.faresExtrasView}>
                        <Text style={styles.fareTitle}>Confirmed Fares</Text>
                        {bookingItem.fares?.map((fare) => (
                            <Text key={fare.label} style={styles.fareText}>
                                {fare.label}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text style={styles.fareTitle}>Quantity</Text>
                        {bookingItem.fares?.map((fare) => (
                            <Text key={fare.label} style={styles.fareText}>
                                {fare.quantity}
                            </Text>
                        ))}
                    </View>
                </View>
                {!isEmpty(bookingItem.extras) && (
                    <View style={[styles.detailsView, { marginTop: 10 }]}>
                        <View style={styles.faresExtrasView}>
                            <Text style={styles.fareTitle}>Booked Extras</Text>
                            {bookingItem.extras?.map((extra) => (
                                <Text key={extra.id} style={styles.fareText}>
                                    {extra.name}
                                </Text>
                            ))}
                        </View>
                        <View>
                            <Text style={styles.fareTitle}>Quantity</Text>
                            {bookingItem.extras?.map((extra) => (
                                <Text key={extra.id} style={styles.fareText}>
                                    {extra.quantity}
                                </Text>
                            ))}
                        </View>
                    </View>
                )}
                {(!isEmpty(bookingItem.notes?.customer) ||
                    !isEmpty(bookingItem.notes?.internal)) && (
                    <View style={{ backgroundColor: '#d9dde4', marginTop: 15 }}>
                        <Text style={styles.commentsTitle}>Booking Comments</Text>
                        <Text style={styles.commentsText}>{bookingItem.notes?.customer}</Text>
                    </View>
                )}
                <View style={{ marginTop: 20, fontSize: 10.5, lineHeight: 1.4 }}>
                    <Text style={{ fontFamily: 'InterBold', paddingBottom: 8, fontSize: 12 }}>
                        Booking Cancellations
                    </Text>
                    <Text>
                        {getCancellation(bookingGroup.agent!).isAlways
                            ? `Your booking includes a non-refundable administration fee of $${amountUiStr(
                                  bookingItem?.paymentExactValues?.agentGrossCommmsion
                              )}`
                            : `A booking adminstration fee of $${amountUiStr(
                                  bookingItem?.paymentExactValues?.agentGrossCommmsion
                              )} will only be refunded if your booking is cancelled before:`}
                        <br />
                    </Text>
                    <Text
                        style={{
                            paddingTop: 10,
                            fontSize: 10.5,
                            fontFamily: 'InterBold'
                        }}>
                        {!getCancellation(bookingGroup.agent!).isAlways && (
                            <>
                                <br />
                                <b>
                                    {timeDateUIString(
                                        {
                                            ...bookingItem.departure,
                                            utc: moment(bookingItem.departure?.utc)
                                                .subtract(
                                                    bookingGroup.agent?.cancellationReturnPolicies
                                                        ?.full_fare,
                                                    'minutes'
                                                )
                                                .toISOString()
                                        },
                                        true,
                                        bookingItem.product?.availableAllDay
                                    )}
                                </b>
                            </>
                        )}
                    </Text>
                    <View style={styles.separatorView}></View>
                    <Text style={{ paddingBottom: 10 }}>
                        The operator will allow you to make changes or cancel your booking until:
                    </Text>
                    <Text style={{ paddingBottom: 10, fontSize: 10.5, fontFamily: 'InterBold' }}>
                        {timeDateUIString(
                            {
                                ...bookingItem.departure,
                                utc: moment(bookingItem.departure?.utc)
                                    .subtract(
                                        bookingItem.operator?.cancellationReturnPolicies?.full_fare,
                                        'minutes'
                                    )
                                    .toISOString()
                            },
                            true,
                            bookingItem.product?.availableAllDay
                        )}
                    </Text>
                    <Text style={{ paddingBottom: 10 }}>
                        After this time, you will forfeit your fare if you cancel, alter, or do not
                        show up for your booking. In case you have any questions, you should speak
                        directly with your agent, or the operator of this product.
                    </Text>
                    {bookingGroup.agent?.termsAndConditionsURL && (
                        <>
                            <Text style={{ paddingBottom: 4 }}>
                                View all of your booking agent terms and conditions by visiting URL
                                below:
                            </Text>
                            <Link
                                style={{
                                    paddingBottom: 10,
                                    fontSize: 10.5,
                                    fontFamily: 'InterBold',
                                    textDecoration: 'underline'
                                }}
                                src={bookingGroup.agent?.termsAndConditionsURL || ''}>
                                {bookingGroup.agent?.termsAndConditionsURL}
                            </Link>
                        </>
                    )}
                    <Text style={{ paddingBottom: 4 }}>
                        View all of the terms and conditions for the tour operator, by visiting the
                        URL below:
                    </Text>
                    <Link
                        style={{
                            fontSize: 10.5,
                            fontFamily: 'InterBold',
                            textDecoration: 'underline'
                        }}
                        src={bookingItem.operator?.termsAndConditionsURL || ''}>
                        {bookingItem.operator?.termsAndConditionsURL}
                    </Link>
                </View>
                {productTerms.length > 0 && (
                    <>
                        <View style={[styles.separatorView, { margin: '10px 0 10px' }]}></View>
                        <View style={{ fontSize: 10 }}>
                            <Text style={{ paddingBottom: 8, fontFamily: 'InterBold' }}>
                                Terms and Conditions (Product Specific)
                            </Text>
                            {productTerms.map((terms, index) => (
                                <View key={terms} style={styles.detailsView}>
                                    <View style={{ width: '4%', fontFamily: 'InterBold' }}>
                                        <Text style={styles.termsText}>{index + 1}.</Text>
                                    </View>
                                    <View style={{ width: '94%' }}>
                                        <Text style={styles.termsText}>{terms}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </>
                )}
            </View>
        </Page>
    </Document>
);

Font.register({
    family: 'InterBold',
    src: InterBold
});

Font.register({
    family: 'Inter',
    src: InterRegular
});

export default function BookingPdf() {
    const isMounted = IsMounted();
    const { [PATH_IDS.GROUP_ID]: groupId, [PATH_IDS.BOOKING_ID]: bookingId } = useParams() as any;
    const isLoading = State(false, isMounted);
    const booking = State<BookingGroupItem>({}, isMounted);
    const companyDetails = State<Company>({}, isMounted);
    const productTerms = State<string[]>([], isMounted);
    const bookingG = State<BookingGroup>({}, isMounted);

    useEffect(() => {
        fetchAllBookingData();
    }, []);

    const fetchAllBookingData = async () => {
        try {
            isLoading.set(true);
            const bookingItem = await bookingGroupItemRepository.getById(bookingId, groupId);
            booking.set(bookingItem || {});

            const bookingGroup = await bookingGroupRepository.getById(bookingItem?.groupId || '');
            bookingG.set(bookingGroup || {});
            companyDetails.set(bookingGroup?.agent || {});
            const product = await productRepository.getById(
                bookingItem?.product?.id || '',
                bookingItem?.operator?.id || ''
            );
            productTerms.set(product?.specialTerms || []);
        } catch (error) {
            SnackbarManager.error(
                error,
                'BookingPdf - fetchAllBookingData',
                'Could not fetch booking pdf, please try again later.'
            );
        } finally {
            isLoading.set(false);
        }
    };

    return isLoading.data ? (
        <LoadingScreen />
    ) : (
        <BookingPdfRendering
            bookingGroup={bookingG.data}
            bookingItem={booking.data}
            company={companyDetails.data}
            productTerms={productTerms.data}
        />
    );
}

function BookingPdfRendering({
    bookingGroup,
    bookingItem,
    company,
    productTerms
}: BookingPdfViewProps) {
    const [instance] = usePDF({
        document: (
            <BookingPdfView
                bookingGroup={bookingGroup}
                bookingItem={bookingItem}
                company={company}
                productTerms={productTerms}
            />
        )
    });

    return instance.loading || instance.url === null ? (
        <LoadingScreen />
    ) : (
        <Route
            component={() => {
                window.location.href = instance.url || '';
                return null;
            }}
        />
    );
}
