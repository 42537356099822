import { Box, Container, CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { lazy, Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeType } from './assets/css/themes';
import LoadingScreen from './components/common/LoadingScreen';
import PrivateRoute from './components/common/PrivateRoute';
import { LOCAL_STORAGE_KEYS } from './components/constants';
import FareharborSetup from './components/home/FareharborSetup';
import { ThemeStore } from './libs/stores/ThemeStore';
import { checkExtension, getLocalStorage } from './libs/utils/functions';
import PopupContainer from './v2/components/common/popup/PopupContainer';
import SnackbarContainer from './v2/components/common/snackbar/SnacbarContainer';
import { GlobalController } from './v2/components/wrappers/GlobalController';
import BookingPdf from './v2/pages/common/pdfView/BookingPdf/BookingPdf';
import { PATH_IDS, routes, ROUTE_STATE } from './v2/utils/mainMenu/routes';

const LandingPage = lazy(() => import('./components/home/landingPage/LandingPage'));
const SupportHome = lazy(() => import('./components/home/support/SupportHome'));
const SignUpPage = lazy(() => import('./v2/pages/common/signIn/SignInForm'));
const NoPermissionPage = lazy(() => import('./v2/pages/staticPages/NoPermissionPage'));
const PasswordlessLoginPage = lazy(
    () => import('./v2/pages/common/customer/PasswordlessLoginPage')
);
const EmailVerification = lazy(() => import('./v2/pages/common/signIn/EmailVerification'));
const SuccessfulPage = lazy(() => import('./v2/pages/staticPages/SuccessfulPage'));
const StripeConnectedAccountLandingPage = lazy(
    () => import('./components/common/stripe/StripeConnectedAccountLandingPage')
);
const NewCompanyPage = lazy(() => import('./v2/pages/common/newCompany/NewCompanyPage'));
const OpenStrip = lazy(() => import('./components/common/openStripe/OpenStripe'));
const NotFoundPage = lazy(() => import('./v2/pages/staticPages/NotFoundPage'));
const Customer = lazy(() => import('./v2/pages/common/customer/'));
const CustomerLoginPage = lazy(() => import('./v2/pages/common/customer/login/LoginForm'));
const NewPaymentPage = lazy(() => import('./v2/pages/agent/bookings/paymentPage/PaymentPage'));

const Operator = lazy(() => import('./v2/pages/operator'));
const Agent = lazy(() => import('./v2/pages/agent'));
const ExtensionsAuthentication = lazy(
    () => import('./v2/pages/agent/extensions/ExtensionsAuthentication')
);
const ProductCatalogIframe = lazy(
    () => import('./v2/pages/agent/extensions/productCatalog/ProductCatalogIframe')
);

const appStyle = makeStyles(() => ({
    mainConatiner: {
        margin: 0,
        padding: 0,
        maxWidth: '100%',
        height: '100%',
        width: 'unset !important'
    }
}));

function AppRoutes() {
    const themeStore = useContext(ThemeStore);
    const globalController = useContext(GlobalController);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const location = useLocation();
    const isExtension = checkExtension();

    useEffect(() => {
        const storedThemeType =
            (getLocalStorage()?.getItem(LOCAL_STORAGE_KEYS.theme) as ThemeType) || ThemeType.LIGHT;
        if (storedThemeType === null) {
            themeStore.toggleTheme(
                prefersDarkMode ? ThemeType.DARK : ThemeType.LIGHT,
                prefersDarkMode
            );
        }
        // eslint-disable-next-line
    }, [prefersDarkMode]);

    if (!globalController.initialDataHasLoaded && location.pathname !== routes.home) {
        return <LoadingScreen title={isExtension ? 'Please wait...' : 'Initialising'} />;
    }

    return (
        <Suspense fallback={null}>
            <Switch>
                <Route
                    path={routes.agent.extensionCatalog.display(
                        ':' + PATH_IDS.AGENT_ID,
                        ':' + PATH_IDS.CATALOG_ID,
                        ':' + ROUTE_STATE.CATALOG_PADDING
                    )}
                    exact
                    component={ProductCatalogIframe}
                />
                <>
                    <Box
                        sx={{
                            background: (theme) => theme.palette.background.default,
                            height: isExtension ? 'unset' : 'inherit'
                        }}>
                        <Switch>
                            <Route
                                path={routes.bookingPdf(
                                    ':' + PATH_IDS.GROUP_ID,
                                    ':' + PATH_IDS.BOOKING_ID
                                )}
                                exact
                                component={BookingPdf}
                            />
                            <Route
                                path={routes.extensions.button.authentication(
                                    ':' + PATH_IDS.AGENT_ID,
                                    ':' + PATH_IDS.PRODUCT_ID
                                )}
                                exact
                                component={ExtensionsAuthentication}
                            />
                            <Route path={routes.home} exact component={LandingPage} />
                            <Route path={routes.fareharborLink} exact component={FareharborSetup} />
                            <Route path={routes.support.home} component={SupportHome} />
                            <Route path={routes.login} component={SignUpPage} />
                            <Route path={routes.signup} component={SignUpPage} />
                            <Route path={routes.forgotPassword} component={SignUpPage} />
                            <Route
                                path={routes.successfulPage.home}
                                exact
                                component={SuccessfulPage}
                            />
                            <Route path={routes.customer.login} component={CustomerLoginPage} />
                            <Route path={routes.passwordless} component={PasswordlessLoginPage} />
                            <PrivateRoute
                                path={routes.customer.payment(':' + PATH_IDS.BOOKING_ID)}
                                component={NewPaymentPage}
                                needsCompleteCompany={false}
                            />
                            <PrivateRoute
                                path={routes.customer.bookings().home}
                                component={Customer}
                                needsCompleteCompany={false}
                            />
                            <PrivateRoute
                                path={routes.noPermission}
                                isCommonRoute
                                component={NoPermissionPage}
                                needsCompleteCompany={false}
                            />
                            <PrivateRoute
                                path={routes.emailVerification}
                                component={EmailVerification}
                                needsCompleteCompany={false}
                                isCommonRoute
                            />
                            <PrivateRoute
                                path={routes.operator.overview}
                                component={Operator}
                                needsCompleteCompany={false}
                            />
                            <PrivateRoute
                                path={routes.agent.overview}
                                component={Agent}
                                needsCompleteCompany={false}
                            />
                            <PrivateRoute
                                path={routes.stripeConnectedAccount}
                                needsCompleteCompany={false}
                                component={StripeConnectedAccountLandingPage}
                                isCommonRoute
                            />
                            <PrivateRoute
                                path={routes.newCompany}
                                needsCompleteCompany={false}
                                isCommonRoute
                                component={NewCompanyPage}
                            />
                            <PrivateRoute
                                path={routes.openSripe}
                                isCommonRoute
                                needsCompleteCompany={true}
                                component={OpenStrip}
                            />
                            <Route component={NotFoundPage} />
                        </Switch>
                    </Box>
                </>
            </Switch>
        </Suspense>
    );
}

function MainContainer() {
    const classes = appStyle();

    return (
        <Container className={classes.mainConatiner} maxWidth={false}>
            <PopupContainer />
            <SnackbarContainer />
            <CssBaseline />
            <Router>
                <AppRoutes />
            </Router>
        </Container>
    );
}

export default function App() {
    const themeStore = useContext(ThemeStore);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeStore.theme}>
                <MainContainer />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
