"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTwoNumberPercentageDiff = exports.getFlooredPercent = exports.getPercent = exports.nearestInt = void 0;
const lodash_1 = require("lodash");
const nearestInt = (num) => {
    if (!num) {
        return 0;
    }
    const intValue = (0, lodash_1.toInteger)(num);
    const realValue = num - intValue;
    return intValue + (realValue >= 0.5 ? 1 : 0);
};
exports.nearestInt = nearestInt;
const getPercent = (amount, percentage) => amount && percentage ? amount * (percentage / 100) : 0;
exports.getPercent = getPercent;
const getFlooredPercent = (amount, percentage) => (amount && percentage ? Math.floor(amount * (percentage / 100)) : 0);
exports.getFlooredPercent = getFlooredPercent;
const getTwoNumberPercentageDiff = (amount1, amount2) => (amount1 && amount2 ? (amount2 * 100) / amount1 : 0);
exports.getTwoNumberPercentageDiff = getTwoNumberPercentageDiff;
