import {
    collection,
    doc,
    Firestore,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    where,
    writeBatch
} from 'firebase/firestore';
import { Collection, PriceOption, Product, ProductStatus, setCreatedUpdatedAt } from 'firebase_api';
import { last } from 'lodash';
import { SEARCH_DEFAULT_LIMIT } from '../../../v2/pages/agent/extensions/ExtensionsPageController';
import { CrudRepository } from '../CrudRepository';
import { DocumentSnapshotAny, GetAllResult, toEntities, toEntity } from '../utils';

export class ProductRepository extends CrudRepository<Product> {
    constructor(private readonly batchLimit: number, db: Firestore) {
        super(db, Collection.PRODUCTS);
    }

    public createWithPriceOptions = async (
        product: Product,
        priceOptions: PriceOption[]
    ): Promise<string> => {
        const batch = writeBatch(this.db);
        const docRef = doc(this.db, this.collectionName);
        const sanitizedProduct: Product = setCreatedUpdatedAt(product);
        batch.set(docRef, sanitizedProduct);

        priceOptions.forEach((option) => {
            const optionDocRef = doc(
                this.db,
                `${Collection.PRODUCTS}/${docRef.id}/${Collection.PRICE_OPTIONS}`
            );
            const sanitizedOption = setCreatedUpdatedAt(option);
            batch.set(optionDocRef, sanitizedOption);
        });
        await batch.commit();
        return docRef.id;
    };

    public getAllByStatusAndCompanyId = async (
        companyId: string,
        status?: ProductStatus,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<Product>> => {
        let queryResult = query(
            collection(this.db, Collection.PRODUCTS),
            where('company.id', '==', companyId),
            limit(this.batchLimit),
            orderBy('updatedAt', 'desc')
        );
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        if (status) {
            queryResult = query(queryResult, where('status', '==', status));
        }
        const snapshot = await getDocs(queryResult);
        return {
            entities: toEntities<Product>(snapshot.docs),
            lastDoc: last(snapshot.docs)
        };
    };

    public getAllByFilters = async (
        state?: string,
        region?: string,
        lastVisible?: DocumentSnapshotAny
    ): Promise<GetAllResult<Product>> => {
        let queryResult = query(
            collection(this.db, Collection.PRODUCTS),
            where('status', '==', 'active'),
            limit(this.batchLimit)
        );
        queryResult = query(queryResult, orderBy('createdAt', 'desc'));
        if (state !== undefined) {
            queryResult = query(queryResult, where('location.state', '==', state));
        }
        if (region !== undefined) {
            queryResult = query(queryResult, where('location.region', '==', region));
        }
        if (lastVisible) {
            queryResult = query(queryResult, startAfter(lastVisible));
        }

        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<Product>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getAllProduct = async (
        region?: string,
        lastVisible?: DocumentSnapshotAny,
        withLimit?: boolean
    ): Promise<GetAllResult<Product>> => {
        let queryResult = withLimit
            ? query(
                  collection(this.db, Collection.PRODUCTS),
                  where('status', '==', 'active'),
                  limit(this.batchLimit)
              )
            : query(collection(this.db, Collection.PRODUCTS), where('status', '==', 'active'));
        queryResult = query(queryResult, orderBy('createdAt', 'desc'));

        if (region !== undefined) {
            queryResult = query(queryResult, where('location.region', '==', region));
        }
        if (lastVisible) {
            queryResult = query(queryResult, startAfter(lastVisible));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<Product>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getProductsWithLimit = async (
        lastVisible?: DocumentSnapshotAny,
        currentLimit: number = SEARCH_DEFAULT_LIMIT
    ): Promise<GetAllResult<Product>> => {
        let queryResult = query(collection(this.db, Collection.PRODUCTS), limit(currentLimit));
        queryResult = query(queryResult, orderBy('createdAt', 'desc'));
        if (lastVisible) {
            queryResult = query(queryResult, startAfter(lastVisible));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<Product>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public checkProductsExist = async (
        companyId: string,
        currentLimit: number = 1
    ): Promise<boolean> => {
        const queryResult = await query(
            collection(this.db, Collection.PRODUCTS),
            where('company.id', '==', companyId),
            limit(currentLimit)
        );
        return !(await getDocs(queryResult)).empty;
    };

    public getByProductCodeAndProvider = async (
        productCode: string,
        companyId: string,
        provider?: string,
        currentLimit: number = 1
    ): Promise<Product | undefined> => {
        let queryResult = query(
            collection(this.db, Collection.PRODUCTS),
            where('company.id', '==', companyId),
            where('productCode', '==', productCode),
            limit(currentLimit)
        );

        if (provider) {
            queryResult = query(queryResult, where('provider', '==', provider));
        }
        const snapshot = await getDocs(queryResult);
        return snapshot.docs.length > 0 ? toEntity<Product>(snapshot.docs[0]) : undefined;
    };
}
