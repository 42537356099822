"use strict";
/* eslint-disable camelcase */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusTypes = exports.ApplyExtras = exports.Collection = void 0;
var Collection;
(function (Collection) {
    Collection["USERS"] = "users";
    Collection["PRODUCTS"] = "products";
    Collection["COMPANIES"] = "companies";
    Collection["PICK_UP_LIST"] = "pick_up_lists";
    Collection["BOOKINGS"] = "bookings";
    Collection["BOOKING_GROUPS"] = "booking_groups";
    Collection["TRANSFERS"] = "transfers";
    Collection["PRICE_OPTIONS"] = "price_options";
    Collection["PARTICIPANTS"] = "participants";
    Collection["EXTRAS"] = "extras";
    Collection["ERRORS"] = "errors";
    Collection["PRIVATE"] = "private";
    Collection["CUSTOMERS"] = "customers";
    Collection["CHECKOUT_SESSIONS"] = "checkout_sessions";
    Collection["PERMISSION_REQUEST"] = "permission_request";
    Collection["SUPPORT_ARTICLES"] = "support_articles";
    Collection["NOTES_TASKS"] = "notes_tasks";
})(Collection = exports.Collection || (exports.Collection = {}));
var ApplyExtras;
(function (ApplyExtras) {
    ApplyExtras["PER_PERSON"] = "per_person";
    ApplyExtras["PER_BOOKING"] = "per_booking";
})(ApplyExtras = exports.ApplyExtras || (exports.ApplyExtras = {}));
var StatusTypes;
(function (StatusTypes) {
    StatusTypes[StatusTypes["SUCCESS"] = 0] = "SUCCESS";
    StatusTypes[StatusTypes["FAILED"] = 1] = "FAILED";
    StatusTypes[StatusTypes["PENDING"] = 2] = "PENDING";
})(StatusTypes = exports.StatusTypes || (exports.StatusTypes = {}));
