"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductProvider = exports.ProductStatus = void 0;
var ProductStatus;
(function (ProductStatus) {
    ProductStatus["ACTIVE"] = "active";
    ProductStatus["DISABLED"] = "disabled";
})(ProductStatus = exports.ProductStatus || (exports.ProductStatus = {}));
var ProductProvider;
(function (ProductProvider) {
    ProductProvider["REZDY"] = "rezdy";
    ProductProvider["FAREHARBOR"] = "fareharbor";
    ProductProvider["RESPAX"] = "resPax";
})(ProductProvider = exports.ProductProvider || (exports.ProductProvider = {}));
