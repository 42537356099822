import {
    Box,
    Card,
    FormControl,
    FormHelperText,
    Grid,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FareharborRequestEmail } from 'firebase_api';
import { isEmpty, toNumber } from 'lodash';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import fareHarborIcon from '../../assets//images/product/apiLogos/fareharbor.png';
import logoLargePNG from '../../assets/logos/fullLogoColor.png';
import logoLarge from '../../assets/logos/fullLogoColor.webp';
import logoSmallPNG from '../../assets/logos/logoColor.png';
import logoSmall from '../../assets/logos/logoColor.webp';
import { emailFunctions } from '../../firebase/firebaseIndex';
import { setOrDelete, toMenuItem } from '../../libs/utils/functions';
import LoadingButton from '../../v2/components/common/LoadingButton';
import SnackbarManager from '../../v2/components/common/snackbar/SnackbarManager';
import IsMounted from '../../v2/components/wrappers/IsMounted';
import { State } from '../../v2/components/wrappers/State';
import ImgWithFallback from '../../v2/pages/common/ImgWithFallback';
import Label from '../../v2/pages/operator/products/upsert/common/Label';
import { routes } from '../../v2/utils/mainMenu/routes';
import { EMAIL_REGEX } from '../constants';

interface Errors {
    readonly fullName?: boolean;
    readonly operatorEmailMissing?: boolean;
    readonly operatorEmailInvalid?: boolean;
    readonly businessName?: boolean;
    readonly commission?: boolean;
}

export default function FareharborSetup() {
    const isMounted = IsMounted();
    const theme = useTheme();
    const emailData = State<FareharborRequestEmail>(
        {
            businessName: '',
            fullName: '',
            operatorEmail: '',
            commission: null
        },
        isMounted
    );
    const errors = State<Errors>({}, isMounted);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const commOptions = [10, 15, 20, 25, 30];

    const textStyle = {
        textAlign: 'center',
        mb: 2,
        color: theme.palette.text.secondary
    };

    const changeField = (event: any, isBlur?: boolean) => {
        const value =
            event.target.name === 'commission'
                ? toNumber(event.target.value)
                : isBlur
                ? event.target.value.trim()
                : event.target.value;
        emailData.set((prev) => ({
            ...prev,
            [event.target.name]: value
        }));
    };

    const sendLinkRequest = async () => {
        try {
            setIsLoading(true);
            await emailFunctions.sendFareharborRequestEmail(emailData.data);
            SnackbarManager.success('Email sent succesfully');
        } catch (error) {
            SnackbarManager.error(error, 'Footer - sendFareharborEmail', 'Error Sending Email');
        } finally {
            setIsLoading(false);
            setSuccess(true);
        }
    };

    const isItemValid = () => {
        let error = {};
        error = setOrDelete(error, 'operatorEmailMissing', isEmpty(emailData.data.operatorEmail));
        error = setOrDelete(
            error,
            'operatorEmailInvalid',
            !EMAIL_REGEX.test(emailData.data.operatorEmail)
        );
        error = setOrDelete(error, 'fullName', isEmpty(emailData.data.fullName));
        error = setOrDelete(error, 'businessName', isEmpty(emailData.data.businessName));
        error = setOrDelete(
            error,
            'commission',
            commOptions.indexOf(emailData.data.commission || 0) === -1
        );
        errors.set(error);
        return isEmpty(error);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh'
            }}>
            <Box sx={{ pt: 3 }}>
                <Link to={routes.home}>
                    <ImgWithFallback
                        src={isSmallScreen ? logoSmall : logoLarge}
                        height={40}
                        fallbackSrc={isSmallScreen ? logoSmallPNG : logoLargePNG}
                    />
                </Link>
            </Box>
            {success ? (
                <Box
                    sx={{
                        display: 'flex',
                        maxWidth: '560px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 2
                    }}>
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: 'center',
                            mb: 2,
                            lineHeight: 1.4,
                            fontSize: { xs: '1.4rem', sm: '1.6rem' }
                        }}>
                        Request Complete
                    </Typography>
                    <Typography sx={{ ...textStyle }}>
                        Fareharbor will send you a confirmation email once Parseports has been added
                        as an affiliate.
                    </Typography>
                    <Typography sx={{ ...textStyle }}>
                        As soon as that&apos;s done, you can start linking your products via API,
                        and Agents on Parseports will instantly be able to start booking.
                    </Typography>
                    <Typography sx={{ ...textStyle, mb: 0 }}>
                        Thank you, and happy selling 😀
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        maxWidth: '560px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 2
                    }}>
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: 'center',
                            mb: 2,
                            lineHeight: 1.4,
                            fontSize: { xs: '1.4rem', sm: '1.6rem' }
                        }}>
                        FareHarbor Link
                    </Typography>
                    <Typography sx={{ ...textStyle }}>
                        In order to link your products with Parseports, FareHarbor will need to
                        affiliate your business with the Parseports platform.
                    </Typography>
                    <Typography sx={{ ...textStyle, mb: 3 }}>
                        Please fill out your details below, and this will notify FareHarbor that
                        you&apos;d like to receive agent bookings through Parseports in the future.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                        <Card
                            sx={{
                                background: '#0064C4',
                                px: 3,
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                            <img src={fareHarborIcon} width={70} />
                        </Card>
                    </Box>
                    <Grid container sx={{ justifyContent: 'center', gap: 2 }}>
                        <Grid item xs={12} sx={{ maxWidth: '480px' }}>
                            <Label>Trading Name</Label>
                            <TextField
                                name="businessName"
                                required
                                onChange={changeField}
                                onBlur={(event) => changeField(event, true)}
                                inputProps={{
                                    maxLength: 100
                                }}
                                value={emailData.data.businessName}
                                fullWidth
                                placeholder="Parseports Travel"
                                disabled={isLoading}
                                helperText={errors.data.businessName && 'Enter your busines name'}
                                error={errors.data.businessName}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ maxWidth: '500px' }}>
                            <Label>Your Name</Label>
                            <TextField
                                name="fullName"
                                required
                                onChange={changeField}
                                onBlur={(event) => changeField(event, true)}
                                inputProps={{
                                    maxLength: 100
                                }}
                                value={emailData.data.fullName}
                                fullWidth
                                placeholder="John Smith"
                                disabled={isLoading}
                                helperText={errors.data.fullName && 'Please enter your name'}
                                error={errors.data.fullName}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ maxWidth: '500px' }}>
                            <Label>Email</Label>
                            <TextField
                                required
                                fullWidth
                                name="operatorEmail"
                                value={emailData.data.operatorEmail}
                                onChange={changeField}
                                onBlur={(event) => changeField(event, true)}
                                placeholder="email@domain.com"
                                error={
                                    errors.data.operatorEmailMissing ||
                                    errors.data.operatorEmailInvalid
                                }
                                helperText={
                                    errors.data.operatorEmailMissing
                                        ? 'Enter your email address'
                                        : errors.data.operatorEmailInvalid &&
                                          'Email address invalid'
                                }
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ maxWidth: '500px' }}>
                            <Label>Default Commission</Label>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={errors.data.commission}>
                                <Select
                                    value={emailData.data.commission || 'default'}
                                    native={isMobile}
                                    name="commission"
                                    MenuProps={{
                                        sx: {
                                            '& .MuiMenu-list': {
                                                gap: 1
                                            },
                                            '& .MuiMenuItem-root': {
                                                borderBottom: 'none'
                                            }
                                        },
                                        BackdropProps: { style: { opacity: 0 } }
                                    }}
                                    disabled={isLoading}
                                    onChange={(event) => changeField(event)}>
                                    {toMenuItem(
                                        isMobile,
                                        'default',
                                        'default',
                                        'Select Rate...',
                                        isLoading
                                    )}
                                    {commOptions.map((comm) =>
                                        toMenuItem(isMobile, comm, comm, `${comm}%`, isLoading)
                                    )}
                                </Select>
                                <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
                                    {errors.data.commission && 'Select default commission'}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                maxWidth: '500px',
                                display: 'flex',
                                justifyContent: { xs: 'center', sm: 'flex-end' }
                            }}>
                            <LoadingButton
                                sx={{
                                    width: { xs: '100%', sm: 130 },
                                    mb: 3,
                                    height: 'fit-content'
                                }}
                                isLoading={isLoading}
                                variant="contained"
                                onClick={() => isItemValid() && sendLinkRequest()}
                                disableElevation={true}>
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
