import {
    deleteObject,
    getDownloadURL,
    ref,
    StorageReference,
    uploadBytesResumable,
    UploadTask
} from 'firebase/storage';
import {
    Collection,
    getResizedImageName,
    Image,
    ImageSizeProps,
    ImagesUrlsMap
} from 'firebase_api';

interface GetImgages {
    readonly images: { [imageName: string]: any };
    readonly errors: { [imageName: string]: Error };
}

export abstract class CrudStorage {
    constructor(
        protected readonly storageRef: StorageReference,
        protected readonly rootPath: Collection
    ) {}

    public create = (image: Image, rootId: string): UploadTask =>
        uploadBytesResumable(
            ref(this.storageRef, `${this.rootPath}/${rootId}/${image.id}`),
            image.data
        );

    public createMany = (images: Image[], rootId: string): { [imageName: string]: UploadTask } => {
        const uploadTasks: { [id: string]: UploadTask } = {};
        images.forEach((image) => {
            uploadTasks[image.id] = this.create(image, rootId);
        });
        return uploadTasks;
    };

    public delete = async (id: string, rootId: string): Promise<void> =>
        await deleteObject(ref(this.storageRef, `${this.rootPath}/${rootId}/${id}`));

    public get = async (id: string, rootId: string): Promise<string | undefined> => {
        try {
            return await getDownloadURL(ref(this.storageRef, `${this.rootPath}/${rootId}/${id}`));
        } catch (error: any) {
            const errorCode = JSON.parse(error?.serverResponse_)?.error?.code;
            if (errorCode === 404) {
                return undefined;
            }
            throw error;
        }
    };

    public getMany = async (ids: string[], rootId: string): Promise<GetImgages> => {
        const images: { [id: string]: string } = {};
        const errors: { [id: string]: Error } = {};
        const promises = ids.map(async (id) => {
            try {
                const downloadUrl = await this.get(id, rootId);
                if (downloadUrl) {
                    images[id] = downloadUrl;
                }
            } catch (error) {
                errors[id] = error as any;
            }
        });
        await Promise.all(promises);
        return { images, errors };
    };

    public removeRemoteImagesWithUrl = async (
        oldImages: ImagesUrlsMap,
        currentImages: ImagesUrlsMap,
        mainObjId: string,
        imgSizes: ImageSizeProps[]
    ) => {
        const removedIds = Object.keys(oldImages).filter(
            (key) => !Object.keys(currentImages)?.includes(key)
        );
        const imgsToRemove = removedIds.flatMap((id: string) =>
            imgSizes.map((imageProp) =>
                getResizedImageName(id, imageProp.size, imageProp.isBlurred)
            )
        );
        const promises = imgsToRemove.map((imgId: string) => this.delete(imgId, mainObjId));
        await Promise.all(
            promises.concat(removedIds.map((originalId) => this.delete(originalId, mainObjId)))
        );
    };
}
