import {
    collection,
    deleteDoc,
    doc,
    Firestore,
    getDoc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
    WriteBatch,
    writeBatch
} from 'firebase/firestore';
import { Collection, setCreatedUpdatedAt, setUpdatedAt } from 'firebase_api';
import { toEntities, toEntity } from './utils';

export abstract class CrudRepository<T extends { id?: string }> {
    constructor(
        protected readonly db: Firestore,
        protected readonly collectionName: Collection,
        protected readonly rootCollection?: Collection
    ) {}

    public getRandomId = (rootId?: string) =>
        doc(collection(this.db, `${this.getRoot(rootId)}${this.collectionName}`)).id;

    protected getRoot = (rootId?: string): string =>
        this.rootCollection && rootId ? `${this.rootCollection}/${rootId}/` : '';

    public create = async (
        entity: T,
        rootId?: string,
        batch?: WriteBatch,
        merge?: boolean
    ): Promise<T> => {
        const sanitized = setCreatedUpdatedAt(entity);
        const path = `${this.getRoot(rootId)}${this.collectionName}`;
        const docRef = doc(this.db, path, entity.id || this.getRandomId(rootId));
        batch
            ? batch.set(docRef, sanitized, { merge })
            : await setDoc(docRef, sanitized, { merge });
        return { ...sanitized, id: docRef.id };
    };

    public createMany = async (
        entities: T[],
        rootId?: string,
        batch?: WriteBatch
    ): Promise<T[]> => {
        const localBatch = batch || writeBatch(this.db);
        const finalEntities = entities.map((entity) => {
            const path = `${this.getRoot(rootId)}${this.collectionName}`;
            const docRef = doc(this.db, path, entity.id || this.getRandomId(rootId));
            const sanitized = setCreatedUpdatedAt(entity);
            localBatch.set(docRef, sanitized);
            return { ...sanitized, id: docRef.id };
        });
        if (batch === undefined) {
            await localBatch.commit();
        }
        return finalEntities;
    };

    public update = async (entity: T, rootId?: string, batch?: WriteBatch): Promise<T> => {
        const sanitized = setUpdatedAt(entity);
        const docRef = doc(this.db, `${this.getRoot(rootId)}${this.collectionName}/${entity.id}`);
        batch ? batch.update(docRef, sanitized) : await updateDoc(docRef, sanitized);
        return { ...entity, ...sanitized };
    };

    public updateMany = async (
        entities: T[],
        rootId?: string,
        batch?: WriteBatch
    ): Promise<void> => {
        const localBatch = batch || writeBatch(this.db);
        entities.forEach((entity) => {
            const path = `${this.getRoot(rootId)}${this.collectionName}`;
            const docRef = doc(this.db, path, entity.id || this.getRandomId(rootId));
            const sanitized = setUpdatedAt(entity);
            localBatch.update(docRef, sanitized);
        });
        if (batch === undefined) {
            await localBatch.commit();
        }
    };

    public delete = async (id: string, rootId?: string, batch?: WriteBatch): Promise<void> => {
        const docRef = doc(this.db, `${this.getRoot(rootId)}${this.collectionName}/${id}`);
        if (batch) {
            batch.delete(docRef);
        } else {
            await deleteDoc(docRef);
        }
    };

    public deleteByIds = async (
        ids: string[],
        rootId?: string,
        batch?: WriteBatch
    ): Promise<void> => {
        await Promise.all(ids.map((id) => this.delete(id, rootId, batch)));
    };

    public getById = async (id: string, rootId?: string): Promise<T | undefined> => {
        const docRef = doc(this.db, `${this.getRoot(rootId)}${this.collectionName}/${id}`);
        const currentDoc = await getDoc(docRef);
        return currentDoc.exists() ? toEntity(currentDoc) : undefined;
    };

    public getAll = async (rootId?: string): Promise<T[]> => {
        const entities = await getDocs(
            query(
                collection(this.db, `${this.getRoot(rootId)}${this.collectionName}`),
                orderBy('createdAt', 'desc')
            )
        );
        return toEntities<T>(entities.docs);
    };

    public getManyByIds = async (ids: string[], rootId?: string): Promise<T[]> => {
        if (ids.length === 0) return [];
        const entities = await Promise.all(ids.map((id) => this.getById(id, rootId)));
        return entities.filter((entity) => entity !== undefined) as T[];
    };

    public getByIdLive = (
        id: string,
        onSuccess: (entity: T | undefined) => void,
        onError: (error: any) => void,
        rootId?: string
    ): (() => void) =>
        onSnapshot(
            doc(this.db, `${this.getRoot(rootId)}${this.collectionName}/${id}`),
            (snapshot) =>
                onSuccess(
                    snapshot.exists() && snapshot.data()?.updatedAt ? toEntity(snapshot) : undefined
                ),
            (error) => onError && onError(error)
        );
}
