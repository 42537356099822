import { CompanyStatus, CompanyType, Role } from 'firebase_api';
import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { firebaseAuth } from '../../firebase/firebaseIndex';
import { getOverallCompanySatus } from '../../libs/utils/companyStatus';
import {
    checkExtension,
    containsAnyRole,
    notExtensionsPath,
    userHasAnyRole
} from '../../libs/utils/functions';
import SnackbarManager from '../../v2/components/common/snackbar/SnackbarManager';
import { GlobalController } from '../../v2/components/wrappers/GlobalController';
import { routes } from '../../v2/utils/mainMenu/routes';

interface PrivateRouteProps {
    readonly component: React.LazyExoticComponent<() => JSX.Element>;
    readonly exact?: Boolean;
    readonly path: string;
    readonly roles?: Role[];
    readonly needsCompleteCompany: boolean;
    readonly isCommonRoute?: boolean;
    readonly specialRoles?: Role[];
}

export default function PrivateRoute({
    component: Component,
    path,
    roles,
    needsCompleteCompany,
    isCommonRoute,
    specialRoles
}: PrivateRouteProps) {
    const globalController = useContext(GlobalController);
    const isCustomerPath = location.pathname.startsWith('/customer');
    const isExtension = checkExtension();

    const getPathAndState = () => {
        if (!firebaseAuth.currentUser) {
            return {
                pathname: isCustomerPath ? routes.customer.login : routes.login,
                state: { targetUrl: path }
            };
        }

        if (!firebaseAuth.currentUser?.emailVerified && !isExtension) {
            return { pathname: routes.signup, state: { targetUrl: path } };
        }
        if (
            isExtension &&
            notExtensionsPath(routes.agent.products()) &&
            notExtensionsPath(routes.agent.booking('')) &&
            notExtensionsPath(routes.agent.payment('')) &&
            notExtensionsPath(routes.agent.extensionCatalog.home) &&
            notExtensionsPath(routes.customer.home)
        ) {
            return { pathname: routes.noPermission, state: { targetUrl: path } };
        }

        if (path.startsWith('/customer')) {
            if (!globalController.user.customerStripeId) {
                return {
                    pathname: routes.noPermission,
                    search: '?customer=true',
                    state: {}
                };
            }
            return {};
        }

        if (isCommonRoute) {
            return {};
        }

        if (!specialRoles && !path.startsWith(`/${globalController.company.type}`)) {
            return {
                pathname: routes.noPermission,
                search: `?valid_company_type=${globalController.company.type}`,
                state: { targetUrl: path }
            };
        }

        if (
            !isExtension &&
            roles &&
            !userHasAnyRole(globalController.user.id, globalController.company, roles)
        ) {
            return {
                pathname: routes.noPermission,
                search: `?valid_roles=${roles}`,
                state: { targetUrl: path }
            };
        }

        if (
            specialRoles &&
            !containsAnyRole(globalController?.user?.specialRoles || [], specialRoles)
        ) {
            return {
                pathname: routes.noPermission,
                search: `?valid_roles=${specialRoles}`,
                state: { targetUrl: path }
            };
        }

        if (
            needsCompleteCompany &&
            getOverallCompanySatus(globalController.company) !== CompanyStatus.COMPLETE
        ) {
            const isAgent = globalController.company.type === CompanyType.AGENT;
            const pathname = isAgent ? routes.agent.company.setup : routes.operator.company.setup;
            SnackbarManager.warning('Please setup your company details first!');
            return {
                pathname,
                search: '?redirected=true',
                state: { targetUrl: undefined }
            };
        }
        return {};
    };

    return (
        <Route
            path={path}
            render={() => {
                const redirectTo = getPathAndState();
                return isEmpty(redirectTo) || redirectTo.pathname === path ? (
                    <Component />
                ) : (
                    <Redirect to={redirectTo} />
                );
            }}
        />
    );
}
