import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    UserCredential
} from 'firebase/auth';

export class UserAuth {
    constructor(readonly firebaseAuth: any) {}

    public signUpUser = async (email: string, passowrd: string): Promise<UserCredential> => {
        return await createUserWithEmailAndPassword(this.firebaseAuth, email, passowrd);
    };

    public signInUser = async (email: string, passowrd: string): Promise<UserCredential> => {
        return await signInWithEmailAndPassword(this.firebaseAuth, email, passowrd);
    };
}
