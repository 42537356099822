"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportCategories = void 0;
var SupportCategories;
(function (SupportCategories) {
    SupportCategories["SETUP"] = "setup";
    SupportCategories["PRODUCTS"] = "products";
    SupportCategories["PAYMENTS"] = "payments";
    SupportCategories["USERS"] = "users";
    SupportCategories["BOOKINGS"] = "bookings";
    SupportCategories["CUSTOMER"] = "customer";
    SupportCategories["WEB"] = "web";
    SupportCategories["OPERATOR"] = "operator";
    SupportCategories["OTHER"] = "other";
})(SupportCategories = exports.SupportCategories || (exports.SupportCategories = {}));
