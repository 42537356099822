"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingUpdateApprovalType = exports.BookingStatus = exports.ApprovalResponse = void 0;
var ApprovalResponse;
(function (ApprovalResponse) {
    ApprovalResponse["PENDING"] = "pending";
    ApprovalResponse["APPROVED"] = "approved";
    ApprovalResponse["REJECTED"] = "rejected";
})(ApprovalResponse = exports.ApprovalResponse || (exports.ApprovalResponse = {}));
var BookingStatus;
(function (BookingStatus) {
    BookingStatus["DRAFT"] = "draft";
    BookingStatus["NEW"] = "new";
    BookingStatus["CONFIRMED"] = "confirmed";
    BookingStatus["PENDING_UPDATE"] = "pending_update";
    BookingStatus["PENDING_PAYMENT"] = "pending_payment";
    BookingStatus["PROCESSING_PAYMENT"] = "processing_payment";
    BookingStatus["PAYMENT_FAILED"] = "payment_failed";
    BookingStatus["PENDING_REFUND"] = "pending_refund";
    BookingStatus["PROCESSING_REFUND"] = "processing_refund";
    BookingStatus["CANCELLED"] = "cancelled";
    BookingStatus["BOOKING_FAILED"] = "booking_failed";
})(BookingStatus = exports.BookingStatus || (exports.BookingStatus = {}));
var BookingUpdateApprovalType;
(function (BookingUpdateApprovalType) {
    BookingUpdateApprovalType[BookingUpdateApprovalType["APPROVED"] = 0] = "APPROVED";
    BookingUpdateApprovalType[BookingUpdateApprovalType["REJECTED"] = -1] = "REJECTED";
    BookingUpdateApprovalType[BookingUpdateApprovalType["CANCELLED"] = -2] = "CANCELLED";
})(BookingUpdateApprovalType = exports.BookingUpdateApprovalType || (exports.BookingUpdateApprovalType = {}));
