/* eslint-disable comma-spacing */
import { DocumentSnapshot } from 'firebase/firestore';

export interface GetAllResult<T> {
    entities: T[];
    lastDoc?: DocumentSnapshotAny;
}

export type DocumentSnapshotAny = DocumentSnapshot<any>;

export const toEntity = <T,>(doc: DocumentSnapshotAny): T => {
    return { ...doc.data(), id: doc.id };
};

export const toEntities = <T,>(docs: DocumentSnapshotAny[]): T[] =>
    docs.map((doc) => toEntity(doc));
