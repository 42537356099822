import { Functions, httpsCallable } from 'firebase/functions';
import {
    ApiBookingValidation,
    FareharborAvailabilityRequest,
    FareharborRequest
} from 'firebase_api';

export class FareharborFunctions {
    constructor(readonly functions: Functions) {}

    public getProduct = async (request: FareharborRequest) =>
        (
            await httpsCallable<FareharborRequest, any>(
                this.functions,
                'getFareharborProductsByCompany'
            )(request)
        ).data;

    public getFareharborAvailability = async (request: FareharborAvailabilityRequest) =>
        (
            await httpsCallable<FareharborAvailabilityRequest, any>(
                this.functions,
                'getFareharborAvailability'
            )(request)
        ).data;

    public getFareharborDetailedAvailability = async (request: FareharborAvailabilityRequest) =>
        (
            await httpsCallable<FareharborAvailabilityRequest, any>(
                this.functions,
                'getFareharborDetailedAvailability'
            )(request)
        ).data;

    public getFareharborPickUps = async (request: FareharborAvailabilityRequest) =>
        (
            await httpsCallable<FareharborAvailabilityRequest, any>(
                this.functions,
                'getFareharborPickUps'
            )(request)
        ).data;

    public validateFareharborBooking = async (request: ApiBookingValidation) =>
        (
            await httpsCallable<ApiBookingValidation, string | undefined>(
                this.functions,
                'validateFareharborBooking'
            )(request)
        ).data;
}
