import { onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import {
    BookingGroup,
    BookingGroupItem,
    BookingStatus,
    Company,
    CompanyProductLinks,
    CompanyType,
    ImageType,
    User
} from 'firebase_api';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import {
    bookingGroupItemRepository,
    bookingGroupRepository,
    companyRepository,
    firebaseAuth,
    userRepository
} from '../../../firebase/firebaseIndex';
import { createStore, createStoreContext, DefaultProviderProps } from '../../../libs/stores/common';
import { checkExtension, getLocalStorage } from '../../../libs/utils/functions';
import { ExtensionQueryParams } from '../../pages/agent/extensions/ExtensionsAuthentication';
import SnackbarManager from '../common/snackbar/SnackbarManager';
import IsMounted from './IsMounted';
import { State, StateSetterType, StateType } from './State';

export enum StoreKeys {
    USER_ID = 'user_id',
    COMPANY_ID = 'company_id',
    EXTENSIONS = 'extensions_booking',
    IS_CUSTOMER = 'isCustomer'
}

export interface GlobalControllerValue {
    readonly user: User;
    readonly company: Company;
    readonly companies: Company[];
    readonly productLinks: CompanyProductLinks;
    readonly initialDataHasLoaded: boolean;
    readonly temporalLogo: ImageType | undefined;
    readonly pendingItems: { [groupId: string]: BookingGroupItem[] };
    readonly bookingGroups: { [groupId: string]: BookingGroup };
    readonly extensionParams: StateType<ExtensionQueryParams>;
    readonly setPendingItems: StateSetterType<{ [groupId: string]: BookingGroupItem[] }>;
    readonly changeCompany: (change: Company) => void;
    readonly setTemporalLogo: StateSetterType<ImageType | undefined>;
}

export const GlobalController = createStoreContext<GlobalControllerValue>();

export const GlobalControllerProvider = ({ children }: DefaultProviderProps) => {
    const isMounted = IsMounted();
    const initialDataHasLoaded = State(false, isMounted);
    const user = State<User>({}, isMounted);
    const company = State<Company>({}, isMounted);
    const companies = State<Company[]>([], isMounted);
    const productLinks = State<CompanyProductLinks>({}, isMounted);
    const temporalLogo = State<ImageType | undefined>(undefined, isMounted);
    const authUser = State<FirebaseUser | null>(null, isMounted);
    const isExtension = checkExtension();
    const isCustomer = !isEmpty(getLocalStorage()?.getItem(StoreKeys.IS_CUSTOMER));
    const pendingItems = State<{ [groupId: string]: BookingGroupItem[] }>({}, isMounted);
    const bookingGroups = State<{ [groupId: string]: BookingGroup }>({}, isMounted);
    const extensionParams = State<ExtensionQueryParams>({}, isMounted);

    const onSignout = () => {
        user.set({});
        company.set({});
        companies.set([]);
        productLinks.set({});
        initialDataHasLoaded.set(true);
        getLocalStorage()?.removeItem(StoreKeys.IS_CUSTOMER);
        getLocalStorage()?.removeItem(StoreKeys.EXTENSIONS);
    };

    const getUserLive = (id: string) =>
        userRepository.getByIdLive(
            id,
            (data) => data && user.set(data),
            (error) => SnackbarManager.error(error, 'GlobalControllerProvider - getUserLive')
        );

    useEffect(() => {
        return onAuthStateChanged(firebaseAuth, (firebaseUser) => {
            authUser.set(firebaseUser);
            !firebaseUser && onSignout();
        });
    }, []);

    useEffect(() => {
        getLocalStorage()?.setItem(StoreKeys.USER_ID, authUser.data?.uid || '');
        if (authUser.data?.uid) {
            initialDataHasLoaded.set(false);
            return getUserLive(authUser.data.uid);
        }
    }, [authUser.data?.uid]);

    const getCompanyLive = (companyId: string) =>
        companyRepository.getByIdLive(
            companyId,
            (data) => {
                companies.set((prev) => {
                    const currentIds = prev.map((p) => p.id!);
                    return currentIds.includes(data?.id || '')
                        ? prev.map((p) => (p.id === data?.id ? data || {} : p))
                        : prev.concat(data || {});
                });
                const selectedId = getLocalStorage()?.getItem(user.data.id!);
                if (selectedId === data?.id) {
                    company.set(data || {});
                }
            },
            (error) => SnackbarManager.error(error, 'GlobalControllerProvider - getCompanyLive')
        );

    useEffect(() => {
        const companyIds = user.data.companyIds || [];
        const selectedId = getLocalStorage()?.getItem(user.data.id!);
        if (!selectedId || !companyIds.includes(selectedId)) {
            getLocalStorage()?.setItem(user.data.id!, companyIds[0]);
        }
        companies.set((prev) => prev.filter((p) => companyIds.includes(p.id!)));
        const subscriptions = companyIds.map((companyId) => getCompanyLive(companyId));
        return () => {
            subscriptions.forEach((subscription) => subscription());
        };
    }, [user.data.companyIds]);

    useEffect(() => {
        if (
            !isEmpty(user.data) &&
            (isEmpty(user.data.companyIds) ||
                companies.data.length === user.data.companyIds!.length)
        ) {
            initialDataHasLoaded.set(true);
        }
    }, [user.data, companies.data]);

    const changeCompany = (change: Company) => {
        pendingItems.set({});
        company.set(change);
    };

    const onPendingItemsChanged = async (items: BookingGroupItem[]) => {
        try {
            const groupedItems = items.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.groupId!]: (acc[item.groupId!] || []).concat(item)
                }),
                {} as { [groupId: string]: BookingGroupItem[] }
            );
            const groupIds = Object.keys(groupedItems);
            const unfetchGroupIds = groupIds.filter(
                (id) => !Object.keys(bookingGroups).includes(id)
            );
            const result = await bookingGroupRepository.getManyByIds(unfetchGroupIds);
            for (const key in groupedItems) {
                const bookingGroup = (bookingGroups.data || {})[key];
                if (
                    (bookingGroup?.pendingChange?.status === BookingStatus.PENDING_PAYMENT &&
                        isEmpty(bookingGroup.pendingTransfers)) ||
                    result.find(
                        (group) =>
                            group.id === key &&
                            group.pendingChange?.status === BookingStatus.PENDING_PAYMENT &&
                            isEmpty(group.pendingTransfers)
                    )
                ) {
                    delete groupedItems[key];
                }
            }
            pendingItems.set(groupedItems);
            bookingGroups.set((prev) =>
                result.reduce((acc, act) => ({ ...acc, [act.id!]: act }), prev)
            );
        } catch (error) {
            SnackbarManager.error(error, 'GlobalControllerProvider-onPendingItemsChanged');
        }
    };

    useEffect(() => {
        if (company.data.id) {
            getLocalStorage()?.setItem(StoreKeys.COMPANY_ID, company.data.id || '');
            if (!isCustomer && !isExtension) {
                return bookingGroupItemRepository.getPendingLive(
                    company.data.id,
                    company.data.type === CompanyType.AGENT,
                    onPendingItemsChanged,
                    (error) =>
                        SnackbarManager.error(error, 'GlobalControllerProvider-getPendingLive')
                );
            }
        }
    }, [company.data.id]);

    return createStore(GlobalController, children, {
        initialDataHasLoaded: initialDataHasLoaded.data,
        user: user.data,
        company: company.data,
        companies: companies.data,
        productLinks: productLinks.data,
        temporalLogo: temporalLogo.data,
        pendingItems: pendingItems.data,
        bookingGroups: bookingGroups.data,
        setTemporalLogo: temporalLogo.set,
        setPendingItems: pendingItems.set,
        changeCompany,
        extensionParams
    });
};
