import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import PDialog from '../../../components/common/PDialog';
import { AccordionPanels } from './ExtensionsPage';
import { ExtensionsPageController } from './ExtensionsPageController';
import { ProductCatalogController } from './productCatalog/ProductCatalogController';

const StyledTab = styled(Grid)({
    opacity: 0.4,
    lineHeight: 1.6,
    cursor: 'pointer',
    padding: '20px 0px',
    textAlign: 'center'
});

const StyledStep = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    marginRight: 6,
    width: 24,
    height: 24,
    borderRadius: 100,
    color: theme.palette.common.white
}));

const styles = makeStyles((theme) => ({
    activeTab: { borderBottom: '3px solid ' + theme.palette.secondary.main, opacity: 1 },
    activStep: { background: theme.palette.secondary.main },
    inactiveStep: { background: theme.palette.text.secondary }
}));

export default function ExtensionsCodeSnippet() {
    const controller = useContext(ExtensionsPageController);
    const productCatalogController = useContext(ProductCatalogController);
    const theme = useTheme();
    const classes = styles();

    const step1 = (
        <Typography variant="body1" align="justify">
            {
                'The code here needs to pasted in between the <head> </head> tags, in the index.html file for your website. This file is normally located in the main folder for your website, so it should be easy to find. Then just look for the <head> section, and copy this code straight underneath.'
            }
        </Typography>
    );

    const step2 = (
        <Typography variant="body1" align="justify">
            {`Copy and paste this code into the section of the site that you'd like this component to appear.`}
            {controller.activeTab.data === AccordionPanels.BUTTON
                ? ' Booking buttons are normally placed inside other components, and you may need to adjust the styling for this elements to align the button.'
                : ''}
        </Typography>
    );

    return (
        <PDialog
            title={<Typography variant="h3">Code Snippet</Typography>}
            dialogProps={{
                open: controller.isCodeSnippetDialogOpen.data,
                maxWidth: 'md',
                style: { maxWidth: 800, margin: 'auto' }
            }}
            onClose={() => controller.isCodeSnippetDialogOpen.set(false)}>
            <Typography variant="body1" style={{ margin: '22px 0px 10px' }}>
                {`Let's get your new booking feature online. Follow our two step process, and you'll be selling tours in no time!`}
            </Typography>
            <Grid container style={{ marginBottom: 15 }}>
                <StyledTab
                    item
                    xs={12}
                    sm={6}
                    key={'step1'}
                    className={controller.isFirstCodeStep.data ? classes.activeTab : undefined}
                    onClick={() => {
                        controller.isFirstCodeStep.set(true);
                        controller.codeSnippetDialogText.set(
                            controller.activeTab.data === AccordionPanels.BUTTON
                                ? controller.generateCodeStep1()
                                : productCatalogController.generateProductCatalogStep1()
                        );
                    }}>
                    <StyledStep
                        className={
                            controller.isFirstCodeStep.data
                                ? classes.activStep
                                : classes.inactiveStep
                        }>
                        1
                    </StyledStep>
                    <Typography variant="body1" style={{ display: 'inline-block' }}>
                        HTML Head Tag
                    </Typography>
                </StyledTab>
                <StyledTab
                    item
                    xs={12}
                    sm={6}
                    key={'step2'}
                    className={controller.isFirstCodeStep.data ? undefined : classes.activeTab}
                    onClick={() => {
                        controller.isFirstCodeStep.set(false);
                        controller.codeSnippetDialogText.set(
                            controller.activeTab.data === AccordionPanels.BUTTON
                                ? controller.generateCodeStep2()
                                : productCatalogController.generateProductCatalogStep2()
                        );
                    }}>
                    <StyledStep
                        className={
                            controller.isFirstCodeStep.data
                                ? classes.inactiveStep
                                : classes.activStep
                        }>
                        2
                    </StyledStep>
                    <Typography variant="body1" style={{ display: 'inline-block' }}>
                        Component Code
                    </Typography>
                </StyledTab>
            </Grid>
            {controller.isFirstCodeStep.data ? step1 : step2}
            {controller.isFirstCodeStep.data && (
                <Typography
                    style={{
                        padding: 24,
                        margin: '16px 0px',
                        background: theme.palette.secondary.main + '1A',
                        color: theme.palette.secondary.main
                    }}>
                    Note: You only need to do this once. Then for any other component, you can skip
                    straight to Part 2.
                </Typography>
            )}
            <Grid item xs={12} style={controller.isFirstCodeStep.data ? {} : { marginTop: 16 }}>
                <IconButton
                    style={{
                        position: 'absolute',
                        right: controller.isFirstCodeStep.data ? '10%' : '7%',
                        marginTop: 10,
                        zIndex: theme.zIndex.modal
                    }}
                    title="Copy"
                    onClick={controller.copyCodeToClipboard}
                    size="large">
                    <FileCopyOutlinedIcon />
                </IconButton>
                <TextField
                    inputProps={{
                        readOnly: Boolean(true)
                    }}
                    fullWidth
                    multiline
                    rows={controller.isFirstCodeStep.data ? 15 : 21}
                    value={controller.codeSnippetDialogText.data}
                />
            </Grid>
        </PDialog>
    );
}
