import deepEqual from 'deep-equal';
import { Company, CompanyStatus, CompanyType, ContactType } from 'firebase_api';
import { isEmpty } from 'lodash';

export const getBasicDetailsStatus = (company: Company): CompanyStatus => {
    if (isEmpty(company.timeZone)) {
        return CompanyStatus.INCOMPLETE;
    }
    if (isEmpty(company.tradingName)) {
        return CompanyStatus.INCOMPLETE;
    }
    if (company.isSoleTrader && isEmpty(company.accountName)) {
        return CompanyStatus.INCOMPLETE;
    }
    if (isEmpty(company.supportPhone)) {
        return CompanyStatus.INCOMPLETE;
    }
    if (isEmpty(company.supportEmail)) {
        return CompanyStatus.INCOMPLETE;
    }
    if (isEmpty(company.abn)) {
        return CompanyStatus.INCOMPLETE;
    }
    if (company.type !== CompanyType.AGENT && isEmpty(company.termsAndConditionsURL)) {
        return CompanyStatus.INCOMPLETE;
    }
    return CompanyStatus.COMPLETE;
};

export const getStripeAccountStatus = (company: Company): CompanyStatus => {
    if (company.stripeAccountStatus === undefined) {
        return CompanyStatus.INCOMPLETE;
    }
    if (company.stripeAccountStatus === null) {
        return CompanyStatus.COMPLETE;
    }
    if (
        company.stripeAccountStatus === 'requirements.pending_verification' ||
        company.stripeAccountStatus === 'under_review'
    ) {
        return CompanyStatus.PENDING;
    }
    if (
        company.stripeAccountStatus === 'requirements.past_due' ||
        company.stripeAccountStatus === 'rejected.terms_of_service' ||
        company.stripeAccountStatus === 'rejected.other' ||
        company.stripeAccountStatus === 'other'
    ) {
        return CompanyStatus.ACTION_REQUIRED;
    }
    return CompanyStatus.DISABLED;
};

export const getCancellationPolicyStatus = (company: Company): CompanyStatus => {
    if (
        company.cancellationReturnPolicies === undefined ||
        deepEqual(company.cancellationReturnPolicies, {})
    ) {
        return CompanyStatus.INCOMPLETE;
    }
    return CompanyStatus.COMPLETE;
};

export const getCommissionRateStatus = (company: Company): CompanyStatus => {
    if (company.type === CompanyType.AGENT) {
        return CompanyStatus.COMPLETE;
    }
    if (company.commissionRate !== undefined && company.commissionRate > 0) {
        return CompanyStatus.COMPLETE;
    }
    return CompanyStatus.INCOMPLETE;
};

export const getTransactionFeeStatus = (company: Company): CompanyStatus => {
    if (company.type === CompanyType.OPERATOR) {
        return CompanyStatus.COMPLETE;
    }
    if (company.undertakeTransactionFee !== undefined) {
        return CompanyStatus.COMPLETE;
    }
    return CompanyStatus.INCOMPLETE;
};

export const getContactMemberStatus = (company: Company): CompanyStatus =>
    Object.keys(company.contacts || {}).findIndex(
        (userId) =>
            !(company.contacts || {})[userId]?.isPrivate &&
            (company.contacts || {})[userId].type?.includes(ContactType.ADMIN)
    ) > -1
        ? CompanyStatus.COMPLETE
        : CompanyStatus.INCOMPLETE;

export const getOverallCompanySatus = (company: Company): CompanyStatus => {
    if (getBasicDetailsStatus(company) !== CompanyStatus.COMPLETE) {
        return CompanyStatus.INCOMPLETE;
    }
    if (getStripeAccountStatus(company) !== CompanyStatus.COMPLETE) {
        return CompanyStatus.INCOMPLETE;
    }
    if (getCancellationPolicyStatus(company) !== CompanyStatus.COMPLETE) {
        return CompanyStatus.INCOMPLETE;
    }
    if (getCommissionRateStatus(company) !== CompanyStatus.COMPLETE) {
        return CompanyStatus.INCOMPLETE;
    }
    if (getTransactionFeeStatus(company) !== CompanyStatus.COMPLETE) {
        return CompanyStatus.INCOMPLETE;
    }
    // if (getContactMemberStatus(company) !== CompanyStatus.COMPLETE) {
    //     return CompanyStatus.INCOMPLETE;
    // }
    return CompanyStatus.COMPLETE;
};
