import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import notFoundImg from '../../../../assets/images/search_not_found.png';
import PDialog from '../../../components/common/PDialog';
import { routes } from '../../../utils/mainMenu/routes';
import { ExtensionsPageController, SEARCH_DEFAULT_LIMIT } from './ExtensionsPageController';
import { ProductCatalogController } from './productCatalog/ProductCatalogController';

export default function SearchProducts() {
    const controller = useContext(ExtensionsPageController);
    const productCatalogController = useContext(ProductCatalogController);
    const theme = useTheme();
    const productsLength = controller.products.length;

    return (
        <PDialog
            title="Select Product"
            dialogProps={{
                open:
                    productCatalogController.isCatalogSearchOpen.data ||
                    controller.isSearchFormOpen.data,
                maxWidth: 'sm',
                fullWidth: true
            }}
            contentProps={{ style: { paddingBottom: 20 } }}
            onClose={() => {
                controller.isSearchFormOpen.set(false);
                productCatalogController.isCatalogSearchOpen.set(false);
            }}
            cancelButtonText="Close">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        fullWidth
                        freeSolo
                        autoComplete={false}
                        loading={controller.fetchingProducts}
                        options={[]}
                        value={controller.searchProductValue || ''}
                        onInputChange={(_, value) => controller.onSearchFieldChanged(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                    form: { autocomplete: 'off' }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: <SearchIcon sx={{ mr: 2 }} />
                                }}
                                placeholder="Start typing product name"
                            />
                        )}
                    />
                </Grid>
                {productsLength > 0 ? (
                    <Grid container item style={{ maxHeight: 500, paddingBottom: 20 }}>
                        <List dense={true} style={{ width: '100%', paddingBottom: 30 }}>
                            {controller.products.map((product, index) => (
                                <ListItem
                                    button
                                    key={index}
                                    divider={
                                        index + 1 !== productsLength ||
                                        (index + 1) % SEARCH_DEFAULT_LIMIT === 0
                                    }
                                    onClick={() =>
                                        controller.isSearchFormOpen.data
                                            ? controller.onSelectProduct(product)
                                            : productCatalogController.addProductToList(product)
                                    }>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            style: { fontSize: 15 },
                                            variant: 'h6'
                                        }}
                                        primary={
                                            <Link
                                                target="_blank"
                                                rel="noopener"
                                                onClick={(event: any) => event.stopPropagation()}
                                                underline="hover"
                                                href={`${routes.agent.products(product.id || '')}`}>
                                                {product.name}{' '}
                                                <IconButton
                                                    edge="end"
                                                    title="Open Product in New Tab"
                                                    size="small"
                                                    style={{ marginTop: -5 }}>
                                                    <OpenInNewIcon style={{ fontSize: 18 }} />
                                                </IconButton>
                                            </Link>
                                        }
                                        secondary={product.company?.name}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        {productsLength % SEARCH_DEFAULT_LIMIT === 0 &&
                            !controller.isAllProductFetched.data && (
                                <ListItem
                                    button
                                    hidden={controller.isAllProductFetched.data}
                                    disabled={controller.fetchingProducts}
                                    style={{ padding: '10px  0px 12px', marginTop: -30 }}
                                    onClick={() =>
                                        controller.fetchProducts(
                                            controller.searchProductValue,
                                            SEARCH_DEFAULT_LIMIT
                                        )
                                    }>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            style: {
                                                fontSize: 14,
                                                textAlign: 'center'
                                            }
                                        }}
                                        primary={
                                            controller.fetchingProducts ? (
                                                <CircularProgress size={25} />
                                            ) : (
                                                'View More...'
                                            )
                                        }
                                    />
                                </ListItem>
                            )}
                    </Grid>
                ) : (
                    <Box style={{ margin: '35px auto 20px', textAlign: 'center' }}>
                        <img src={notFoundImg} style={{ marginBottom: 20 }} />
                        <Typography variant="h6">Search Products</Typography>
                        <Typography
                            variant="body2"
                            style={{ lineHeight: 1.8, color: theme.palette.text.shadowText }}>
                            We can’t find any items matching your search
                        </Typography>
                    </Box>
                )}
            </Grid>
        </PDialog>
    );
}
