import {
    collection,
    collectionGroup,
    Firestore,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    where
} from 'firebase/firestore';
import { Collection, Transfer } from 'firebase_api';
import { last } from 'lodash';
import moment from 'moment';
import { CrudRepository } from '../CrudRepository';
import { DocumentSnapshotAny, GetAllResult, toEntities } from '../utils';

export class TransactionRepository extends CrudRepository<Transfer> {
    constructor(readonly batchLimit: number, db: Firestore) {
        super(db, Collection.TRANSFERS, Collection.BOOKING_GROUPS);
    }

    public getAllByFilters = async (
        companyId: string,
        transfer: Transfer,
        startDate?: string,
        endDate?: string,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<Transfer>> => {
        let queryResult = query(
            collectionGroup(this.db, this.collectionName),
            where('companyId', '==', companyId),
            limit(this.batchLimit),
            orderBy('createdAt', 'asc')
        );
        if (startDate) {
            queryResult = query(
                queryResult,
                where('createdAt', '>', moment(startDate).utc().toISOString())
            );
        }
        if (endDate) {
            queryResult = query(
                queryResult,
                where('createdAt', '<', moment(endDate).utc().toISOString())
            );
        }
        if (transfer.agentId) {
            queryResult = query(queryResult, where('agentId', '==', transfer.agentId));
        }
        if (transfer.operatorId) {
            queryResult = query(queryResult, where('operatorId', '==', transfer.operatorId));
        }
        if (transfer.productId) {
            queryResult = query(queryResult, where('productId', '==', transfer.productId));
        }
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<Transfer>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getByDateFilters = async (
        companyId: string,
        startDate?: string,
        endDate?: string,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<Transfer>> => {
        let queryResult = query(
            collectionGroup(this.db, this.collectionName),
            where('companyId', '==', companyId),
            limit(this.batchLimit),
            orderBy('createdAt', 'desc')
        );
        if (startDate) {
            queryResult = query(
                queryResult,
                where('createdAt', '>', moment(startDate).utc().toISOString())
            );
        }
        if (endDate) {
            queryResult = query(
                queryResult,
                where('createdAt', '<', moment(endDate).utc().toISOString())
            );
        }
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<Transfer>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getByBookingId = async (
        bookingId: string,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<Transfer>> => {
        let queryResult = query(
            collection(this.db, `${this.getRoot(bookingId)}${this.collectionName}`),
            limit(this.batchLimit),
            orderBy('createdAt', 'asc')
        );
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<Transfer>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getTransactionsByCreatedAtLive = (
        companyId: string,
        startDateTime: string,
        endDateTime: string,
        onChange: (transactions: Transfer[]) => void,
        onError: (error: Error) => void
    ): (() => void) =>
        onSnapshot(
            query(
                collectionGroup(this.db, this.collectionName),
                where('companyId', '==', companyId),
                where('createdAt', '>=', startDateTime),
                where('createdAt', '<=', endDateTime)
            ),
            (snapshot) => {
                const transactions = toEntities<Transfer>(snapshot.docs);
                onChange(transactions);
            },
            (error) => onError(error)
        );

    public getBookingTransaction = async (
        companyId: string,
        groupId: string,
        customerId?: string,
        itemRef?: string
    ) => {
        let queryResult = query(
            collection(this.db, `${this.getRoot(groupId)}${this.collectionName}`),
            where('companyId', '==', companyId),
            orderBy('createdAt', 'desc')
        );
        if (itemRef) {
            queryResult = query(queryResult, where('itemReference', '==', itemRef));
        }
        if (customerId) {
            queryResult = query(queryResult, where('customerId', '==', customerId));
        }
        const snapshot = await getDocs(queryResult);
        return toEntities<Transfer>(snapshot.docs);
    };
}
