import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import LoadingButton from '../../../components/common/LoadingButton';
import TableTabs from '../../../components/common/table/TableTabs';
import ExtensionsBookNowBtn from './bookNowButton/BookNowButton';
import BookNowButtonCustomization from './bookNowButton/BookNowButtonCustomization';
import BookNowSelectProducts from './bookNowButton/BookNowSelectProducts';
import ExtensionsCodeSnippet from './BookNowCodeSnippet';
import {
    ExtensionsPageController,
    ExtensionsPageControllerProvider
} from './ExtensionsPageController';
import ProductCatalog from './productCatalog/ProductCatalog';
import {
    ProductCatalogController,
    ProductCatalogControllerProvider
} from './productCatalog/ProductCatalogController';
import ProductCatalogCustomization from './productCatalog/ProductCatalogCustomization';
import SearchProducts from './SearchProducts';

export const extensionsStyle = makeStyles((theme) => ({
    boxContainer: {
        padding: '50px 0px 30px',
        [theme.breakpoints.down('lg')]: {
            padding: '40px 0px 30px'
        },
        width: '100%'
    },
    mainContainer: {
        [theme.breakpoints.down('lg')]: {
            width: '96%'
        },
        width: '92%',
        margin: 'auto',
        maxWidth: 1300
    },
    gridContainer: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        border: '1px solid ' + theme.palette.background.tabelRow,
        borderRadius: 8
    },
    activeSectionButton: {
        color: theme.palette.background.paperDark,
        backgroundColor: theme.palette.common.white,
        height: 22
    },
    sectionButton: {
        color: theme.palette.background.tabelRow,
        backgroundColor: theme.palette.common.white,
        height: 22,
        fontWeight: 400
    },
    footerBg: {
        maxHeight: 120,
        minHeight: 74,
        padding: 20,
        borderTop: '1px solid' + theme.palette.background.tabelRow,
        [theme.breakpoints.down('md')]: {
            padding: 14
        }
    },
    buttonContainer: {
        width: '80%',
        height: 'max-content',
        [theme.breakpoints.down('md')]: {
            width: '90%'
        }
    },
    tabClass: {
        maxWidth: '50%',
        flexBasis: '50%',
        borderBottom: '2px solid ' + theme.palette.text.shadowText,
        fontWeight: 600,
        opacity: 0.4
    }
}));

export enum AccordionPanels {
    BUTTON = 'Button',
    CATALOG = 'Catalogue'
}

function ExtensionsPage() {
    const theme = useTheme();
    const classes = extensionsStyle();
    const controller = useContext(ExtensionsPageController);
    const productCatalogController = useContext(ProductCatalogController);
    const tabsAndCounts = () =>
        Object.values(AccordionPanels).reduce(
            (acc, label) => ({ ...acc, [label]: 0 }),
            {} as { [label: string]: number }
        );

    return (
        <Box className={classes.boxContainer}>
            <Grid
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
                className={classes.mainContainer}>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h3" style={{ marginBottom: 24 }}>
                            Extensions
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.gridContainer}>
                    <Grid item xs={12} md={4} style={{ minWidth: 402 }}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <TableTabs
                                    activeTab={controller.activeTab}
                                    tabs={tabsAndCounts()}
                                    extraTabClass={classes.tabClass}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {controller.activeTab.data === AccordionPanels.BUTTON ? (
                                <>
                                    <BookNowSelectProducts />
                                    <BookNowButtonCustomization />
                                </>
                            ) : (
                                <ProductCatalogCustomization />
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={true}
                        container
                        direction="column-reverse"
                        sx={{
                            flex: 1,
                            borderLeft: '1px solid' + theme.palette.background.tabelRow,
                            minHeight: 250,
                            overflow: 'auto'
                        }}>
                        <Grid
                            item
                            container
                            alignItems="center"
                            direction="row-reverse"
                            className={classes.footerBg}>
                            <Grid item style={{ textAlign: 'end' }}>
                                <LoadingButton
                                    style={{ width: 160 }}
                                    variant="contained"
                                    disabled={
                                        controller.activeTab.data === AccordionPanels.BUTTON
                                            ? isEmpty(controller.selectedProduct.data)
                                            : isEmpty(productCatalogController.currentCatalog.data)
                                    }
                                    onClick={() => {
                                        controller.isFirstCodeStep.set(true);
                                        controller.codeSnippetDialogText.set(
                                            controller.activeTab.data === AccordionPanels.BUTTON
                                                ? controller.generateCodeStep1()
                                                : productCatalogController.generateProductCatalogStep1()
                                        );
                                        controller.isCodeSnippetDialogOpen.set(true);
                                    }}>
                                    Generate Code
                                </LoadingButton>
                            </Grid>
                            <Grid item style={{ flex: 1 }}>
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: theme.palette.text.shadowText,
                                        marginRight: 14
                                    }}>
                                    {`Happy with your snazzy new ${
                                        controller.activeTab.data === AccordionPanels.BUTTON
                                            ? 'Booking Button'
                                            : 'Product Catalogue'
                                    }? Get some code and make it live.`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                            sx={{ flex: 1 }}>
                            {controller.activeTab.data === AccordionPanels.BUTTON ? (
                                <ExtensionsBookNowBtn
                                    sizeOption={controller.buttonSizeOption}
                                    textSize={controller.textSize}
                                    fontWeight={controller.fontWeight}
                                    textColor={controller.buttonTextLastColor}
                                    btnColor={controller.buttonLastColor}
                                    borderRadius={controller.roundEdgeSize}
                                />
                            ) : (
                                <ProductCatalog
                                    textColor={productCatalogController.buttonTextLastColor}
                                    btnColor={productCatalogController.buttonLastColor}
                                    borderRadius={productCatalogController.roundEdgeSize}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <ExtensionsCodeSnippet />
                <SearchProducts />
            </Grid>
        </Box>
    );
}

export default function ExtensionsPageIndex() {
    return (
        <ExtensionsPageControllerProvider>
            <ProductCatalogControllerProvider>
                <ExtensionsPage />
            </ProductCatalogControllerProvider>
        </ExtensionsPageControllerProvider>
    );
}
