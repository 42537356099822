import {
    collection,
    Firestore,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    where
} from 'firebase/firestore';
import { Collection, CompanyFeature, CompanyNote, Task } from 'firebase_api';
import { last } from 'lodash';
import { CrudRepository } from '../CrudRepository';
import { DocumentSnapshotAny, GetAllResult, toEntities } from '../utils';

export class NoteAndTaskRepository extends CrudRepository<CompanyNote | Task> {
    constructor(
        private readonly batchLimit: number,
        db: Firestore,
        readonly rootCollection: Collection.COMPANIES
    ) {
        super(db, Collection.NOTES_TASKS, rootCollection);
    }

    public getByType = async (
        type: CompanyFeature,
        rootId: string,
        userId: string,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<CompanyNote | Task>> => {
        let queryResult = query(
            collection(this.db, `${this.rootCollection}/${rootId}/${this.collectionName}`),
            where('type', '==', type),
            where('visibility.users', 'array-contains', userId),
            limit(this.batchLimit)
        );
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        const entities =
            type === CompanyFeature.NOTE
                ? toEntities<CompanyNote>(snapshot.docs)
                : toEntities<Task>(snapshot.docs);
        return { lastDoc: last(snapshot.docs), entities };
    };

    public getUpdatedLive = (
        rootId: string,
        userId: string,
        type: CompanyFeature,
        onChange: (newItems: CompanyNote[] | Task[]) => void,
        onError: (error: any) => void
    ) =>
        onSnapshot(
            query(
                collection(this.db, `${this.rootCollection}/${rootId}/${this.collectionName}`),
                where('type', '==', type),
                where('visibility.users', 'array-contains', userId),
                limit(this.batchLimit),
                orderBy('updatedAt', 'desc')
            ),
            (snapshot) => onChange(toEntities<CompanyNote | Task>(snapshot.docs)),
            onError
        );
}
