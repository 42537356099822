import DragHandleIcon from '@mui/icons-material/DragHandle';
import PageviewIcon from '@mui/icons-material/Pageview';
import {
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography
} from '@mui/material';
import { useContext } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import DeleteButton from '../../../../components/common/DeleteButton';
import PDialog from '../../../../components/common/PDialog';
import { ProductCatalogController } from './ProductCatalogController';

export default function CreateCatalog() {
    const controller = useContext(ProductCatalogController);

    return (
        <PDialog
            title="Product List"
            dialogProps={{
                open: controller.isCreateCatalogFormOpen.data,
                maxWidth: 'sm',
                fullWidth: true
            }}
            onClose={() => controller.isCreateCatalogFormOpen.set(false)}
            submitButtonProps={{
                onClick: () => {
                    controller.saveCatalogList();
                }
            }}
            cancelButtonText="Close"
            submitButtonText="Save List">
            <Typography variant="h6" style={{ paddingBottom: 12 }}>
                List Title
            </Typography>
            <TextField
                fullWidth
                name="listTitle"
                value={controller.currentCatalog.data.title || ''}
                variant="outlined"
                placeholder="Catalog Title"
                error={!!controller.titleError.data}
                helperText={controller.titleError.data}
                onChange={(event) => controller.changeCatalogTitle(event.target.value)}
                onBlur={(event) => controller.changeCatalogTitle(event.target.value.trim())}
            />
            <Typography variant="h6" style={{ paddingBottom: 12, paddingTop: 30 }}>
                Products
            </Typography>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Add New Products"
                    onClick={() => controller.isCatalogSearchOpen.set(true)}
                    name="productName"
                    InputProps={{
                        inputProps: {
                            style: { cursor: 'pointer' }
                        },
                        endAdornment: (
                            <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                <PageviewIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            {Object.keys(controller.currentCatalog.data.productNamesIds || {}).length > 0 && (
                <Grid container item style={{ maxHeight: 500, paddingTop: 24 }}>
                    <Typography variant="body2">Added Products</Typography>
                    <List dense={true} style={{ width: '100%' }}>
                        <Container
                            dragHandleSelector=".drag-handle"
                            lockAxis="y"
                            onDrop={controller.onDropFn}>
                            {controller.currentCatalog.data.productsOrder?.map((id) => (
                                <Draggable key={id}>
                                    <ListItem
                                        style={{
                                            border: '1px solid rgba(0, 25, 76, 0.15)',
                                            borderRadius: 4,
                                            marginTop: 6,
                                            marginBottom: 6,
                                            alignItems: 'center',
                                            alignContent: 'center'
                                        }}
                                        onClick={() => {}}>
                                        <ListItemIcon
                                            className="drag-handle"
                                            sx={{ cursor: 'move' }}>
                                            <DragHandleIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                style: { fontSize: 15 },
                                                variant: 'h6'
                                            }}
                                            primary={
                                                <Grid container justifyContent={'space-between'}>
                                                    <Typography alignSelf={'center'}>
                                                        {
                                                            controller.currentCatalog.data
                                                                .productNamesIds[id]
                                                        }
                                                    </Typography>
                                                    <DeleteButton
                                                        buttonProps={{
                                                            sx: {
                                                                height: 30,
                                                                width: 40,
                                                                background:
                                                                    'transparent !important',
                                                                '&:hover': {
                                                                    background: (theme) =>
                                                                        theme.palette.text
                                                                            .companyText +
                                                                        '28 !important'
                                                                }
                                                            }
                                                        }}
                                                        onClick={() =>
                                                            controller.removeProductFromList(id)
                                                        }
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                </Draggable>
                            ))}
                        </Container>
                    </List>
                </Grid>
            )}
        </PDialog>
    );
}
