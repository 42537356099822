import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { ThemeStore } from '../../../../libs/stores/ThemeStore';
import { uiString } from '../../../../libs/utils/functions';
import IsMounted from '../../wrappers/IsMounted';
import { State, StateType } from '../../wrappers/State';
import { commonTableStyles } from '../styles/CommonTableStyles';

export interface TableTabsProps {
    readonly activeTab: StateType<any>;
    readonly tabs: { [label: string]: number };
    readonly disabledLabel?: string;
    readonly extraTabClass?: any;
}

export default function TableTabs({
    activeTab,
    tabs,
    disabledLabel,
    extraTabClass
}: TableTabsProps) {
    const isMounted = IsMounted();
    const activeTabIndex = State<number>(0, isMounted);
    const themeStore = useContext(ThemeStore);
    const classes = commonTableStyles();

    return (
        <Tabs
            value={activeTab?.data}
            onChange={(_e, index) => activeTabIndex.set(index)}
            TabIndicatorProps={{
                style: { color: themeStore.theme.palette.secondary.main }
            }}
            textColor="secondary"
            indicatorColor={'secondary'}
            centered>
            {Object.keys(tabs).map((label) => (
                <Tab
                    disabled={disabledLabel === label}
                    key={label}
                    value={label}
                    className={clsx(classes.tabs, extraTabClass)}
                    onClick={() => activeTab.set(label)}
                    label={`${uiString(label)}${tabs[label] ? ` (${tabs[label]})` : ''}`}
                />
            ))}
        </Tabs>
    );
}
