import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Autocomplete,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ExtensionCatalog } from 'firebase_api';
import { isEmpty } from 'lodash';
import { Color, ColorPicker } from 'material-ui-color';
import { useContext } from 'react';
import PDialog from '../../../../components/common/PDialog';
import { IOSSwitch } from '../../../operator/products/upsert/common/IOSSwitch';
import {
    ContainerPadding,
    CustomizationGrid,
    customizationStyle
} from '../bookNowButton/BookNowButtonCustomization';
import CreateCatalog from './CreateCatalog';
import { ProductCatalogController } from './ProductCatalogController';

export default function ProductCatalogCustomization() {
    const theme = useTheme();
    const controller = useContext(ProductCatalogController);
    const classes = customizationStyle();

    return (
        <>
            <Grid container style={{ padding: '28px 24px 16px' }}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            controller.isCreateCatalogFormOpen.set(true);
                            controller.selectedCatalogId.set('');
                            controller.currentCatalog.set({});
                        }}>
                        Add New
                    </Button>
                </Grid>
                <Grid container item xs={12} sx={{ pt: 2.2, gap: 2 }}>
                    <Grid item xs={10}>
                        <Autocomplete
                            disableClearable
                            value={
                                [
                                    controller.selectedCatalogId.data || '',
                                    controller.currentCatalog.data || {}
                                ] as [string, ExtensionCatalog]
                            }
                            getOptionLabel={(catalog: [string, ExtensionCatalog]) =>
                                catalog?.[1]?.title || 'Select Product Catalogue'
                            }
                            style={{
                                opacity: isEmpty(controller.currentCatalog.data) ? '0.5' : '1'
                            }}
                            isOptionEqualToValue={() => true}
                            options={Object.entries(
                                controller.currentCompany.extensionCatalog || {}
                            )}
                            renderOption={(_props, catalog: [string, ExtensionCatalog]) => (
                                <List key={catalog?.[1]?.title}>
                                    <ListItem
                                        onClick={() => {
                                            controller.currentCatalog.set(catalog[1]);
                                            controller.selectedCatalogId.set(catalog[0]);
                                        }}
                                        disablePadding
                                        secondaryAction={
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => {
                                                    controller.openRemoveDialog.set(true);
                                                    controller.currentCatalog.set(catalog[1]);
                                                    controller.selectedCatalogId.set(catalog[0]);
                                                }}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        }>
                                        <ListItemButton sx={{ m: 0, p: 0.5 }}>
                                            <ListItemText
                                                sx={{ m: 0, p: 0, px: 2 }}
                                                primary={catalog?.[1]?.title}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            )}
                            renderInput={(params) => (
                                <TextField required {...params} variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            sx={{
                                border: '1px solid rgba(0, 25, 76, 0.15)',
                                borderRadius: 2,
                                height: 46,
                                width: 46
                            }}
                            disabled={!controller.selectedCatalogId.data}
                            onClick={() => controller.isCreateCatalogFormOpen.set(true)}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <CreateCatalog />
            </Grid>
            <Grid item xs={12} className={classes.container}>
                <Typography variant="h6" style={{ marginBottom: 20 }}>
                    Customise Widget
                </Typography>
                <Grid container item xs={12} alignItems="center" style={{ padding: '8px 0' }}>
                    <Grid item>
                        <Typography>Rounded Edges</Typography>
                        <Typography
                            variant="body2"
                            style={{ color: theme.palette.text.shadowText, paddingBottom: 4 }}>
                            Set 0px for square edges
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="flex-end" style={{ flex: 1 }}>
                        <TextField
                            type="tel"
                            style={{
                                backgroundColor: theme.palette.common.white,
                                width: 78,
                                borderRadius: 6
                            }}
                            value={
                                (controller.roundEdgeSize || 0) === 0
                                    ? ''
                                    : controller.roundEdgeSize
                            }
                            placeholder=" 0"
                            inputProps={{ maxLength: 2 }}
                            onChange={(event) => controller.changeRoundEdgeSize(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.textFieldText}>
                                            px
                                        </Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" style={{ padding: '8px 0' }}>
                    <Grid item>
                        <Typography style={{ paddingBottom: 4 }}>Button Text Colour</Typography>
                    </Grid>
                    <CustomizationGrid item container spacing={1}>
                        <Grid item>
                            <ColorPicker
                                value={controller.buttonTextColor}
                                onChange={(newValue: Color) => {
                                    controller.changeButtonTextColor(newValue);
                                }}
                                hideTextfield
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                className={classes.inputFields}
                                value={controller.buttonTextLastColor}
                                inputProps={{
                                    readOnly: Boolean(true)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography
                                                style={{
                                                    color: theme.palette.text.disabled
                                                }}>
                                                #
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </CustomizationGrid>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                    <Grid item>
                        <Typography style={{ paddingBottom: 4 }}>Background Colour</Typography>
                    </Grid>
                    <CustomizationGrid item container spacing={1}>
                        <Grid item>
                            <ColorPicker
                                value={controller.buttonColor}
                                onChange={(newValue: Color) => {
                                    controller.changeButtonColor(newValue);
                                }}
                                hideTextfield
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                className={classes.inputFields}
                                value={controller.buttonLastColor}
                                inputProps={{
                                    readOnly: Boolean(true)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography
                                                style={{
                                                    color: theme.palette.text.disabled
                                                }}>
                                                #
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </CustomizationGrid>
                </Grid>
                <Grid container item xs={12} alignItems="center" style={{ paddingTop: 16 }}>
                    <Grid item xs={8}>
                        <Typography style={{ padding: '9px 0' }}>Include Div Container</Typography>
                    </Grid>
                    <Grid item xs={4} container justifyContent="flex-end">
                        <IOSSwitch
                            checked={controller.isParentDivChecked}
                            onChange={() => controller.changeParentDivState()}
                        />
                    </Grid>
                </Grid>
                <ContainerPadding
                    isParentDivChecked={controller.isParentDivChecked}
                    defaultPadding={controller.defaultPadding}
                    parentDivPaddings={controller.parentDivPaddings}
                    changeAllPadding={controller.changeAllPadding}
                    changeParentDivState={controller.changeParentDivState}
                    changeButtonPadding={controller.changeButtonPadding}
                />
            </Grid>
            <Grid container style={{ padding: '20px 24px' }}>
                <Typography variant="h6" sx={{ mb: 1.5 }}>
                    Catalogue Design
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    The catalogue must be pasted into a full width component on your website,
                    otherwise it will not look very nice.
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    The catalogue will automatically scale for different screen sizes, from mobile
                    to desktop.
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    You can include as many products in the catalogue as you like. You may also
                    create multiple lists, if you want to include different catalogues on different
                    pages.
                </Typography>
            </Grid>
            <PDialog
                dialogProps={{ open: controller.openRemoveDialog.data }}
                title={'Delete Catalogue List'}
                submitButtonText="Yes"
                cancelButtonText="No"
                onClose={() => controller.openRemoveDialog.set(false)}
                submitButtonProps={{
                    onClick: () => controller.removeCatalogList(),
                    color: 'primary'
                }}>
                <Typography variant={'h5'}>Are you sure you want to delete this list?</Typography>
            </PDialog>
        </>
    );
}

// Responsive Desing Example

// const catalogCustomizationStyle = makeStyles((theme) => ({
//     inactiveResponsive: {
//         borderRadius: 6,
//         width: 43,
//         height: 43,
//         margin: '0 4px',
//         border: '1px solid' + theme.palette.text.companyText + 66,
//         color: theme.palette.text.companyText + 66,
//         backgroundColor: theme.palette.common.white,
//         '&:hover': {
//             backgroundColor: theme.palette.text.companyText,
//             color: theme.palette.common.black
//         }
//     },
//     activeResponsive: {
//         borderRadius: 6,
//         width: 43,
//         height: 43,
//         margin: '0 4px',
//         border: '1px solid' + theme.palette.inputBorder,
//         color: theme.palette.common.black,
//         backgroundColor: theme.palette.common.white,
//         '&:hover': {
//             backgroundColor: theme.palette.text.companyText
//         }
//     }
// }));

// <Grid container style={{ padding: '20px 24px' }}>
// <Grid
//     item
//     xs={12}
//     container
//     justifyContent="space-between"
//     alignItems="center"
//     direction="row">
//     <Grid item xs={4}>
//         <Typography>Responsive</Typography>
//     </Grid>
//     <Grid item xs={8} container justifyContent="flex-end">
//         <Grid item>
//             <IconButton
//                 className={
//                     controller.selectedResponsiveOption ===
//                     ResponsiveOptions.PORTRAIT
//                         ? customClasses.activeResponsive
//                         : customClasses.inactiveResponsive
//                 }
//                 onClick={() =>
//                     controller.changeResponsiveOption(ResponsiveOptions.PORTRAIT)
//                 }
//                 size="large">
//                 <StayCurrentPortraitIcon />
//             </IconButton>
//         </Grid>
//         <Grid item>
//             <IconButton
//                 className={
//                     controller.selectedResponsiveOption ===
//                     ResponsiveOptions.LANDSCAPE
//                         ? customClasses.activeResponsive
//                         : customClasses.inactiveResponsive
//                 }
//                 onClick={() =>
//                     controller.changeResponsiveOption(ResponsiveOptions.LANDSCAPE)
//                 }
//                 size="large">
//                 <StayCurrentLandscapeIcon />
//             </IconButton>
//         </Grid>
//         <Grid item>
//             <IconButton
//                 className={
//                     controller.selectedResponsiveOption ===
//                     ResponsiveOptions.DESKTOP
//                         ? customClasses.activeResponsive
//                         : customClasses.inactiveResponsive
//                 }
//                 onClick={() =>
//                     controller.changeResponsiveOption(ResponsiveOptions.DESKTOP)
//                 }
//                 size="large">
//                 <LaptopIcon />
//             </IconButton>
//         </Grid>
//     </Grid>
// </Grid>
// <Grid item xs={12} style={{ padding: '16px 0' }}>
//     <Typography style={{ fontWeight: 600, lineHeight: 1.85 }}>
//         Up to {controller.containerCount}
//         {controller.containerCount > 1 ? ' Containers' : ' Container'}
//     </Typography>
//     <Typography style={{ paddingBottom: 16 }}>
//         {controller.selectedResponsiveOption}
//     </Typography>
//     <Grid item xs={12} container justifyContent="space-around">
//         {[...Array(controller.containerCount)].map((_, i) => (
//             <Grid item xs={3} key={i}>
//                 <img src={ProductCatImg} style={{ width: '100%' }} />
//             </Grid>
//         ))}
//     </Grid>
// </Grid>
// </Grid>
