import makeStyles from '@mui/styles/makeStyles';

export const commonTableStyles = makeStyles((theme) => ({
    table: {
        '& .MuiToolbar-root': {
            paddingLeft: 0
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid ' + theme.palette.primary.main
        },
        '& .MuiInput-root': theme.components?.MuiInput?.styleOverrides?.inputTypeSearch,
        '& .MuiInputBase-inputMarginDense': { padding: 6 },
        '& .MuiIcon-fontSizeSmall': { fontSize: '1.5rem' },
        '& .MuiIconButton-colorInherit': { padding: 2 },
        '& .MuiPaper-rounded': { borderRadius: 10 },
        '& .css-ursahc-MuiInputBase-input-MuiInput-input': {
            padding: '5px 3px'
        },
        overflowX: 'auto',
        borderRadius: 10
    },
    tableBox: {
        margin: '50px 32px',
        [theme.breakpoints.only('sm')]: {
            margin: '32px 16px'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '24px 16px'
        }
    },
    addButton: { minWidth: 130 },
    tableTitle: { marginBottom: 36 },
    tabs: {
        height: 78,
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'none'
    },
    expansionPanel: {
        maxWidth: '90%',
        minWidth: 700,
        marginTop: 40
    },
    filterBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 20px',
        marginBottom: 20
    },
    input: {
        flex: 1
    },
    selectLocationContainer: {
        display: 'inline-flex',
        flex: 5
    },
    locationFields: {
        flex: 1,
        marginLeft: '20px'
    },
    clearButton: {
        marginLeft: 20,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    gridFillSpace: {
        flex: 1
    },
    tableContainer: {
        margin: '20px auto',
        textAlign: 'center',
        width: '90%',
        minWidth: 700
    }
}));

type OwnStyleType = { [label: string]: React.CSSProperties };

export const muiTableStyle: OwnStyleType = {
    actionsCellStyle: {
        color: 'primary',
        paddingRight: 60
    },
    lastColumn: {
        paddingRight: 10
    },
    headerStyle: {
        lineHeight: 1.57,
        fontWeight: 400,
        fontSize: 14,
        opacity: '0.55',
        padding: '8px 40px 8px 0'
    },
    searchFieldStyle: {
        padding: '6px 0 6px 14px',
        margin: '12px 15px 12px',
        borderRadius: 4
    },
    productsActionsCellStyle: {
        color: 'primary',
        padding: '8px 40px 8px 0'
    },
    productsHeaderStyle: { padding: '8px 40px 8px 0' },
    imgColumnHeader: {
        padding: '8px 0 8px 40px'
    },
    imgColumn: {
        padding: '8px 0 8px 30px'
    },
    commonCellStyle: { padding: '8px 16px 8px 0px' },
    firstColumnCellStyle: { padding: '8px 16px 8px 40px' }
};
