import {
    collection,
    collectionGroup,
    doc,
    endAt,
    Firestore,
    FirestoreError,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    startAt,
    where,
    writeBatch
} from 'firebase/firestore';
import { BookingGroup, BookingGroupItem, Collection } from 'firebase_api';
import { last } from 'lodash';
import { CrudRepository } from '../CrudRepository';
import { DocumentSnapshotAny, GetAllResult, toEntities } from '../utils';
import { BookingGroupItemRepository } from './BookingGroupItemRepository';

export class BookingGroupRepository extends CrudRepository<BookingGroup> {
    private readonly itemRepository: BookingGroupItemRepository;

    constructor(readonly batchLimit: number, db: Firestore) {
        super(db, Collection.BOOKING_GROUPS);
        this.itemRepository = new BookingGroupItemRepository(batchLimit, db);
    }

    public getBookingsByCustomerId = async (
        companyId: string | undefined,
        customerId: string,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<BookingGroup>> => {
        let queryResult = query(
            collection(this.db, this.collectionName),
            where('customer.id', '==', customerId),
            limit(this.batchLimit),
            orderBy('updatedAt', 'desc')
        );
        if (companyId) {
            queryResult = query(queryResult, where('companies', 'array-contains', companyId));
        }
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<BookingGroup>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getBookingsByCustomerIdLive = (
        companyId: string | undefined,
        customerId: string,
        updatedAtTime: string,
        callback: (result: GetAllResult<BookingGroup>) => void,
        onError: (error: FirestoreError) => void
    ) => {
        let queryResult = query(
            collection(this.db, this.collectionName),
            where('customer.id', '==', customerId),
            where('updatedAt', '>=', updatedAtTime),
            limit(this.batchLimit),
            orderBy('updatedAt', 'desc')
        );

        if (companyId) {
            queryResult = query(queryResult, where('companies', 'array-contains', companyId));
        }
        return onSnapshot(
            queryResult,
            (snapshot) => {
                const result = {
                    entities: toEntities<BookingGroup>(snapshot.docs),
                    lastDoc: last(snapshot.docs)
                };
                callback(result);
            },
            onError
        );
    };

    public deleteUnpaidGroupAndItems = async (
        groupId: string,
        companyId: string,
        itemIds?: string[]
    ) => {
        const batch = writeBatch(this.db);
        const itemsToDelete =
            itemIds ||
            (await this.itemRepository.getAllByCompanyId(groupId, companyId)).map(
                (item) => item.id!
            );
        const base = `${this.collectionName}/${groupId}`;
        itemsToDelete.forEach((id) =>
            batch.delete(doc(this.db, `${base}/${Collection.BOOKINGS}/${id}`))
        );
        batch.delete(doc(this.db, base));
        await batch.commit();
    };

    public getFiltered = async (
        companyId: string,
        customerId?: string,
        reference?: string,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<BookingGroup>> => {
        let queryResult = query(
            collection(this.db, this.collectionName),
            where('companies', 'array-contains', companyId),
            limit(this.batchLimit),
            orderBy('updatedAt', 'desc')
        );
        if (customerId) {
            queryResult = query(queryResult, where('customer.id', '==', customerId));
        }
        if (reference) {
            queryResult = query(queryResult, where('reference', '==', reference));
        }
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<BookingGroup>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getBookingByRefNumLike = async (
        refNumber: string,
        companyId: string,
        currentLimit = 15
    ) => {
        const queryResult = query(
            collection(this.db, this.collectionName),
            where('companies', 'array-contains', companyId),
            orderBy('reference'),
            startAt(refNumber),
            endAt(refNumber + '\uf8ff'),
            limit(currentLimit)
        );
        const snapshot = await getDocs(queryResult);
        return toEntities<BookingGroup>(snapshot.docs);
    };

    public updateBookingGroup = async (
        bookingGroup: BookingGroup,
        updatedItems: BookingGroupItem[],
        newItems: BookingGroupItem[]
    ) => {
        const batch = writeBatch(this.db);
        await this.update(bookingGroup, undefined, batch);
        await this.itemRepository.updateMany(updatedItems, bookingGroup.id!, batch);
        await this.itemRepository.createMany(newItems, bookingGroup.id!, batch);
        await batch.commit();
    };

    public createBookingGroup = async (
        bookingGroup: BookingGroup,
        items: BookingGroupItem[],
        deletedIds: string[]
    ) => {
        const batch = writeBatch(this.db);
        await this.create(bookingGroup, undefined, batch);
        await this.itemRepository.createMany(items, bookingGroup.id!, batch);
        await this.itemRepository.deleteByIds(deletedIds, bookingGroup.id!, batch);
        await batch.commit();
    };

    public getAllByCompanyIdSortedByDeparture = async (
        companyId: string,
        startDate: string,
        endDate: string,
        shouldGetOnlyPaid: boolean = false,
        lastDoc?: DocumentSnapshotAny
    ): Promise<GetAllResult<BookingGroupItem>> => {
        let queryResult = query(
            collectionGroup(this.db, Collection.BOOKINGS),
            where('companies', 'array-contains', companyId),
            where('departure.utc', '>=', startDate),
            where('departure.utc', '<=', endDate),
            limit(this.batchLimit),
            orderBy('departure.utc', 'desc')
        );
        if (shouldGetOnlyPaid) {
            queryResult = query(queryResult, where('isPaid', '==', true));
        }
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return {
            entities: toEntities<BookingGroupItem>(snapshot.docs),
            lastDoc: last(snapshot.docs)
        };
    };

    public checkBookingsExist = async (
        companyId: string,
        currentLimit: number = 1
    ): Promise<boolean> => {
        const queryResult = query(
            collection(this.db, this.collectionName),
            where('companies', 'array-contains', companyId),
            limit(currentLimit)
        );
        return !(await getDocs(queryResult)).empty;
    };

    public getAllByCompany = async (
        companyId: string,
        isPaid?: boolean,
        hasPendingChanges?: boolean,
        lastDoc?: DocumentSnapshotAny
    ) => {
        let queryResult = query(
            collection(this.db, this.collectionName),
            where('companies', 'array-contains', companyId),
            limit(this.batchLimit),
            orderBy('updatedAt', 'desc')
        );
        if (isPaid) {
            queryResult = query(queryResult, where('isPaid', '==', isPaid));
        }
        if (hasPendingChanges) {
            queryResult = query(queryResult, where('hasPendingChanges', '==', hasPendingChanges));
        }
        if (lastDoc) {
            queryResult = query(queryResult, startAfter(lastDoc));
        }
        const snapshot = await getDocs(queryResult);
        return { entities: toEntities<BookingGroup>(snapshot.docs), lastDoc: last(snapshot.docs) };
    };

    public getNewUpdatedLive = (
        companyId: string,
        updatedAt: string,
        onChange: (bookings: BookingGroup[]) => void,
        onError: (error: any) => void
    ) =>
        onSnapshot(
            query(
                collection(this.db, this.collectionName),
                where('companies', 'array-contains', companyId),
                where('updatedAt', '>=', updatedAt)
            ),
            (snapshot) => onChange(toEntities<BookingGroup>(snapshot.docs)),
            onError
        );

    public deleteGroupAndItems = async (groupId: string, itemIds: string[]) => {
        const batch = writeBatch(this.db);
        batch.delete(doc(this.db, `${this.collectionName}/${groupId}`));
        itemIds.forEach((id) =>
            batch.delete(
                doc(this.db, `${this.collectionName}/${groupId}/${Collection.BOOKINGS}/${id}`)
            )
        );
        await batch.commit();
    };
}
