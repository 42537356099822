"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateAdditionalCosts = exports.getTotalDiff = exports.getStripeFeeForTotal = exports.getPriceBreakdown = void 0;
const number_1 = require("./number");
const DEFAULT_STRIPE_FEE_RATE = 1.5;
const DEFAULT_STRIPE_FIX_FEE = 0;
const AGENT_PARSEPORTS_FEE = 1.5;
const OPERATOR_PARSEPORTS_FEE = 0.8;
const AGENT_DEFAULT_COMMISSION_RATE = 20;
const getPriceBreakdown = (total, additionalCosts = 0, agentComissionRate = AGENT_DEFAULT_COMMISSION_RATE, agentParseportsFeeRate = AGENT_PARSEPORTS_FEE, operatorParseportsFeeRate = OPERATOR_PARSEPORTS_FEE, stripeFeeRate = DEFAULT_STRIPE_FEE_RATE, stripeFixFee = DEFAULT_STRIPE_FIX_FEE, agentUndertakes, agentDiscountPerItem = 0) => {
    const totaltWithoutCost = total - additionalCosts;
    const agentGrossCommmsion = (0, number_1.getFlooredPercent)(totaltWithoutCost, agentComissionRate);
    const agentParseportsFee = Math.ceil((0, number_1.getPercent)(totaltWithoutCost, agentParseportsFeeRate) || 1);
    const agentNetCommision = Math.trunc(agentGrossCommmsion - agentParseportsFee - agentDiscountPerItem);
    const operatorComissionRate = 100 - agentComissionRate;
    const operatorGrossComission = total - agentGrossCommmsion;
    const operatorParseportsFee = Math.ceil((0, number_1.getPercent)(total, operatorParseportsFeeRate) || 1);
    const operatorNetComission = Math.trunc(operatorGrossComission - operatorParseportsFee);
    const cardFee = (0, exports.getStripeFeeForTotal)(total - agentDiscountPerItem, stripeFeeRate, stripeFixFee, agentUndertakes);
    return {
        agentGrossCommmsion: (0, number_1.nearestInt)(agentGrossCommmsion),
        operatorGrossComission: (0, number_1.nearestInt)(operatorGrossComission),
        agentParseportsFee,
        agentNetCommision,
        operatorComissionRate,
        operatorParseportsFee,
        operatorNetComission,
        cardFee
    };
};
exports.getPriceBreakdown = getPriceBreakdown;
const getStripeFeeForTotal = (total, stripeFeeRate = DEFAULT_STRIPE_FEE_RATE, stripeFixFee = DEFAULT_STRIPE_FIX_FEE, agentUndertakes) => {
    if (agentUndertakes) {
        return (0, number_1.nearestInt)(total * (stripeFeeRate / 100) + stripeFixFee);
    }
    // See https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
    const Fpercent = stripeFeeRate / 100;
    const charge = (total + stripeFixFee) / (1 - Fpercent);
    return (0, number_1.nearestInt)(charge - total) || 1;
};
exports.getStripeFeeForTotal = getStripeFeeForTotal;
const getTotalDiff = (bookingGroup) => {
    var _a, _b;
    const prevTotal = bookingGroup.isPaid ? bookingGroup.total || 0 : 0;
    const currTotal = ((_a = bookingGroup.pendingChange) === null || _a === void 0 ? void 0 : _a.total) !== undefined
        ? (_b = bookingGroup.pendingChange) === null || _b === void 0 ? void 0 : _b.total
        : bookingGroup.total || 0;
    const totalDiff = currTotal - prevTotal;
    return totalDiff;
};
exports.getTotalDiff = getTotalDiff;
const calculateAdditionalCosts = (items) => items.reduce((sum, item) => { var _a; return (sum = sum + (((_a = item === null || item === void 0 ? void 0 : item.pendingChange) === null || _a === void 0 ? void 0 : _a.additionalCosts) || (item === null || item === void 0 ? void 0 : item.additionalCosts) || 0)); }, 0);
exports.calculateAdditionalCosts = calculateAdditionalCosts;
