import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage, ref } from 'firebase/storage';
import { Collection } from 'firebase_api';
import environment from '../libs/environment/env';
import { UserAuth } from './auth/UserAuth';
import { BookingGroupItemRepository } from './firestore/booking/BookingGroupItemRepository';
import { BookingGroupRepository } from './firestore/booking/BookingGroupRepository';
import { BookingGroupStatisticsRepository } from './firestore/booking/BookingGroupStatisticsRepository';
import { TransactionRepository } from './firestore/booking/TransactionRepository';
import { CheckoutRepository } from './firestore/common/CheckoutRepository';
import { ErrorRepository } from './firestore/common/ErrorRepository';
import { PermissionRequestRepository } from './firestore/common/PermissionRequestRepository';
import { PriceOptionRepository } from './firestore/common/PriceOptionRepository';
import { ProductRepository } from './firestore/common/ProductRepository';
import { SupportRepository } from './firestore/common/SupportRepository';
import { UserRepository } from './firestore/common/UserRepository';
import { CompanyProductLinksRepository } from './firestore/company/CompanyProductLinksRepository';
import { CompanyRepository } from './firestore/company/CompanyRepository';
import { CustomerRepository } from './firestore/company/CustomerRepository';
import { ExtrasRepository } from './firestore/company/ExtrasRepository';
import { NoteAndTaskRepository } from './firestore/company/NoteAndTaskRepository';
import { PickUpListRepository } from './firestore/company/PickUpListRepository';
import { EmailFunctions } from './functions/EmailFunctions';
import { FareharborFunctions } from './functions/import/FareharborFunctions';
import { ResPaxFunctions } from './functions/import/ResPaxFunctions';
import { RezdyFunctions } from './functions/import/RezdyFunctions';
import { StripeFunctions } from './functions/StripeFunctions';
import { UserFunctions } from './functions/UserFunctions';
import { CompanyStorage } from './storage/company/CompanyStorage';
import { ExtrasStorage } from './storage/company/ExtrasStorage';
import { ProductStorage } from './storage/product/ProductStorage';

const firebaseApp = initializeApp(environment.firebase);

export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, environment.firebase.funcRegion);
export const storageRef = ref(storage);
export const firebaseAuth = getAuth(firebaseApp);

const productBatchLimit = 51;
const bookingBatchLimit = 51;
const transactionBatchLimit = 51;
const noteAndTaskBatchLimit = 21;

export const userAuth = new UserAuth(firebaseAuth);

export const userRepository = new UserRepository(firestore);
export const productRepository = new ProductRepository(productBatchLimit, firestore);
export const productPriceOptionRepository = new PriceOptionRepository(
    firestore,
    Collection.PRODUCTS
);
export const bookingPriceOptionRepository = new PriceOptionRepository(
    firestore,
    Collection.BOOKINGS
);
export const bookingGroupRepository = new BookingGroupRepository(bookingBatchLimit, firestore);
export const bookingGroupItemRepository = new BookingGroupItemRepository(
    bookingBatchLimit,
    firestore
);
export const bookingGroupStatsRepository = new BookingGroupStatisticsRepository(firestore);
export const transactionRepository = new TransactionRepository(transactionBatchLimit, firestore);
export const companyRepository = new CompanyRepository(firestore, Collection.COMPANIES);
export const companyProductLinksRepository = new CompanyProductLinksRepository(
    firestore,
    Collection.PRIVATE,
    Collection.COMPANIES
);
export const pickUpListRepository = new PickUpListRepository(firestore);
export const companyExtrasRepository = new ExtrasRepository(firestore, Collection.COMPANIES);
export const errorRepository = new ErrorRepository(firestore);
export const customerRepository = new CustomerRepository(firestore);
export const checkoutRepository = new CheckoutRepository(firestore);
export const permissionRepository = new PermissionRequestRepository(firestore);
export const supportRepository = new SupportRepository(firestore);
export const noteAndTaskRepository = new NoteAndTaskRepository(
    noteAndTaskBatchLimit,
    firestore,
    Collection.COMPANIES
);

// Firebase Functions
export const userFunctions = new UserFunctions(functions);
export const rezdyFunctions = new RezdyFunctions(functions);
export const fareharborFunctions = new FareharborFunctions(functions);
export const resPaxFunctions = new ResPaxFunctions(functions);
export const emailFunctions = new EmailFunctions(functions);
export const stripeFunctions = new StripeFunctions(functions);

// Firebase Storages
export const companyStorage = new CompanyStorage(storageRef);
export const productStorage = new ProductStorage(storageRef);
export const extrasStorage = new ExtrasStorage(storageRef);
