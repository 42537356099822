import { collectionGroup, Firestore, onSnapshot, query, where } from 'firebase/firestore';
import { BookingGroupItem, Collection, Transfer } from 'firebase_api';
import { toEntities } from '../utils';

export class BookingGroupStatisticsRepository {
    constructor(private readonly db: Firestore) {}

    public getTodaysBookings = (
        companyId: string,
        startDateTime: string,
        endDateTime: string,
        onChange: (sum: number) => void,
        onError: (error: Error) => void
    ): (() => void) =>
        onSnapshot(
            query(
                collectionGroup(this.db, Collection.BOOKINGS),
                where('companies', 'array-contains', companyId),
                where('status', '==', 'confirmed'),
                where('createdAt', '>=', startDateTime),
                where('createdAt', '<=', endDateTime)
            ),
            (snapshot) => onChange(snapshot.size),
            (error) => onError(error)
        );

    public getTodaysPassengers = (
        companyId: string,
        startDateTime: string,
        endDateTime: string,
        onChange: (sum: number) => void,
        onError: (error: Error) => void
    ): (() => void) =>
        onSnapshot(
            query(
                collectionGroup(this.db, Collection.BOOKINGS),
                where('companies', 'array-contains', companyId),
                where('status', '==', 'confirmed'),
                where('departure.utc', '>=', startDateTime),
                where('departure.utc', '<=', endDateTime)
            ),
            (snapshot) => {
                const entities = toEntities<BookingGroupItem>(snapshot.docs);
                onChange(this.getNumberOfUsedSeats(entities));
            },
            (error) => onError(error)
        );

    public getSales = (
        companyId: string,
        startDateTime: string,
        endDateTime: string,
        onChange: (sum: number) => void,
        onError: (error: Error) => void
    ): (() => void) =>
        onSnapshot(
            query(
                collectionGroup(this.db, Collection.TRANSFERS),
                where('companyId', '==', companyId),
                where('createdAt', '>=', startDateTime),
                where('createdAt', '<=', endDateTime)
            ),
            (snapshot) => {
                const transactions = toEntities<Transfer>(snapshot.docs);
                const sum = transactions.reduce((acc, transaction) => {
                    const amount = transaction.isRefund
                        ? -transaction.amountReversed!
                        : transaction.amount!;
                    return acc + amount;
                }, 0);
                onChange(sum);
            },
            (error) => onError(error)
        );

    private getBookingSumOfUsedSeats = (item: BookingGroupItem) =>
        Object.values(item.priceOptions || []).reduce(
            (sum, option) => sum + (option.seatsUsed || 0) * (option.quantity || 0),
            0
        );

    private getNumberOfUsedSeats = (items: BookingGroupItem[]) =>
        items.reduce((sum, item) => sum + this.getBookingSumOfUsedSeats(item), 0);
}
