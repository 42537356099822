import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Select,
    Slider,
    TextField,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { capitalize, toNumber } from 'lodash';
import { Color, ColorPicker } from 'material-ui-color';
import { useContext } from 'react';
import { toMenuItem } from '../../../../../libs/utils/functions';
import { IOSSwitch } from '../../../operator/products/upsert/common/IOSSwitch';
import {
    ButtonAlign,
    ContainerPaddingProps,
    ExtensionsPageController,
    FontWeight,
    PaddingType
} from '../ExtensionsPageController';

export const customizationStyle = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('md')]: {
            maxHeight: 700
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset'
        },
        backgroundColor: theme.palette.background.titleBg,
        padding: '24px 24px',
        overflowX: 'auto'
    },
    inputFields: {
        backgroundColor: theme.palette.common.white,
        width: 128,
        borderRadius: 6
    },
    helperField: {
        '& .MuiFormHelperText-root': {
            backgroundColor: theme.palette.background.titleBg,
            paddingLeft: 10,
            margin: 0
        }
    },
    textFieldText: {
        color: theme.palette.text.disabled,
        marginLeft: 0
    },
    customButton: {
        width: 43,
        height: 43,
        marginLeft: 6,
        borderRadius: 6,
        border: '1px solid' + theme.palette.inputBorder,
        backgroundColor: theme.palette.background.titleBg,
        '&:hover': {
            backgroundColor: theme.palette.text.companyText
        }
    },
    selectedButton: {
        borderRadius: 6,
        width: 43,
        height: 43,
        marginLeft: 6,
        border: '1px solid' + theme.palette.inputBorder,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.text.companyText
        }
    },
    paddingFields: {
        width: 80,
        backgroundColor: theme.palette.common.white,
        borderRadius: 6
    },
    parentPaddingMainField: {
        width: 80,
        backgroundColor: theme.palette.common.white,
        borderRadius: '6px 0 0 6px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0 0 4px'
        }
    },
    paddingIcon: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        width: 30,
        height: 46,
        border: '1px solid' + theme.palette.inputBorder,
        borderLeft: 'none',
        borderRadius: '0 6px 6px 0'
    }
}));

const ThemedSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.primary.main,
    height: 8,
    width: 140,
    '& .MuiSlider-track': {
        border: 'none'
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit'
        },
        '&:before': {
            display: 'none'
        }
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: theme.palette.primary.main,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
        },
        '& > *': {
            transform: 'rotate(45deg)'
        }
    }
}));

const StyledSelect = styled(Select)({
    select: {
        '& .MuiSelect-selectMenu': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiList-padding': {
            padding: 0
        }
    }
});

export const CustomizationGrid = styled(Grid)(() => ({
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    minWidth: 200
}));

export const ContainerPadding = ({
    isParentDivChecked,
    defaultPadding,
    changeAllPadding,
    parentDivPaddings,
    changeButtonPadding
}: ContainerPaddingProps) => {
    const theme = useTheme();
    const classes = customizationStyle();

    return (
        <Grid container item xs={12} alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
                <Typography
                    style={{
                        padding: '9px 0',
                        color: isParentDivChecked
                            ? theme.palette.common.black
                            : theme.palette.text.disabled
                    }}>
                    Container Padding
                </Typography>
            </Grid>
            <CustomizationGrid item container xs={6} style={{ minWidth: 150 }}>
                <Grid item>
                    <TextField
                        type="tel"
                        disabled={!isParentDivChecked}
                        className={classes.parentPaddingMainField}
                        value={defaultPadding}
                        placeholder=" 0"
                        inputProps={{ maxLength: 2, style: { paddingRight: 0 } }}
                        onChange={(event) => changeAllPadding(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography className={classes.textFieldText}>px</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button disabled className={classes.paddingIcon}>
                        <CropFreeIcon />
                    </Button>
                </Grid>
            </CustomizationGrid>
            <Grid
                item
                container
                xs={12}
                sx={{ justifyContent: { xs: 'flex-end', md: 'space-between' }, pt: '12px' }}>
                {Object.values(PaddingType).map((paddingType) => (
                    <Grid item key={paddingType} sx={{ ml: { xs: 1, md: 0 } }}>
                        <TextField
                            disabled={!isParentDivChecked}
                            type="tel"
                            className={classes.paddingFields}
                            value={parentDivPaddings?.[paddingType]}
                            placeholder=" 0"
                            inputProps={{ maxLength: 2, style: { paddingRight: 0 } }}
                            onChange={(event) =>
                                changeButtonPadding(paddingType, event.target.value)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.textFieldText}>
                                            px
                                        </Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default function BookNowButtonCustomization() {
    const theme = useTheme();
    const controller = useContext(ExtensionsPageController);
    const classes = customizationStyle();

    return (
        <Grid item xs={12} className={classes.container}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
                Customise Widget
            </Typography>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                item
                xs={12}
                style={{ padding: '8px 0 4px' }}>
                <Typography style={{ paddingBottom: 3 }}>Button Height</Typography>
                <ThemedSlider
                    name="height"
                    value={controller.buttonSizeOption.height}
                    aria-label="button-height"
                    valueLabelDisplay="auto"
                    min={30}
                    max={80}
                    onChange={(_, value) =>
                        controller.changeNumberValue('button', toNumber(value), 'height')
                    }
                />
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" item xs={12}>
                <Typography style={{ paddingBottom: 3 }}>Button Width</Typography>
                <ThemedSlider
                    name="width"
                    value={controller.buttonSizeOption.width}
                    aria-label="button-width"
                    valueLabelDisplay="auto"
                    min={120}
                    max={300}
                    onChange={(_, value) =>
                        controller.changeNumberValue('button', toNumber(value), 'width')
                    }
                />
            </Grid>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                item
                xs={12}
                style={{ padding: '4px 0 8px' }}>
                <Typography style={{ paddingBottom: 3 }}>Text Size</Typography>
                <ThemedSlider
                    name="textSize"
                    value={controller.textSize}
                    aria-label="text-size"
                    valueLabelDisplay="auto"
                    min={10}
                    max={24}
                    onChange={(_, value) => controller.changeNumberValue('text', toNumber(value))}
                />
            </Grid>
            <Grid container item xs={12} alignItems="center" style={{ padding: '8px 0' }}>
                <Typography style={{ paddingBottom: 3 }}>Text Weight</Typography>
                <Grid item container justifyContent="flex-end" style={{ flex: 1 }}>
                    <FormControl
                        variant="outlined"
                        style={{
                            backgroundColor: theme.palette.common.white,
                            width: 140,
                            borderRadius: 6
                        }}>
                        <StyledSelect
                            value={controller.fontWeight}
                            MenuProps={{ BackdropProps: { style: { opacity: 0 } } }}
                            onChange={(event) =>
                                controller.changeNumberValue('weight', toNumber(event.target.value))
                            }>
                            {Object.getOwnPropertyNames(FontWeight)
                                .filter((res) => !toNumber(res))
                                .map((weight) =>
                                    toMenuItem(
                                        false,
                                        FontWeight[weight as keyof typeof FontWeight],
                                        weight,
                                        capitalize(weight)
                                    )
                                )}
                        </StyledSelect>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ padding: '8px 0' }}>
                <Grid item>
                    <Typography style={{ paddingBottom: 3 }}>Rounded Edges</Typography>
                    <Typography
                        variant="body2"
                        style={{ color: theme.palette.text.shadowText, paddingBottom: 4 }}>
                        Set 0px for square edges
                    </Typography>
                </Grid>
                <Grid item container justifyContent="flex-end" style={{ flex: 1 }}>
                    <TextField
                        type="tel"
                        style={{
                            backgroundColor: theme.palette.common.white,
                            width: 78,
                            borderRadius: 6
                        }}
                        value={
                            (controller.roundEdgeSize || 0) === 0 ? '' : controller.roundEdgeSize
                        }
                        placeholder=" 0"
                        inputProps={{ maxLength: 2, style: { paddingRight: 0 } }}
                        onChange={(event) => controller.changeRoundEdgeSize(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography className={classes.textFieldText}>px</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" style={{ padding: '8px 0' }}>
                <Grid item>
                    <Typography style={{ padding: '9px 0' }}>Button Text Colour</Typography>
                </Grid>
                <CustomizationGrid item container spacing={1}>
                    <Grid item>
                        <ColorPicker
                            value={controller.buttonTextColor}
                            onChange={(newValue: Color) => {
                                controller.changeButtonTextColor(newValue);
                            }}
                            hideTextfield
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.inputFields}
                            value={controller.buttonTextLastColor}
                            inputProps={{
                                readOnly: Boolean(true)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography
                                            style={{
                                                color: theme.palette.text.disabled
                                            }}>
                                            #
                                        </Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </CustomizationGrid>
            </Grid>
            <Grid container item xs={12} alignItems="center">
                <Grid item>
                    <Typography style={{ padding: '9px 0' }}>Background Colour</Typography>
                </Grid>
                <CustomizationGrid item container spacing={1}>
                    <Grid item>
                        <ColorPicker
                            value={controller.buttonColor}
                            onChange={(newValue: Color) => {
                                controller.changeButtonColor(newValue);
                            }}
                            hideTextfield
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.inputFields}
                            value={controller.buttonLastColor}
                            inputProps={{
                                readOnly: Boolean(true)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography
                                            style={{
                                                color: theme.palette.text.disabled
                                            }}>
                                            #
                                        </Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </CustomizationGrid>
            </Grid>
            <Grid container item xs={12} alignItems="center" style={{ paddingTop: 16 }}>
                <Grid item xs={8}>
                    <Typography style={{ padding: '9px 0' }}>Include Div Container</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                    <IOSSwitch
                        checked={controller.isParentDivChecked}
                        onChange={() => controller.changeParentDivState()}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ padding: '21px 0' }}
                direction="row">
                <Grid item xs={4}>
                    <Typography
                        variant="body1"
                        style={{
                            padding: '9px 0',
                            color: controller.isParentDivChecked
                                ? theme.palette.common.black
                                : theme.palette.text.disabled
                        }}>
                        Alignment
                    </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-end">
                    <Grid item>
                        <IconButton
                            disabled={!controller.isParentDivChecked}
                            className={
                                controller.selectedButton === ButtonAlign.LEFT
                                    ? classes.selectedButton
                                    : classes.customButton
                            }
                            onClick={() => controller.changeButtonAlignment(ButtonAlign.LEFT)}
                            size="large">
                            <FormatAlignLeftIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            disabled={!controller.isParentDivChecked}
                            className={
                                controller.selectedButton === ButtonAlign.CENTER
                                    ? classes.selectedButton
                                    : classes.customButton
                            }
                            onClick={() => controller.changeButtonAlignment(ButtonAlign.CENTER)}
                            size="large">
                            <FormatAlignCenterIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            disabled={!controller.isParentDivChecked}
                            className={
                                controller.selectedButton === ButtonAlign.RIGHT
                                    ? classes.selectedButton
                                    : classes.customButton
                            }
                            style={{ alignContent: 'flex-end' }}
                            onClick={() => controller.changeButtonAlignment(ButtonAlign.RIGHT)}
                            size="large">
                            <FormatAlignLeftIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <ContainerPadding
                isParentDivChecked={controller.isParentDivChecked}
                defaultPadding={controller.defaultPadding}
                parentDivPaddings={controller.parentDivPaddings}
                changeAllPadding={controller.changeAllPadding}
                changeParentDivState={controller.changeParentDivState}
                changeButtonPadding={controller.changeButtonPadding}
            />
        </Grid>
    );
}
