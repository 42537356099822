export const readAsDataURL = (file: File) =>
    new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target?.result as string);
        };
        reader.readAsDataURL(file);
    });

export const readManyAsDataUrl = async (files: File[]) => {
    const base64: string[] = [];
    for (const file of files) {
        base64.push(await readAsDataURL(file));
    }
    return base64;
};

export const getImgExtensionFromDataUrl = (datUrl: string) =>
    datUrl.substring(datUrl.lastIndexOf(':') + 1, datUrl.indexOf(';')).split('/')[1];
