"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESIZED_IMAGE_SUFFIX = exports.NORMAL_IMAGE_SIZES = exports.monitorUploadTask = exports.LOGO_IMAGE_SIZES = exports.getResizedImageName = exports.BLURED_SUFFIX = exports.generateRefNumber = exports.getTotalDiff = exports.getStripeFeeForTotal = exports.getPriceBreakdown = exports.calculateAdditionalCosts = exports.nearestInt = exports.getTwoNumberPercentageDiff = exports.getPercent = exports.getFlooredPercent = exports.strToAmount = exports.currency = exports.amountUiStr = exports.amountUiFormat = exports.timeDateUIString = exports.getChangedFields = exports.changeableBookingItemFields = exports.changeableBookingGroupFields = exports.FeedbackType = exports.ViewTypes = exports.UserStatus = exports.Role = exports.ProductStatus = exports.ProductProvider = exports.PRICE_OPTIONS_USED_SEATS = exports.PriceOptionLabel = exports.LOCATIONS = exports.ReturnPolicy = exports.EmailType = exports.ContactType = exports.CompanyType = exports.CompanyStatus = exports.CompanyPrivateCollectionId = exports.CompanyFeature = exports.StatusTypes = exports.Collection = exports.ApplyExtras = exports.TransferStatus = exports.TransferPrefix = exports.PARSEPORTS = exports.ParticipantInfoFieldType = exports.BookingUpdateApprovalType = exports.BookingStatus = exports.ApprovalResponse = exports.SupportCategories = void 0;
exports.setUpdatedAt = exports.setCreatedUpdatedAt = void 0;
var Article_1 = require("./model/firestore/Article");
Object.defineProperty(exports, "SupportCategories", { enumerable: true, get: function () { return Article_1.SupportCategories; } });
var Booking_1 = require("./model/firestore/booking/Booking");
Object.defineProperty(exports, "ApprovalResponse", { enumerable: true, get: function () { return Booking_1.ApprovalResponse; } });
Object.defineProperty(exports, "BookingStatus", { enumerable: true, get: function () { return Booking_1.BookingStatus; } });
Object.defineProperty(exports, "BookingUpdateApprovalType", { enumerable: true, get: function () { return Booking_1.BookingUpdateApprovalType; } });
var Participant_1 = require("./model/firestore/booking/Participant");
Object.defineProperty(exports, "ParticipantInfoFieldType", { enumerable: true, get: function () { return Participant_1.ParticipantInfoFieldType; } });
var Transfer_1 = require("./model/firestore/booking/Transfer");
Object.defineProperty(exports, "PARSEPORTS", { enumerable: true, get: function () { return Transfer_1.PARSEPORTS; } });
Object.defineProperty(exports, "TransferPrefix", { enumerable: true, get: function () { return Transfer_1.TransferPrefix; } });
Object.defineProperty(exports, "TransferStatus", { enumerable: true, get: function () { return Transfer_1.TransferStatus; } });
var Common_1 = require("./model/firestore/Common");
Object.defineProperty(exports, "ApplyExtras", { enumerable: true, get: function () { return Common_1.ApplyExtras; } });
Object.defineProperty(exports, "Collection", { enumerable: true, get: function () { return Common_1.Collection; } });
Object.defineProperty(exports, "StatusTypes", { enumerable: true, get: function () { return Common_1.StatusTypes; } });
var Company_1 = require("./model/firestore/Company");
Object.defineProperty(exports, "CompanyFeature", { enumerable: true, get: function () { return Company_1.CompanyFeature; } });
Object.defineProperty(exports, "CompanyPrivateCollectionId", { enumerable: true, get: function () { return Company_1.CompanyPrivateCollectionId; } });
Object.defineProperty(exports, "CompanyStatus", { enumerable: true, get: function () { return Company_1.CompanyStatus; } });
Object.defineProperty(exports, "CompanyType", { enumerable: true, get: function () { return Company_1.CompanyType; } });
Object.defineProperty(exports, "ContactType", { enumerable: true, get: function () { return Company_1.ContactType; } });
Object.defineProperty(exports, "EmailType", { enumerable: true, get: function () { return Company_1.EmailType; } });
Object.defineProperty(exports, "ReturnPolicy", { enumerable: true, get: function () { return Company_1.ReturnPolicy; } });
var Locations_1 = require("./model/firestore/Locations");
Object.defineProperty(exports, "LOCATIONS", { enumerable: true, get: function () { return Locations_1.LOCATIONS; } });
var PriceOption_1 = require("./model/firestore/PriceOption");
Object.defineProperty(exports, "PriceOptionLabel", { enumerable: true, get: function () { return PriceOption_1.PriceOptionLabel; } });
Object.defineProperty(exports, "PRICE_OPTIONS_USED_SEATS", { enumerable: true, get: function () { return PriceOption_1.PRICE_OPTIONS_USED_SEATS; } });
var Product_1 = require("./model/firestore/Product");
Object.defineProperty(exports, "ProductProvider", { enumerable: true, get: function () { return Product_1.ProductProvider; } });
Object.defineProperty(exports, "ProductStatus", { enumerable: true, get: function () { return Product_1.ProductStatus; } });
var User_1 = require("./model/firestore/User");
Object.defineProperty(exports, "Role", { enumerable: true, get: function () { return User_1.Role; } });
Object.defineProperty(exports, "UserStatus", { enumerable: true, get: function () { return User_1.UserStatus; } });
Object.defineProperty(exports, "ViewTypes", { enumerable: true, get: function () { return User_1.ViewTypes; } });
var EmailRequest_1 = require("./model/functions/EmailRequest");
Object.defineProperty(exports, "FeedbackType", { enumerable: true, get: function () { return EmailRequest_1.FeedbackType; } });
var booking_1 = require("./utils/booking");
Object.defineProperty(exports, "changeableBookingGroupFields", { enumerable: true, get: function () { return booking_1.changeableBookingGroupFields; } });
Object.defineProperty(exports, "changeableBookingItemFields", { enumerable: true, get: function () { return booking_1.changeableBookingItemFields; } });
Object.defineProperty(exports, "getChangedFields", { enumerable: true, get: function () { return booking_1.getChangedFields; } });
Object.defineProperty(exports, "timeDateUIString", { enumerable: true, get: function () { return booking_1.timeDateUIString; } });
var currency_1 = require("./utils/currency");
Object.defineProperty(exports, "amountUiFormat", { enumerable: true, get: function () { return currency_1.amountUiFormat; } });
Object.defineProperty(exports, "amountUiStr", { enumerable: true, get: function () { return currency_1.amountUiStr; } });
Object.defineProperty(exports, "currency", { enumerable: true, get: function () { return currency_1.currency; } });
Object.defineProperty(exports, "strToAmount", { enumerable: true, get: function () { return currency_1.strToAmount; } });
var number_1 = require("./utils/number");
Object.defineProperty(exports, "getFlooredPercent", { enumerable: true, get: function () { return number_1.getFlooredPercent; } });
Object.defineProperty(exports, "getPercent", { enumerable: true, get: function () { return number_1.getPercent; } });
Object.defineProperty(exports, "getTwoNumberPercentageDiff", { enumerable: true, get: function () { return number_1.getTwoNumberPercentageDiff; } });
Object.defineProperty(exports, "nearestInt", { enumerable: true, get: function () { return number_1.nearestInt; } });
var payment_1 = require("./utils/payment");
Object.defineProperty(exports, "calculateAdditionalCosts", { enumerable: true, get: function () { return payment_1.calculateAdditionalCosts; } });
Object.defineProperty(exports, "getPriceBreakdown", { enumerable: true, get: function () { return payment_1.getPriceBreakdown; } });
Object.defineProperty(exports, "getStripeFeeForTotal", { enumerable: true, get: function () { return payment_1.getStripeFeeForTotal; } });
Object.defineProperty(exports, "getTotalDiff", { enumerable: true, get: function () { return payment_1.getTotalDiff; } });
var refNumber_1 = require("./utils/refNumber");
Object.defineProperty(exports, "generateRefNumber", { enumerable: true, get: function () { return refNumber_1.generateRefNumber; } });
var storage_1 = require("./utils/storage");
Object.defineProperty(exports, "BLURED_SUFFIX", { enumerable: true, get: function () { return storage_1.BLURED_SUFFIX; } });
Object.defineProperty(exports, "getResizedImageName", { enumerable: true, get: function () { return storage_1.getResizedImageName; } });
Object.defineProperty(exports, "LOGO_IMAGE_SIZES", { enumerable: true, get: function () { return storage_1.LOGO_IMAGE_SIZES; } });
Object.defineProperty(exports, "monitorUploadTask", { enumerable: true, get: function () { return storage_1.monitorUploadTask; } });
Object.defineProperty(exports, "NORMAL_IMAGE_SIZES", { enumerable: true, get: function () { return storage_1.NORMAL_IMAGE_SIZES; } });
Object.defineProperty(exports, "RESIZED_IMAGE_SUFFIX", { enumerable: true, get: function () { return storage_1.RESIZED_IMAGE_SUFFIX; } });
var timestamps_1 = require("./utils/timestamps");
Object.defineProperty(exports, "setCreatedUpdatedAt", { enumerable: true, get: function () { return timestamps_1.setCreatedUpdatedAt; } });
Object.defineProperty(exports, "setUpdatedAt", { enumerable: true, get: function () { return timestamps_1.setUpdatedAt; } });
