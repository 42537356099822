export enum Operation {
    CREATE,
    EDIT,
    REVIEW,
    REVIEW_TABLE
}

export const SNACKBAR_HIDE_DURATION = 3000; // 3 sec
export const TWO_YEAR_DURATION = 1051920;

export const DATE_PICKER_FORMAT = 'dd/MMM/yyyy';
export const UI_BUTTON_DATE_FORMAT = 'DD MMM YYYY';
export const UI_DATE_FORMAT = 'DD/MMM/YYYY';
export const UI_DATE_FORMAT_WITH_HOURS = 'h:mm A, DD/MMM/YYYY';
export const FILTER_DATE_FORMAT_WITH_HOURS = 'YYYY-MM-DDTHH:mm';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const NOTES_EXPIRY_DATE = 'YYYYMMDD';

export const CHART_DATE_DISPLAY = 'MMM DD';

export const LOCAL_STORAGE_KEYS = {
    theme: 'THEME',
    operatorOnboarding: 'operatorOnboarding',
    agentOnboarding: 'agentOnboarding'
};

export const COMPANY_LOGO_NAME = 'logo';
export const STORAGE_API = 'https://firebasestorage.googleapis.com/v0/b';

export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_REGEX =
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const EXTENSIONS_EMAIL = '_extensions@noreply.com';
export const EXTENSIONS_RANDOM = 'random_e2zkHZJBpROCCMAhj2bZLi0Ud6x4';
export const IFRAME_CERTAIN_CLOSE_MESSAGE = 'closeIframeForSure';
export const IFRAME_UNCERTAIN_CLOSE_MESSAGE = 'closeIframe';

export const REFUND_ENABLED_DAYS = 30; // after the booking departure, refund process still available 30 days
